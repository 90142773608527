import React, { Component, Fragment } from 'react';
import { compose, pathOr, fromPairs, prepend, path } from 'ramda';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Row, Col, Spin, Table, Button } from 'antd';
import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';

import withUserContext from '../../../hocs/withUserContext';
import { getUrlFilter } from '../../../../utils/urlParams';
import { CLIENT_MANAGER } from '../../../../constants/roles';
import { getRole } from '../../../../utils/users';
import { Toolbar } from '../../table/Table';
import { ErrorBlockIcon, ErrorBlock } from './Report';
import { getApplicantStatusesStat } from '../../../../queries/requests';
import ApplicantApprovalStatReportFilter from '../../../forms/filters/ApplicantApprovalStatReportFilter';
import { getDeadlineSettings } from '../../../../queries/deadline';
import { roundValue } from '../../../../utils/numbers';

const getFilter = props => getRole() === CLIENT_MANAGER && props.user.regionMacro ? ({
    ...getUrlFilter(props.location),
    regionMacro: props.user.regionMacro.id
}) : getUrlFilter(props.location);

const renderStatValue = (num = 0, status, statuses) => {
    const value = roundValue(num);
    return value > statuses[status] ? <span style={{ color: 'red' }}>{ value }</span> : value;
}

class ApplicantApprovalStatReport extends Component {
    getColumns = deadlineData => {
        const { statuses } = this.getSLA(deadlineData);

        return [
            {
                title: 'Регионы',
                dataIndex: ['regionClient', 'name'],
                key: 'regionClient'
            },
            {
                title: 'Согласование',
                dataIndex: ['statuses', 'supervisorApproval'],
                key: 'position',
                align: 'center',
                render: value => renderStatValue(value, 'supervisorApproval', statuses)
            },
            {
                title: 'Интервью',
                dataIndex: ['statuses', 'supervisorInterview'],
                key: 'position',
                align: 'center',
                render: value => renderStatValue(value, 'supervisorInterview', statuses)
            },
            {
                title: 'СБ',
                dataIndex: ['statuses', 'securityApproval'],
                key: 'position',
                align: 'center',
                render: value => renderStatValue(value, 'securityApproval', statuses)
            },
            {
                title: 'Оформление',
                dataIndex: ['statuses', 'registration'],
                key: 'position',
                align: 'center',
                render: value => renderStatValue(value, 'registration', statuses)
            },
            {
                title: 'Ожидание выхода',
                dataIndex: ['statuses', 'attendanceAwaiting'],
                key: 'position',
                align: 'center',
                render: value => renderStatValue(value, 'attendanceAwaiting', statuses)
            }
        ];
    }

    getSLA = data => {
        return {
            id: 'sla',
            regionClient: { name: 'SLA' },
            statuses: pathOr({}, ['deadlineSettings', 'periods'], data)
        };
    }

    getStatData = (data, deadlineData) => {
        return prepend(this.getSLA(deadlineData), pathOr([], ['applicantStatusesStat'], data).map((item, index) => ({
            ...item,
            id: `applicant-statuses-stat-${index}`,
            statuses: fromPairs(item.statuses.map(({ status, avgDaysInStatus }) => ([ status, avgDaysInStatus ])))
        })));
    }

    downloadExcel = data => {
        const ws = XLSX.utils.json_to_sheet(this.getStatData(data).map(item => ({
            'Регион': path(['regionClient', 'name'], item),
            'Согласование': pathOr(0, ['statuses', 'supervisorApproval'], item),
            'Интервью': pathOr(0, ['statuses', 'supervisorInterview'], item),
            'СБ': pathOr(0, ['statuses', 'securityApproval'], item),
            'Оформление': pathOr(0, ['statuses', 'registration'], item),
            'Ожидание выхода': pathOr(0, ['statuses', 'attendanceAwaiting'], item)
        })), {
            header: [
                'Регион',
                'Согласование',
                'Интервью',
                'СБ',
                'Оформление',
                'Ожидание выхода',
            ]
        });
        ws['!cols'] = [
            { wpx: 130 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 110 }
        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Переход по статусам');

        XLSX.writeFile(wb, 'applicant-statuses-stat.xlsx')
    }

    render() {
        const filters = getFilter(this.props);

        return (
            <Query query={getApplicantStatusesStat} variables={{ filter: filters }}>
                { ({ data, loading, error }) =>
                    <Fragment>
                        <Toolbar>
                            <Col span={23}>
                                <ApplicantApprovalStatReportFilter filter={filters} />
                            </Col>
                            <Col span={1}>
                                <Button
                                    shape='circle'
                                    icon={<FileExcelOutlined />}
                                    disabled={loading}
                                    onClick={() => this.downloadExcel(data)} />
                            </Col>
                        </Toolbar>
                        <Row>
                            <Col span={24}>
                                <Query query={getDeadlineSettings} variables={{ type: 'applicant' }}>
                                    { ({ data: deadlineData, loading: deadlineLoading, error: deadlineError }) =>
                                        <Spin spinning={loading || deadlineLoading} delay={1000}>
                                            <Table
                                                columns={this.getColumns()}
                                                dataSource={this.getStatData(data, deadlineData)}
                                                pagination={false}
                                                rowKey='id'
                                                locale={{
                                                    emptyText: (error || deadlineError) ?
                                                        <ErrorBlock>
                                                            <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                                                            <div>Не удалось отобразить данные</div>
                                                        </ErrorBlock> :
                                                        'Нет данных'
                                                }} />
                                        </Spin>
                                    }
                                </Query>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Query>
        );
    }
}

export default compose(
    withRouter,
    withUserContext
)(ApplicantApprovalStatReport);
