import React from 'react';
import { AuditOutlined, FileOutlined, HourglassOutlined, LineChartOutlined, PauseOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const REQUEST_STATUSES = {
    draft: { title: 'Черновик', color: '#8E8E8E', icon: <FileOutlined /> },
    clientApproval: { title: 'Согласование', color: '#4CC2E5', icon: <AuditOutlined /> },
    finalClientApproval: { title: 'Финальное согласование', color: '#4CC2E5', icon: <AuditOutlined /> },
    ancorAwaiting: { title: 'Ожидание Анкор', color: '#F5A623', icon: <HourglassOutlined /> },
    ancorApproval: { title: 'Согласование Анкор', color: '#4CC2E5', icon: <AuditOutlined /> },
    inwork: { title: 'В работе', color: '#1890FF', icon: <LineChartOutlined /> },
    hold: { title: 'Приостановлено', color: '#FF4136', icon: <PauseOutlined /> },
    done: { title: 'Выполнено', color: '#71C57A', icon: <CheckOutlined /> },
    rejected: { title: 'Отклонено', color: '#8E8E8E', icon: <CloseOutlined /> }
};

export const REQUEST_REFETCH_QUERIES = ['hireRequest', 'hireRequests', 'hireRequestFilters'];

export const DEADLINE_REQUESTS = [
    { type: 'clientApproval', name: REQUEST_STATUSES.clientApproval.title },
    { type: 'finalClientApproval', name: REQUEST_STATUSES.finalClientApproval.title },
    { type: 'ancorAwaiting', name: REQUEST_STATUSES.ancorAwaiting.title },
    { type: 'ancorApproval', name: REQUEST_STATUSES.ancorApproval.title },
    { type: 'inwork', name: REQUEST_STATUSES.inwork.title }
];
