import gql from 'graphql-tag';

export const getSupervisors = gql(`
    query supervisors($filter: SupervisorFilterInput, $pagination: PaginationInput, $sorting: UserSortInput) {
        supervisors(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                username,
                lastName,
                firstName,
                middleName,
                phone,
                regionClient {
                    id,
                    name
                },
                regionAncor {
                    id,
                    name
                },
                regionMacro {
                    id,
                    name
                },
                enabled,
                activated,
                lastLoginAt,
                applicantStatusManagementEnabled,
                hireRequestManagementEnabled
            }
        }
    }
`);

export const createSupervisor = gql(`
    mutation createSupervisor($values: SupervisorInput!) {
        createSupervisor(supervisor: $values) {
            id
        }
    }
`);

export const editSupervisor = gql(`
    mutation editSupervisor($values: SupervisorEditInput!, $id: ID!) {
        editSupervisor(supervisor: $values, id: $id) {
            id
        }
    }
`);
