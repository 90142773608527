import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { pathOr } from 'ramda';

import { getRegionsMacro } from '../../../queries/regions';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import SearchInput from '../formComponents/SearchInput';

class RegionClientFilter extends Component {
    render() {
        return <Row>
            <Col span={12}>
                <Field
                    name='name'
                    component={SearchInput}
                    placeholder='Название' />
            </Col>
            <Col span={12}>
                <Query query={getRegionsMacro}>
                    { ({ data }) =>
                        <Field
                            name='regionMacro'
                            component={Select}
                            placeholder='Макрорегион'
                            namePath='name'
                            valuePath='id'
                            allowClear
                            options={pathOr([], ['regionsMacro', 'items'], data)} />
                    }
                </Query>
            </Col>
        </Row>
    }
}

export default withFilterForm(RegionClientFilter);
