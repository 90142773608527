import React from 'react';

import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import FullName, { LinkWrapper } from './FullName';

const Applicant = ({ item, text, applicant }) => (
    <ModalsContext.Consumer>
        { ({ openModal }) =>
            text
                ? <LinkWrapper onClick={() => applicant && openModal(MODAL_TYPES.applicantDetail, { applicant })}>{text}</LinkWrapper>
                : <FullName onClick={() => applicant && openModal(MODAL_TYPES.applicantDetail, { applicant })} item={item} />
        }
    </ModalsContext.Consumer>
);

export const ApplicantOPD = ({ item }) => (
    <ModalsContext.Consumer>
        { ({ openModal }) =>
            !item.contact.pdaAgreement && item.pdaKey && (
                <div className='error-text' onClick={() => openModal(MODAL_TYPES.applicantDetail, { applicant: item, opd: true })}>
                    { item.depersonalized
                        ? item.depersonalizationReason === 'rejected' ? <small>Отказался дать СОПД</small> : <small>Обезличен</small>
                        : <small>Нет СОПД</small>
                    }
                </div>
            )
        }
    </ModalsContext.Consumer>
);

export default Applicant;
