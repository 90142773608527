import React, { Component } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { needNativeInputs } from '../../../utils/mobile';
import withFieldWrapper from '../../hocs/withFieldWrapper';

const StyledTimepicker = styled.div`
    .ant-time-picker {
        width: 170px;
    }
`;

class Timepicker extends Component {
    onChange = date => {
        this.props.onChange(date ? moment.utc(date).seconds(0).milliseconds(0).add('minute', date.utcOffset()).toDate() : null);
    }

    onChangeNative = event => {
        const value = moment.utc(event.target.value, 'HH:mm').seconds(0).milliseconds(0);
        const isValid = value.isValid();

        this.onChange(isValid ? value : null);
    }

    render() {
        const { value, name } = this.props.input;

        return !needNativeInputs() ? (
            <StyledTimepicker>
                <TimePicker
                    name={name}
                    value={value ? moment.utc(value) : null}
                    onChange={this.onChange}
                    format='HH:mm'
                    placeholder='Выберите время'
                />
            </StyledTimepicker>
        ) : (
            <input
                className='date-input'
                type='time'
                value={value ? moment.utc(value).format('HH:mm') : ''}
                onChange={this.onChangeNative}
            />
        );
    }
}

export default withFieldWrapper(Timepicker);
