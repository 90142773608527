import React, { Component, Fragment } from 'react';
import { Button } from 'antd';

import { getRole } from '../../../utils/users';
import { CLIENT_MANAGER } from '../../../constants/roles';
import Table from '../table/Table';
import { getAdmins, editAdmin } from '../../../queries/admins';
import FullName from '../table/FullName';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import UserFilter from '../../forms/filters/UserFilter';
import { DEFAULT_USER_FILTER, ENABLED_STATE } from '../../../constants/lists';
import SwitchEnabled from '../table/SwitchEnabled';
import { getUserEditInput } from '../../../utils/users';
import DateFormat from '../table/DateFormat';
import RecoveryButton from './RecoveryButton';
import Contacts from '../table/Contacts';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

export default class Admins extends Component {
    getColumns = () => {
        return [
            {
                title: 'ФИО',
                key: 'name',
                render: item =>
                    <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <FullName item={item} onClick={() => openModal(MODAL_TYPES.userView, item)} />
                        }
                    </ModalsContext.Consumer>
            },
            {
                title: 'Контакты',
                dataIndex: 'username',
                key: 'contacts',
                render: (email, { phone, activated }) => <Contacts email={email} phone={phone} activated={activated} />
            },
            {
                title: 'Активен',
                key: 'enabled',
                width: 110,
                filters: ENABLED_STATE.map(i => ({ ...i, text: i.label })),
                filterMultiple: false,
                render: item =>
                    <SwitchEnabled
                        mutation={editAdmin}
                        refetchQueries={['admins']}
                        item={getUserEditInput(item)} />
            },
            {
                title: 'Последний вход',
                key: 'lastLoginAt',
                dataIndex: 'lastLoginAt',
                render: date => <DateFormat date={date} />
            },
            {
                key: 'actions',
                width: 100,
                render: (item, _, index) =>
                    <Button.Group>
                        <RecoveryButton username={item.username} className={`admins-recovery-btn-${index}`} />
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <Fragment>
                                    { getRole() !== CLIENT_MANAGER && (
                                        <Button
                                            className={`admins-edit-btn-${index}`}
                                            icon={<EditOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.admin, getUserEditInput(item))} />
                                    )}
                                </Fragment>
                            }
                        </ModalsContext.Consumer>
                    </Button.Group>
            }
        ];
    }

    renderToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Fragment>
                    { getRole() !== CLIENT_MANAGER && (
                        <Button
                            className='admins-add-btn'
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => openModal(MODAL_TYPES.admin)}>
                            Добавить
                        </Button>
                    )}
                </Fragment>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        return <Table
            name='admins'
            query={getAdmins}
            columns={this.getColumns()}
            toolbarButtons={this.renderToolbarButtons()}
            FilterForm={UserFilter}
            defaultFilter={DEFAULT_USER_FILTER} />;
    }
}
