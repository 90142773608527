import React, { Component } from 'react';
import { assoc, compose, map, pathOr } from 'ramda';
import moment from 'moment';

import { getApplicantDailyUsersStat } from '../../../../queries/applicants';
import AreaChart from './charts/AreaChart';
import Report, { formatDayValue } from './Report';

export default class Reports extends Component {
    render() {
        return (
            <Report
                query={getApplicantDailyUsersStat}
                ChartComponent={AreaChart}
                getData={compose(
                    map(item => (
                        assoc('field', moment.utc(`${item._id.year}.${item._id.month}.${item._id.day}`, 'YYYY.M.D').format('YYYY.MM.DD'), item)
                    )),
                    pathOr([], ['applicantDailyUsersStat'])
                )}
                value='avgTimeInStatus'
                formatValue={formatDayValue}
                disableFilters={true}
            />
        );
    }
}
