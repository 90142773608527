import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { path } from 'ramda';

import { getRole } from '../../../utils/users';
import { CLIENT_MANAGER } from '../../../constants/roles';
import Table from '../table/Table';
import FullName from '../table/FullName';
import { getSupervisors, editSupervisor } from '../../../queries/supervisors';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import withUserContext from '../../hocs/withUserContext';
import UserFilter from '../../forms/filters/UserFilter';
import { DEFAULT_USER_FILTER, ENABLED_STATE } from '../../../constants/lists';
import { getSupervisorEditInput } from '../../../utils/users';
import SwitchEnabled from '../table/SwitchEnabled';
import DateFormat from '../table/DateFormat';
import RecoveryButton from './RecoveryButton';
import Contacts from '../table/Contacts';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

class Supervisors extends Component {
    getColumns = () => {
        return [
            {
                title: 'ФИО',
                key: 'name',
                render: item =>
                    <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <FullName item={item} onClick={() => openModal(MODAL_TYPES.userView, item)} />
                        }
                    </ModalsContext.Consumer>
            },
            {
                title: 'Контакты',
                dataIndex: 'username',
                key: 'contacts',
                render: (email, { phone, activated }) => <Contacts email={email} phone={phone} activated={activated} />
            },
            {
                title: 'Регион',
                dataIndex: ['regionMacro', 'name'],
                key: 'region',
                render: (regionMacro, { regionClient }) => {
                    const region = path(['name'], regionClient);

                    return regionMacro ? (region ? `${regionMacro}/${region}` : regionMacro) : null;
                }
            },
            {
                title: 'Активен',
                key: 'enabled',
                filters: ENABLED_STATE.map(i => ({ ...i, text: i.label })),
                filterMultiple: false,
                width: 110,
                render: item =>
                    <SwitchEnabled
                        mutation={editSupervisor}
                        refetchQueries={['supervisors']}
                        item={{
                            ...getSupervisorEditInput(item),
                            regionMacro: path(['regionMacro', 'id'], item),
                            regionClient: path(['regionClient', 'id'], item)
                        }}
                        disabled={getRole() === CLIENT_MANAGER} />
            },
            {
                title: 'Последний вход',
                key: 'lastLoginAt',
                dataIndex: 'lastLoginAt',
                render: date => <DateFormat date={date} />
            },
            {
                key: 'actions',
                width: 100,
                render: item =>
                    <Button.Group>
                        <RecoveryButton username={item.username} />
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <Fragment>
                                    { getRole() !== CLIENT_MANAGER && (
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.supervisor, getSupervisorEditInput(item))}
                                        />
                                    )}
                                </Fragment>
                            }
                        </ModalsContext.Consumer>
                    </Button.Group>
            }
        ];
    }

    renderToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Fragment>
                    { getRole() !== CLIENT_MANAGER && (
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => openModal(MODAL_TYPES.supervisor)}>
                            Добавить
                        </Button>
                    )}
                </Fragment>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        const defaultFilter = getRole() === CLIENT_MANAGER && {
            regionMacro: path(['regionMacro', 'id'], this.props.user)
        };

        return <Table
            name='supervisors'
            query={getSupervisors}
            columns={this.getColumns()}
            toolbarButtons={this.renderToolbarButtons()}
            FilterForm={UserFilter}
            defaultFilter={defaultFilter ? ({
                ...DEFAULT_USER_FILTER,
                ...defaultFilter
            }) : DEFAULT_USER_FILTER} />;
    }
}

export default withUserContext(Supervisors);
