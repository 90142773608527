import React, { Component } from 'react';
import { Spin, Button } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { pathOr, findLastIndex, contains } from 'ramda';

import { getApplicantLog, cancelAction } from '../../../queries/applicants';
import {
    StyledTimeline,
    CreateApplicantEvent,
    ChangeApplicantEvent,
    MeetingEvent,
    InterviewFeedbackEvent,
    FirstWorkingDayEvent,
    EditApplicantEvent,
    ChangeResponsibleEvent,
    CommentCreate
} from '../../user/TimeLineView';
import { ADMIN, CONSULTANT } from '../../../constants/roles';
import { getRole } from '../../../utils/users';

export default class ApplicantLogs extends Component {
    renderCancelButton = item => {
        return <Mutation
            mutation={cancelAction}
            variables={{ id: item.id }}
            refetchQueries={['applicantLog', 'applicantFilters', 'applicants']}>
            { (mutate, { loading }) =>
                <Button
                    loading={loading}
                    size='small'
                    onClick={mutate}>
                    Отменить
                </Button>
            }
        </Mutation>;
    }

    renderItem = (item, allowCancel) => {
        const props = {
            key: item.id,
            item,
            buttons: allowCancel ? this.renderCancelButton(item) : null
        };

        switch (item.type) {
            case 'Applicant:Create':
                return <CreateApplicantEvent {...props} />;
            case 'Applicant:Edit':
                return <EditApplicantEvent {...props} />;
            case 'Applicant:ChangeResponsible':
                return <ChangeResponsibleEvent {...props} />;
            case 'Applicant:ChangeStatus':
                return <ChangeApplicantEvent {...props} />;
            case 'Applicant:SetDateInterview':
                return <MeetingEvent {...props} />;
            case 'Applicant:SetInterviewFeedback':
                return <InterviewFeedbackEvent {...props} />;
            case 'Applicant:SetFirstWorkingDay':
                return <FirstWorkingDayEvent {...props} />;
            case 'Comment:Create':
                return <CommentCreate {...props} />;
            default:
                return <div />;
        }
    }

    renderLogs = items => {
        const lastNotCanceled = findLastIndex(i => !i.canceled && i.cancelable, items);

        return <StyledTimeline style={{fontSize: '100%'}} lineStyle={{left: '50%', margin: 0, padding: 0}}>
            { items.map((item, index) => this.renderItem(item, lastNotCanceled === index && contains(getRole(), [ADMIN, CONSULTANT])))}
        </StyledTimeline>
    }

    render() {
        const { id } = this.props;

        return <Query
            query={getApplicantLog}
            variables={{
                id
            }}>
            { ({ data, loading }) =>
                loading ?
                    <Spin /> :
                    this.renderLogs(pathOr([], ['applicantLog', 'items'], data))
            }
        </Query>;
    }
}
