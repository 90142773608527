import React, { Component } from 'react';
import { message } from 'antd';

import { addApplicantComment } from '../../queries/applicants';
import CommentForm from '../forms/CommentForm';
import Modal from './Modal';

export default class SupervisorCommentModal extends Component {
    onSuccess = () => {
        message.success('Комментарий успешно отправлен');
        this.props.close();
    }

    onError = () => message.error('Не удалось отправить комментарий');

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Комментарий'
            footer={null}>
            <CommentForm
                mutation={addApplicantComment}
                onSuccess={this.onSuccess}
                onError={this.onError}
                item={params}
                useValuesAsVariables />
        </Modal>;
    }
}
