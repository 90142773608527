import React, { Component, Fragment } from 'react';
import { Tabs, Button, Tooltip, Spin } from 'antd';
import { filter, contains, find, propEq, pathOr, path, pick, pathEq, isEmpty, complement, isNil } from 'ramda';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import qs from 'qs';
import moment from 'moment';

import { getHireRequests, getHireRequestFilters, availableHireRequestStatuses } from '../../queries/requests';
import Table from './table/Table';
import { LinkWrapper } from './table/FullName';
import UserLink from './table/UserLink';
import { REQUEST_STATUSES } from '../../constants/requests';
import { HIRE_REQUESTS_TABS } from '../../constants/lists';
import ModalsContext from '../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../constants/modals';
import { getRole } from '../../utils/users';
import { CONSULTANT, CLIENT_MANAGER, ADMIN } from '../../constants/roles';
import DeadlineFormat from './table/DeadlineFormat';
import HireRequestFilter from '../forms/filters/HireRequestFilter';
import { getUrlFilter } from '../../utils/urlParams';
import withUserContext from '../hocs/withUserContext';
import { getDeadlineClass } from '../../utils/deadline';
import { StyledTextAction } from './TimeLineView';
import Route from '../Route';
import { StepForwardOutlined, UserAddOutlined, CopyOutlined, EditOutlined, UserOutlined, TeamOutlined, InfoCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';

export const StyledTabs = styled(Tabs)`
    overflow: visible !important;

    .ant-tabs-nav {
        margin: 0;
    }
`;

export const Badge = styled.div`
    border-radius: 4px;
    display: inline-block;
    background: #1890ff;
    margin-left: 10px;
    color: #fff;
    padding: 0 5px;
    font-size: 13px;
`;

const filterFields = ['position', 'regionMacro', 'regionClient', 'regionAncor', 'verifier'];

class HireRequests extends Component {
    getColumns = (disableEdit) => {
        const { type } = this.props.match.params;
        const role = getRole();

        return [
            {
                title: 'Позиция',
                key: 'position',
                dataIndex: ['position', 'name'],
                render: (name, request) => {
                    const actions = this.getActions(request);

                    return (
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <LinkWrapper onClick={() => openModal(
                                    MODAL_TYPES.requestView,
                                    { request, actions }
                                )}>
                                    { name }
                                </LinkWrapper>
                            }
                        </ModalsContext.Consumer>
                    );
                }
            },
            {
                title: 'Ответственный',
                key: 'verifier',
                dataIndex: 'verifier',
                render: item => <UserLink item={item} />,
            },
            {
                title: 'Рекрутер',
                key: 'recruiter',
                dataIndex: 'recruiter',
                render: item => <UserLink item={item} />,
            },
            {
                title: 'Город',
                key: 'region',
                render: request => (
                    <Fragment>
                        <div>{path(['regionClient', 'name'], request)}</div>
                        <div>{path(['regionAncor', 'name'], request)}</div>
                    </Fragment>
                ),
            },
            {
                title: 'Количество',
                dataIndex: 'count',
                key: 'count',
                width: 100,
            },
            {
                title: 'Срок',
                dataIndex: 'deadline',
                key: 'deadline',
                width: 100,
                render: date => <DeadlineFormat date={date} />,
            },
            type === 'last' && ({
                title: 'Статус',
                dataIndex: 'status',
                key: 'type',
                render: status => (
                    <StyledTextAction className='nowrap' normal color={REQUEST_STATUSES[status].color}>
                        {REQUEST_STATUSES[status].icon} {REQUEST_STATUSES[status].title}
                    </StyledTextAction>
                ),
            }),
            {
                key: 'actions',
                align: 'center',
                render: (item, _, index) => {
                    const { user } = this.props;
                    const isConsultant = role === CONSULTANT;
                    const isManager = role === CLIENT_MANAGER;
                    const { ancorStagesEnabled } = user;
                    const allowEdit = !disableEdit && (
                        (isManager && item.status === 'clientApproval')
                        || (isConsultant && contains(item.status, ['ancorApproval', 'inwork', 'hold']))
                        || (!ancorStagesEnabled && isConsultant && contains(item.status, ['clientApproval']))
                    );
                    const actions = this.getActions(item);
                    const allowSetVerifier =
                        (isManager && pathEq(['regionMacro', 'id'], path(['regionMacro', 'id'], user), item)) ||
                        isConsultant;

                    return <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <Button.Group>
                                { !disableEdit && !!actions.length &&
                                (item.status === 'clientApproval' ?
                                    (isManager ? (pathEq(['approver', 'id'], user.id, item) && user.approver) : true) :
                                    item.status === 'finalClientApproval' ? ((pathEq(['finalApprover', 'id'], user.id, item) && user.finalApprover) || isConsultant) : true) &&
                                    <Tooltip title='Изменить статус заявки'>
                                        <Button
                                            id={`hire-status-change-btn-${index}`}
                                            icon={<StepForwardOutlined />}
                                            type='primary'
                                            onClick={() => openModal(MODAL_TYPES.hireStatusChange, {
                                                actions,
                                                id: item.id
                                            })} />
                                    </Tooltip>
                                }
                                { item.status === 'inwork' && isConsultant &&
                                    <Tooltip title='Добавить кандидата'>
                                        <Button
                                            icon={<UserAddOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.applicantForm, {
                                                requestId: item.id
                                            })} />
                                    </Tooltip>
                                }
                                { item.status === 'inwork' && isConsultant &&
                                    <Tooltip title='Скопировать кандидата'>
                                        <Button
                                            icon={<CopyOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.applicantCopy, {
                                                requestId: item.id
                                            })} />
                                    </Tooltip>
                                }
                                { allowEdit &&
                                    <Tooltip title='Редактировать'>
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.hireRequestForm, { request: item })} />
                                    </Tooltip>
                                }
                                { allowSetVerifier &&
                                    <Tooltip title='Назначить ответственного'>
                                        <Button
                                            id={`set-verifier-btn-${index}`}
                                            icon={<UserOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.setVerifier, {
                                                id: item.id,
                                                verifierId: item.verifier.id,
                                                regionClient: path(['regionClient', 'id'], item),
                                                regionMacro: path(['regionMacro', 'id'], item)
                                            })} />
                                    </Tooltip>
                                }
                                { contains(item.status, ['inwork', 'hold', 'done']) &&
                                    <Tooltip title='Кандидаты'>
                                        <Button
                                            icon={<TeamOutlined />}
                                            onClick={() => this.props.history.push(`/applicants/last${qs.stringify({
                                                filter: JSON.stringify(filter(complement(isNil), {
                                                    regionMacro: path(['regionMacro', 'id'], item),
                                                    regionClient: path(['regionClient', 'id'], item),
                                                    regionAncor: path(['regionAncor', 'id'], item),
                                                    verifier: path(['verifier', 'id'], item),
                                                    position: path(['position', 'id'], item),
                                                    hireRequest: item.id
                                                }))
                                            }, { addQueryPrefix: true })}`)} />
                                    </Tooltip>
                                }
                                <Tooltip title='История'>
                                    <Button
                                        icon={<InfoCircleOutlined />}
                                        onClick={() => openModal(MODAL_TYPES.requestView, {
                                            request: item,
                                            history: true
                                        })} />
                                </Tooltip>
                            </Button.Group>
                        }
                    </ModalsContext.Consumer>
                }
            }
        ];
    }

    getActions = request => {
        const currentStatusActions = find(propEq('key', request.status), HIRE_REQUESTS_TABS).actions || [];
        return filter(action => contains(getRole(), action.roles || []) && !(action.hide && action.hide(request)), currentStatusActions);
    }

    renderToolbarButtons = () => {
        const { type } = this.props.match.params;

        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Fragment>
                    { (getRole() === CLIENT_MANAGER) && type === 'clientApproval' &&
                        <Button icon={<PlusOutlined />} type='primary' onClick={() => openModal(MODAL_TYPES.hireRequestForm)}>
                            Создать
                        </Button>
                    }
                    { getRole() === ADMIN &&
                        <Button
                            shape='circle'
                            icon={<SettingOutlined />}
                            onClick={() => openModal(MODAL_TYPES.deadline, {
                                type: 'hireRequest'
                            })} />
                    }
                </Fragment>
            }
        </ModalsContext.Consumer>;
    }

    onChange = key => {
        const filter = pick(filterFields, this.getFilter());
        const searchPath = isEmpty(filter) ? '' : qs.stringify({
            filter: JSON.stringify(filter)
        }, { addQueryPrefix: true, strictNullHandling: true });

        this.props.history.push(`/requests/${key}${searchPath}`);
    }

    renderTabTitle = (title, data, key) => {
        const count = path(['count'], find(propEq('status', key), pathOr([], ['hireRequestFilters', 'statuses'], data)));

        return <span>{ title } { count && <Badge>{ count }</Badge> }</span>;
    }

    renderTabs = (table, data, availableStatuses = []) => {
        const { type } = this.props.match.params;
        const { ancorStagesEnabled } = this.props.user;
        const systemStatuses = ancorStagesEnabled ? ['last'] : [];
        const tabs = filter(tab => (!tab.roles || contains(getRole(), tab.roles)) && contains(tab.key, [...systemStatuses, ...availableStatuses]), HIRE_REQUESTS_TABS);

        return <StyledTabs
            onChange={this.onChange}
            activeKey={type}
            animated={{ tabPane: false }}>
            { tabs.map(tab =>
                <Tabs.TabPane
                    key={tab.key}
                    tab={this.renderTabTitle(tab.title, data, tab.key)}>
                    <Route path={`/requests/${tab.key}`} render={() => table} />
                </Tabs.TabPane>
            )}
        </StyledTabs>;
    }

    onTabSelect = data => {
        const availableStatuses = pathOr([], ['availableHireRequestStatuses'], data);
        const { type } = this.props.match.params;
        const { ancorStagesEnabled } = this.props.user;
        const systemStatuses = ancorStagesEnabled ? ['last'] : [];
        const statuses = [...systemStatuses, ...availableStatuses];
        const tabs = filter(tab => (!tab.roles || contains(getRole(), tab.roles)) && contains(tab.key, statuses), HIRE_REQUESTS_TABS);

        if (!tabs.find(tab => type === tab.key)) {
            this.props.history.replace(`/requests/${tabs[0].key}`);
        }
    }

    renderTable = ({ data }) => {
        const { user, match: { params: { type }}} = this.props;

        const isManager = getRole() === CLIENT_MANAGER;
        const regionMacro = isManager ? (
            path(['regionMacro', 'id'], user)
        ) : undefined;

        const defaultFilter = {
            regionMacro,
        };

        return <Query query={availableHireRequestStatuses} onCompleted={this.onTabSelect}>
            { ({ data: statuses, loading }) =>
                <Spin spinning={loading}>
                    <Table
                        name='hireRequests'
                        query={getHireRequests}
                        rowClassName={getDeadlineClass}
                        variables={{
                            sorting: {
                                field: 'deadline',
                                order: 1,
                            },
                        }}
                        columns={this.getColumns(isManager ? !user.allowHireRequestApproval : false).filter(Boolean)}
                        toolbarButtons={this.renderToolbarButtons()}
                        onPageChange={this.clearSelected}
                        FilterForm={HireRequestFilter}
                        customWrapper={table => this.renderTabs(table, data, pathOr([], ['availableHireRequestStatuses'], statuses))}
                        defaultFilter={defaultFilter}
                        staticFilter={type === 'last' ? {
                            maxDeadlineDate: moment.utc().add(2, 'days').startOf('day').format(),
                            statuses: ['ancorAwaiting', 'ancorApproval', 'inwork'],
                        } : {
                            status: type,
                        }} />
                </Spin>
            }
        </Query>;
    }

    getFilter = () => {
        return getUrlFilter(this.props.location);
    }

    render() {
        return <Query
            query={getHireRequestFilters}
            variables={{
                filter: pick(filterFields, this.getFilter())
            }}>
            { this.renderTable }
        </Query>;
    }
}

export default withUserContext(HireRequests);
