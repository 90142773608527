import React, { Component } from 'react';

import HireRequestComment from '../user/admin/HireRequestComment';
import Modal from './Modal';

export default class HireRequestStatusChangeModal extends Component {
    render() {
        const { modal, params, close } = this.props;

        return <Modal
            {...modal}
            title='Изменить статус заявки'
            footer={null}>
            <HireRequestComment params={params} close={close} />
        </Modal>
    }
}
