export const DEFAULT_ERROR = 'Ошибка соединения с сервером';
export const DEFAULT_LOGIN_ERROR = 'Не удалось войти';
export const DEFAULT_RESET_ERROR = 'Не удалось изменить пароль';
export const DEFAULT_RECOVERY_ERROR = 'Не удалось отправить запрос на изменение пароля';

const ERRORS = {
    'Banned': 'Вход временно заблокирован',
    'Bad Credentials': 'Неверный логин или пароль',
    '$property must be a positive number': '$property должно быть положительным числом',
    'user with username \'$value\' already exists': 'Пользователь с почтой \'$value\' уже существует',
    'Password resetting already requested': 'Запрос на изменение пароля уже был отправлен',
    'region with $property \'$value\' already exists': 'Регион \'$value\' уже существует',
    'user is not allowed to do final approvement': 'Для данного пользователя не доступно финальное согласование'
};

export const ERRORS_LABELS = {
    name: 'таким названием',
    code: 'таким кодом',
};

export default ERRORS;
