import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Textarea from './formComponents/Textarea';

const validationSchema = yup.object().shape({
    comment: yup.object().shape({
        text: yup.string().nullable().required()
    })
});

class CommentForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='comment.text'
                component={Textarea}
                label='Комментарий' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(CommentForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item
});
