import React, { Fragment } from 'react';
import { Popover } from 'antd';
import { path } from 'ramda';
import { InfoCircleTwoTone } from '@ant-design/icons';

import 'antd/lib/popover/style/index.less';
import 'antd/lib/tooltip/style/index.less';

import { REQUEST_STATUSES } from '../../../constants/requests';
import { StyledTextAction, StyledDT, StyledDD, StyledDL } from '../TimeLineView';
import DeadlineFormat from './DeadlineFormat';

const Request = ({ request }) => {
    if (!request) {
        return null;
    }

    const status = REQUEST_STATUSES[path(['status'], request)];

    return request ? (
        <Fragment>
            <StyledTextAction>{path(['position', 'name'], request)}</StyledTextAction> <Popover trigger="click" placement="bottom" content={(
                <StyledDL>
                    <StyledDT>Позиция:</StyledDT>
                    <StyledDD>{path(['position', 'name'], request)}</StyledDD>
                    <StyledDT>Количество:</StyledDT>
                    <StyledDD>{request.count}</StyledDD>
                    { status && (
                        <Fragment>
                            <StyledDT>Статус:</StyledDT>
                            <StyledDD>{status.title}</StyledDD>
                        </Fragment>
                    )}
                    <StyledDT>Срок:</StyledDT>
                    <StyledDD><DeadlineFormat date={request.deadline} /></StyledDD>
                    { request.comment && (
                        <Fragment>
                            <StyledDT>Комментарий:</StyledDT>
                            <StyledDD>{request.comment}</StyledDD>
                        </Fragment>
                    )}
                </StyledDL>
            )} title="Заявка">
                <InfoCircleTwoTone />
            </Popover>
        </Fragment>
    ) : null;
}

export default Request;
