import React, { Component } from 'react';
import { Menu, Layout } from 'antd';
import { withRouter, matchPath, Link } from 'react-router-dom';
import { find, contains } from 'ramda';

import { ADMIN_MENU_ITEMS } from '../../../constants/adminMenu';
import { getRole } from '../../../utils/users';

class AdminMenu extends Component {
    state = {
        collapsed: false
    };

    onCollapse = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    getSelectedKey() {
        const selected = find(item =>
            item.key === '/' ? item.key === this.props.location.pathname :
            matchPath(this.props.location.pathname, {
                path: item.route || item.key
            }), ADMIN_MENU_ITEMS()
        );

        return selected ? [selected.key] : null;
    }

    render() {
        return <Layout.Sider
            width={170}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}>
            <Menu
                theme='dark'
                selectable={false}
                mode='inline'
                selectedKeys={this.getSelectedKey()}>
                { ADMIN_MENU_ITEMS().map(item =>
                    !item.roles || contains(getRole(), item.roles) ?
                        <Menu.Item key={item.key}>
                            <Link to={item.key}>
                                {item.icon}
                                <span>{ item.title }</span>
                            </Link>
                        </Menu.Item> : null
                )}
            </Menu>
        </Layout.Sider>;
    }
}

export default withRouter(AdminMenu);
