import React, { Component, Fragment } from 'react';
import { Table, Radio } from 'antd';
import { pathOr, filter, path } from 'ramda';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PeriodsReport from './PeriodsReport';
import { getHireRequestCountStat } from '../../../../queries/requests';
import { extendSearchPath, getUrlFilter, getUrlParams } from '../../../../utils/urlParams';
import { ErrorBlockIcon, ErrorBlock } from './Report';

const RadioWrapper = styled.div`
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
`;

class ReportTable extends Component {
    getColumns = () => {
        const { filters, data, regions } = this.props;
        const showCity = filters.regionClient;
        const subtitle = types => {
            const sum = data.reduce((res, cur) => {
                const value = types.reduce((r, type) => r + pathOr(0, [type], cur), 0);
                return res + value;
            }, 0);

            if (filters.regionMacro && !filters.regionClient) {
                return `(Всего по макрорегиону ${regions[filters.regionMacro] || ''}: ${sum})`;
            } else if (filters.regionClient && !filters.regionAncor) {
                return `(Всего по региону ${regions[filters.regionClient] || ''}: ${sum})`;
            } else {
                return `(Всего: ${sum})`;
            }
        };

        return filter(({ hide }) => !hide, [
            {
                title: 'Регион',
                dataIndex: ['regionClient', 'name'],
                render: (region, _, index) => showCity ? (index === 0 ? region : null) : region
            },
            {
                title: 'Город',
                dataIndex: ['regionAncor', 'name'],
                hide: !showCity
            },
            {
                title: `На начало периода ${subtitle(['inworkBefore'])}`,
                dataIndex: ['inworkBefore'],
                render: inworkBefore => inworkBefore || 0
            },
            {
                title: `Поступило ${subtitle(['created'])}`,
                dataIndex: ['created'],
                render: created => created || 0
            },
            {
                title: `Закрыто ${subtitle(['done'])}`,
                dataIndex: ['done'],
                render: done => done || 0
            },
            {
                title: `В работе ${subtitle(['inworkBefore', 'created'])}`,
                render: item => pathOr(0, ['inworkBefore'], item) + pathOr(0, ['created'], item)
            }
        ]);
    }

    render() {
        const { data, countPositions, onChangeCountPositions, error } = this.props;

        return <Fragment>
            <RadioWrapper>
                <Radio.Group value={!!countPositions} onChange={onChangeCountPositions}>
                    <Radio.Button value={false}>Количество заявок</Radio.Button>
                    <Radio.Button value={true}>Количество позиций</Radio.Button>
                </Radio.Group>
            </RadioWrapper>
            <Table
                columns={this.getColumns()}
                dataSource={error ? [] : data}
                pagination={false}
                rowKey={item => `${path(['regionMacro', 'id'], item)}-${path(['regionClient', 'id'], item)}-${path(['regionAncor', 'id'], item)}`}
                locale={{
                    emptyText: error ?
                        <ErrorBlock>
                            <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                            <div>Не удалось отобразить данные</div>
                        </ErrorBlock> :
                        'Нет данных'
                }} />
        </Fragment>;
    }
}

export default class HireRequestCountStatReport extends Component {
    state = {
        regions: {}
    };

    onChangeCountPositions = e => {
        this.props.history.replace(extendSearchPath(this.props.location, { countPositions: e.target.value }));
    }

    getCountPositions = () => {
        const { countPositions } = getUrlParams(this.props.location);

        if (!countPositions) {
            return false;
        }

        try {
            const value = JSON.parse(countPositions);
            return value;
        } catch (e) {
            return false;
        }
    }

    onChangeRegions = regions => this.setState({ regions });

    render() {
        const countPositions = this.getCountPositions();
        const filters = getUrlFilter(this.props.location);

        return (
            <PeriodsReport
                query={getHireRequestCountStat}
                ChartComponent={props =>
                    <ReportTable
                        {...props}
                        filters={filters}
                        countPositions={countPositions}
                        onChangeCountPositions={this.onChangeCountPositions}
                        regions={this.state.regions} />
                }
                additionalVariables={{ countPositions }}
                getData={data => data}
                onChangeRegions={this.onChangeRegions}
                regions={this.state.regions}
            />
        );
    }
}
