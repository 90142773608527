import React, { Component } from 'react';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';
import { withApollo } from 'react-apollo';

import 'antd/lib/modal/style/index.less';
import 'antd/lib/message/style/index.less';

import { sendHireRequestToClientApproval, createHireRequest, editHireRequest } from '../../queries/requests';
import HireRequestCommentForm from '../forms/HireRequestCommentForm';
import Modal from './Modal';

class SendHireRequestModal extends Component {
    onSubmit = values => {
        const { client, params: { request: { id, ...data }}} = this.props;

        return client.mutate({
            mutation: id ? editHireRequest : createHireRequest,
            variables: { values: data, id }
        }).then(payload =>
            client.mutate({
                mutation: sendHireRequestToClientApproval,
                variables: {
                    ...values,
                    id: path(['data', id ? 'editHireRequest' : 'createHireRequest', 'id'], payload)
                }
            })
            .then(() => this.onSuccess())
            .catch(() => this.onError())
        ).catch(() => this.onError());
    }

    onSuccess = () => {
        message.success('Заявка была успешно отправлена');
        this.props.close();
        this.props.history.goBack();
    }

    onError = () => message.error('Не удалось отправить заявку');

    render() {
        const { modal } = this.props;

        return <Modal
            {...modal}
            title='Отправить заявку'
            footer={null}>
            <HireRequestCommentForm
                onSubmit={this.onSubmit}
                className='supervisor-layout' />
        </Modal>
    }
}

export default withApollo(withRouter(SendHireRequestModal));
