import gql from 'graphql-tag';

export const getClientManagers = gql(`
    query clientManagers($filter: ClientManagerFilterInput, $pagination: PaginationInput, $sorting: UserSortInput) {
        clientManagers(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                username,
                lastName,
                firstName,
                middleName,
                phone,
                finalApprover,
                regionMacro {
                    id,
                    name
                },
                regionClient {
                    id,
                    name
                },
                enabled,
                activated,
                lastLoginAt,
                allowHireRequestApproval,
                applicantStatusManagementEnabled,
                approver
            }
        }
    }
`);

export const createClientManager = gql(`
    mutation createClientManager($values: ClientManagerInput!) {
        createClientManager(clientManager: $values) {
            id
        }
    }
`);

export const editClientManager = gql(`
    mutation editClientManager($values: ClientManagerEditInput!, $id: ID!) {
        editClientManager(clientManager: $values, id: $id) {
            id
        }
    }
`);
