import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import * as yup from 'yup';

import Input from './formComponents/Input';
import Textarea from './formComponents/Textarea';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    requirements: yup.string().required(),
});

class PositionForm extends Component {
    render() {
        return <div>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Field
                name='requirements'
                component={Textarea}
                label='Обязанности исполнителя' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>
    }
}

export default withFormWrapper(PositionForm, {
    validationSchema,
    mapPropsToValues: prop('position')
});
