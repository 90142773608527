import React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { path, find, propEq } from 'ramda';

import { HIRE_REQUESTS_TABS } from '../../constants/lists';
import { APPLICANTS_STATUS_ICONS, APPLICANTS_STATUS_COLORS, APPLICANTS_STATUSES } from '../../constants/applicants';
import { REQUEST_STATUSES } from '../../constants/requests';
import FullName from './table/FullName';
import Request from './table/Request';
import { InterviewResults } from '../modals/ApplicantDetailModal';
import { CalendarOutlined, FormOutlined, EditOutlined } from '@ant-design/icons';

export const StyledTimeline = styled(Timeline)`
    display: flex;
    flex-direction: column;
    & > div{
        width: 50%;
    }
    & > div:nth-child(even){
        align-self: flex-end;
        .timeline-btns {
            left: 0;
            right: auto;
        }
        & > div:first-child{
            left: -25px!important;
        }
    }
    & > div:nth-child(odd){
        padding-right: 45px;
        text-align: right!important;
        padding-left: 0!important;
        & > div:first-child{
            right: -25px;
            left: initial!important;
        }
    }
`;

const roleColor = {
    Consultant: '#ff4136',
    Supervisor: '#1890ff',
    ClientManager: '#9400d3',
    Admin: '#ff4136',
};

export const StyledUserStatus = styled.span`
    text-transform: uppercase;
    border-radius: 50%;
    margin-right: 5px;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    background: ${p => roleColor[p.role]};
    color: #fff;
`;

export const StyledTextAction = styled.span`
    font-weight: ${p => p.normal ? 'normal' : 'bold'};
    color: ${p => p.color};
`;

export const StyledDL = styled.dt`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    min-width: 250px;
    max-width: 600px;
    overflow: visible;
`;

export const StyledDT = styled.dt`
    flex: 0 0 30%;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledDD = styled.dd`
    flex:0 0 70%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Buttons = styled.div`
    position: absolute;
    right: 0;
    bottom: -15px;
`;

const formatDate = date => moment.utc(date).format('DD.MM.YYYY в HH:mm');
const roleLetter = {
    Consultant: 'К',
    Supervisor: 'С',
    ClientManager: 'М',
    Admin: 'А',
}
const TimelineInitiator = ({ initiator }) => <Tooltip title={initiator.__typename}><StyledUserStatus role={initiator.__typename}>{ roleLetter[initiator.__typename] }</StyledUserStatus><FullName item={initiator} /></Tooltip>;

const CanceledText = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-style: italic;
    padding-top: 7px;
    margin-top: 7px;
`;

const Canceled = ({ item }) => item.canceled && <CanceledText><FullName item={item.canceledBy} /> отменил событие { formatDate(item.canceledAt) }</CanceledText>;

export const CreateEvent = ({ item, edit = false, buttons }) =>
    <TimelineEvent
        title={<TimelineInitiator initiator={item.initiator} />}
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        createdAt={formatDate(item.date)}
        icon={<FormOutlined />}
        iconColor="#01203F"
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        {edit ? 'отредактировал' : 'создал'} заявку <Request request={item.hireRequest} />
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;

export const EditEvent = props => <CreateEvent {...props} edit={true} />;

export const ChangeEvent = ({ item, buttons }) => {
    const status = path(['data', 'status'], item);
    const previousStatus = path(['data', 'previousStatus'], item);
    const comment = path(['data', 'comment'], item);

    return <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={REQUEST_STATUSES[status].icon}
        iconColor={REQUEST_STATUSES[status].color}
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Изменен статус <StyledTextAction color={REQUEST_STATUSES[previousStatus].color}>
            {path(['title'], find(propEq('key', previousStatus), HIRE_REQUESTS_TABS))}
            </StyledTextAction> на <StyledTextAction color={REQUEST_STATUSES[status].color}>
            {path(['title'], find(propEq('key', status), HIRE_REQUESTS_TABS))}
            </StyledTextAction>
        { comment && <div><i>«{ comment }»</i></div> }
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;
}

export const MeetingEvent = ({ item, buttons }) =>
    <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<CalendarOutlined />}
        iconColor="#9013FE"
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Добавлен статус <StyledTextAction color="#9013FE">встреча</StyledTextAction> { formatDate(item.data.date) }<br/>
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;

export const InterviewFeedbackEvent = ({ item, buttons }) =>
<TimelineEvent
    contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
    title={<TimelineInitiator initiator={item.initiator} />}
    createdAt={formatDate(item.date)}
    icon={<CalendarOutlined />}
    iconColor="#9013FE"
    iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
    bubbleStyle={{width: '50px', height: '50px'}}
    style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
    >
    Добавлены результаты интервью <InterviewResults interviewFeedback={path(['data', 'feedback'], item)} /><br/>
    <Canceled item={item} />
    <Buttons className='timeline-btns'>{ buttons }</Buttons>
</TimelineEvent>;

export const FirstWorkingDayEvent = ({ item, buttons }) =>
    <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<CalendarOutlined />}
        iconColor="#9013FE"
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Добавлен <StyledTextAction color="#9013FE">дата Т/У</StyledTextAction> { moment.utc(item.data.firstWorkingDay).format('DD.MM.YYYY') }<br/>
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;

export const RequestRefreshDeadlineEvent = ({ item, buttons }) =>
    <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<EditOutlined />}
        iconColor="#795548"
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
    >
        Кандидаты направлены
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;

export const CreateApplicantEvent = ({ item, edit = false, buttons }) =>
    <TimelineEvent
        title={<TimelineInitiator initiator={item.initiator} />}
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none' }}
        createdAt={formatDate(item.date)}
        icon={<FormOutlined />}
        iconColor="#01203F"
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        {edit ? 'отредактировал' : 'добавил'} кандидата <StyledTextAction>{ `${item.data.contact.firstName} ${item.data.contact.lastName}` }</StyledTextAction>
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;

export const EditApplicantEvent = props => <CreateApplicantEvent {...props} edit={true} />;

export const ChangeResponsibleEvent = ({ item, buttons }) => {
    return <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<FormOutlined />}
        iconColor='#9013FE'
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Изменен рекрутер <StyledTextAction color='#9013FE'>
            <FullName item={item.previousResponsible} />
            </StyledTextAction> на <StyledTextAction color='#9013FE'>
            <FullName item={item.responsible} />
            </StyledTextAction>
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;
}

export const ChangeApplicantEvent = ({ item, buttons }) => {
    const status = path(['data', 'status'], item);
    const previousStatus = path(['data', 'previousStatus'], item);
    const comment = path(['data', 'comment'], item);

    return <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={APPLICANTS_STATUS_ICONS[status]}
        iconColor={APPLICANTS_STATUS_COLORS[status]}
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Изменен статус <StyledTextAction color={APPLICANTS_STATUS_COLORS[previousStatus]}>
            { APPLICANTS_STATUSES[previousStatus] }
            </StyledTextAction> на <StyledTextAction color={APPLICANTS_STATUS_COLORS[status]}>
            { APPLICANTS_STATUSES[status] }
            </StyledTextAction>
        { comment && <div><i>«{ comment }»</i></div> }
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;
}

export const ChangeVerifierEvent = ({ item, buttons }) => {
    return <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<FormOutlined />}
        iconColor='#9013FE'
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
        >
        Изменен ответственный <StyledTextAction color='#9013FE'>
            <FullName item={item.previousVerifier} />
            </StyledTextAction> на <StyledTextAction color='#9013FE'>
            <FullName item={item.verifier} />
            </StyledTextAction>
        <Canceled item={item} />
        <Buttons className='timeline-btns'>{ buttons }</Buttons>
    </TimelineEvent>;
}

export const CommentCreate = ({ item }) => {
    const comment = path(['data', 'comment', 'text'], item);

    return <TimelineEvent
        contentStyle={{width: 'auto', display: 'inline-block', backgroundColor: 'rgb(230, 236, 246)', boxShadow: 'none'}}
        title={<TimelineInitiator initiator={item.initiator} />}
        createdAt={formatDate(item.date)}
        icon={<FormOutlined />}
        iconColor='#9013FE'
        iconStyle={{fontSize: '1.4rem', width: '52px', cursor: 'inherit'}}
        bubbleStyle={{width: '50px', height: '50px'}}
        style={{ position: 'relative', opacity: item.canceled ? .5 : 1 }}
    >
        Добавлен комментарий { <div><i>«{ comment }»</i></div> }
    </TimelineEvent>;
}
