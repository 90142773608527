import React, { Fragment} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import FullName from '../table/FullName';
import { APPLICANTS_STATUS_ICONS, APPLICANTS_STATUS_COLORS } from '../../../constants/applicants';

const ItemWrapper = styled.div`
    display: flex;
    flex: 1;
    min-width: 0;
    cursor: pointer;
`;

const UserItem = ({ item, title, onClick, hideDate }) => (
    <div className="item box">
        <ItemWrapper onClick={onClick}>
            <div className="type-box" style={{ background: APPLICANTS_STATUS_COLORS[item.status] }}>
                {APPLICANTS_STATUS_ICONS[item.status]}
                <span className="name">
                    { title }
                </span>
            </div>
            <div className="title-box">
                <div className="title">
                    <FullName item={item.contact} />
                </div>
                <div className="sub-title">
                    { item.hireRequest.position.name }
                    { item.hireRequest.address &&
                        <Tooltip title={item.hireRequest.address} placement='topLeft'>
                            <div className="address">{ item.hireRequest.address }</div>
                        </Tooltip>
                    }
                </div>
            </div>
        </ItemWrapper>
        { item.status ==='supervisorInterview' &&
        <div className="other-box">
            { !hideDate && (
                item.dateInterview ?
                    <Fragment>
                        <div className="other-box">
                            <div className="date">
                                <Link to={`/user/interview/${item.id}`} className="btn btn-link-small">
                                    <i className="icon-calendar"></i>{ moment.utc(item.dateInterview).format('DD.MM.YYYY') }
                                </Link>
                            </div>
                            <div className="time">
                                <Link to={`/user/interview/${item.id}`} className="btn btn-link-small">
                                    <i className="icon-clock"></i>{ moment.utc(item.dateInterview).format('HH:mm') }
                                </Link>
                            </div>
                        </div>
                    </Fragment> :
                    <Link to={`/user/interview/${item.id}`} className="btn btn-link-small">
                        Внести дату
                    </Link>
            )}
        </div>
        }
    </div>
);

export default UserItem;
