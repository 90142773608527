import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { pathOr, path } from 'ramda';

import { getPositions } from '../../../queries/positions';
import { getRegionsAncor } from '../../../queries/regions';
import { getSupervisors } from '../../../queries/supervisors';
import { getConsultants } from '../../../queries/consultants';
import { getClientManagers } from '../../../queries/clientManagers';
import { getHireRequest, getHireRequests } from '../../../queries/requests';
import { getUrlFilter } from '../../../utils/urlParams';
import { getRegionsCascaderData, getRegionsManagerCascaderData } from '../../../utils/requests';
import withFilterForm from '../../hocs/withFilterForm';
import HireRequestSelectSmall from '../../user/HireRequestSelectSmall';
import { getFullNameString } from '../../user/table/FullName';
import Select, { LazyLoadSelect } from '../formComponents/Select';
import Cascader from '../formComponents/Cascader';
import Datepicker, { DatepickerGroup } from '../formComponents/Datepicker';
import withUserContext from '../../hocs/withUserContext';

class ReportFilter extends Component {
    render() {
        const { additionalFilter, location } = this.props;
        const regionMacro = path(['user', 'regionMacro', 'id'], this.props);

        const values = getUrlFilter(location);
        const position = path(['position'], values);
        const supervisor = path(['supervisor'], values);
        const responsible = path(['responsible'], values);
        const hireRequest = path(['hireRequest'], values);
        const regionMacroFilter = path(['regionMacro'], values);
        const regionAncor = path(['regionAncor'], values);
        const regionClient = path(['regionClient'], values);

        return (
            <Fragment>
                <Row gutter={4}>
                    { additionalFilter && (
                        <Col span={8}>
                            <Field
                                component={Select}
                                {...additionalFilter}
                            />
                        </Col>
                    )}
                    <Col span={8}>
                        <Query
                            query={getPositions}
                            variables={{
                                filter: {
                                    enabled: true
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='position'
                                    component={Select}
                                    options={pathOr([], ['positions', 'items'], data)}
                                    loading={loading}
                                    placeholder='Позиция'
                                    namePath='name'
                                    valuePath='id'
                                    disabled={!!supervisor || !!hireRequest}
                                    allowClear />
                            }
                        </Query>
                    </Col>
                    <Col span={additionalFilter ? 8 : 16}>
                        <Query
                            query={getRegionsAncor}
                            variables={{ filter: { regionMacro }}}
                        >
                            { ({ data }) =>
                                <Field
                                    name={regionMacro ? 'regionClient' : 'regionMacro'}
                                    additionalNames={regionMacro ? ['regionAncor'] : ['regionClient', 'regionAncor']}
                                    component={Cascader}
                                    placeholder={regionMacro ? 'Регион/Город' : 'Макрорегион/Регион/Город'}
                                    options={regionMacro ? getRegionsManagerCascaderData(data) : getRegionsCascaderData(data)}
                                    disabled={!!supervisor || !!hireRequest}
                                />
                            }
                        </Query>
                    </Col>
                </Row>
                <Row gutter={4} style={{ marginTop: 4 }}>
                    <Col span={6}>
                        <Query
                            query={getClientManagers}
                            variables={{
                                filter: {
                                    regionMacro: regionMacroFilter || regionMacro,
                                    regionClient: regionClient,
                                }
                            }}
                        >
                            { ({ data : dataManagers, loading: loadingManagers }) =>
                                <Query
                                    query={getSupervisors}
                                    variables={{
                                        filter: {
                                            regionMacro: regionMacroFilter || regionMacro,
                                            regionClient: regionClient,
                                            regionAncor: regionAncor,
                                        }
                                    }}
                                >
                                    { ({ data, loading }) =>
                                        <Field
                                            name='supervisor'
                                            component={Select}
                                            options={[
                                                ...pathOr([], ['supervisors', 'items'], data),
                                                ...pathOr([], ['clientManagers', 'items'], dataManagers),
                                            ]}
                                            loading={loading || loadingManagers}
                                            placeholder='Ответственный'
                                            renderName={getFullNameString}
                                            valuePath='id'
                                            renderSmall={HireRequestSelectSmall}
                                            allowClear
                                            disabled={!!hireRequest}
                                        />
                                    }
                                </Query>
                            }
                        </Query>
                    </Col>
                    <Col span={6}>
                        <Query
                            query={getConsultants}
                            variables={{
                                filter: {
                                    enabled: true
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='responsible'
                                    component={Select}
                                    options={pathOr([], ['consultants', 'items'], data)}
                                    loading={loading}
                                    placeholder='Рекрутер'
                                    renderName={getFullNameString}
                                    valuePath='id'
                                    allowClear />
                            }
                        </Query>
                    </Col>
                    <Col span={6}>
                        <Field
                            name='hireRequest'
                            component={LazyLoadSelect}
                            placeholder='Заявка'
                            optionsPath='hireRequests'
                            namePath='position.name'
                            valuePath='id'
                            renderSmall={HireRequestSelectSmall}
                            query={getHireRequests}
                            singleQuery={getHireRequest}
                            singleQueryPath='hireRequest'
                            variables={{
                                filter: {
                                    regionMacro: regionMacroFilter || regionMacro,
                                    regionClient: regionClient,
                                    regionAncor: regionAncor,
                                    position: position,
                                    verifier: supervisor,
                                    recruiter: responsible,
                                },
                                sorting: {
                                    field: 'createdAt',
                                    order: -1
                                }
                            }}
                            allowClear />
                    </Col>
                    <Col span={6}>
                        <DatepickerGroup>
                            <Field
                                name='minDate'
                                component={Datepicker}
                                 startOf={'day'}
                                placeholder='От' />
                            <Field
                                name='maxDate'
                                component={Datepicker}
                                endOf={'day'}
                                placeholder='До' />
                        </DatepickerGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withFilterForm(withUserContext(ReportFilter));
