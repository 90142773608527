import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import withUserContext from '../hocs/withUserContext';
import Textarea from './formComponents/Textarea';
import SubmitButton from './formComponents/SubmitButton';
import Datepicker from './formComponents/Datepicker';
import FeedbackFields from './FeedbackFields';
import InterviewDateFields from './InterviewDateFields';
import { required } from '../../utils/yup';

class ApplicantStatusChangeForm extends Component {
    validateComment = value => {
        return required(value);
    }

    render() {
        return <Fragment>
            { this.props.showDateField &&
                <Field
                    name='date'
                    component={Datepicker}
                    label='Дата Т/У' />
            }
            { this.props.showInterviewDateFields &&
                <InterviewDateFields applicant={this.props.applicant} prefix='interviewDate' />
            }
            { this.props.showInterviewFields &&
                <FeedbackFields user={this.props.user} />
            }
            <Field
                name='comment'
                component={Textarea}
                validate={this.validateComment}
                required
                label='Комментарий' />
            <SubmitButton>
                Отправить
            </SubmitButton>
        </Fragment>;
    }
}

export default withUserContext(withFormWrapper(ApplicantStatusChangeForm, {
    mapPropsToValues: prop('data')
}));
