import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { pick } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';

const validationSchema = yup.object().shape({
    password: yup.string().min(6, 'Пароль должен содержать 6 или более символов').required()
});

class ResetForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password'
                customIcon='password' />
            <div className='controls'>
                <SubmitButton>
                    Далее
                </SubmitButton>
            </div>
        </Fragment>
    }
}

export default withFormWrapper(ResetForm, {
    validationSchema,
    mapPropsToValues: pick(['token'])
});
