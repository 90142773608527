import React, { Component } from 'react';
import { Modal, Tabs, Button } from 'antd';
import styled from 'styled-components';
import { path, find, propEq, contains } from 'ramda';

import HireRequestComment from '../user/admin/HireRequestComment';
import DateFormat from '../user/table/DateFormat';
import { REQUEST_STATUSES } from '../../constants/requests';
import DetailField from '../user/DetailField';
import FullName from '../user/table/FullName';
import { GENDERS, EDUCATION } from '../../constants/applicants';
import { Separator } from './ApplicantDetailModal';
import HireRequestLog from '../user/admin/HireRequestLog';
import { downloadHireRequest } from '../../utils/files';
import { getRole } from '../../utils/users';
import { CONSULTANT, ADMIN } from '../../constants/roles';
import { FileExcelOutlined } from '@ant-design/icons';

const Content = styled.div`
    margin-bottom: 20px;
`;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
        overflow: hidden;
    }
`;

const Wrapper = styled.div`
    padding: 0 15px 15px;
`;

export default class HireRequestViewModal extends Component {
    render() {
        const { modal, params: { request, actions = [], history }, close } = this.props;

        return <StyledModal
            {...modal}
            title={
                <span>{ request.position.name }
                { contains(getRole(), [CONSULTANT, ADMIN]) &&
                    <Button icon={<FileExcelOutlined />} shape='circle' onClick={() => downloadHireRequest(request.id)} style={{ marginLeft: 10 }} />
                }</span>
            }
            footer={null}>
            <Tabs defaultActiveKey={history ? 'history' : 'info'}>
                <Tabs.TabPane key='info' tab='Информация'>
                    <Wrapper>
                        <Content>
                            <DetailField title='Позиция' value={request.position.name} />
                            <DetailField title='Статус' value={REQUEST_STATUSES[request.status].title} />
                            <DetailField title='Необходимое количество исполнителей' value={request.count} />
                            <DetailField title='Обязанности исполнителя' value={request.requirements} />
                            <DetailField title='Автор' value={<FullName item={request.initiator} />} />
                            <DetailField title='Ответственный' value={<FullName item={request.verifier} />} />
                            <DetailField title='Ответственный за первое согласование' value={<FullName item={request.approver} />} />
                            <DetailField title='Ответственный за финальное согласование' value={<FullName item={request.finalApprover} />} />
                            <DetailField title='Рекрутер' value={<FullName item={request.recruiter} />} />
                            <DetailField title='Срок' value={<DateFormat date={request.deadline} utc />} />
                            <DetailField title='Комментарий статуса' value={request.statusComment} />
                            <DetailField title='Создана' value={<DateFormat date={request.createdAt} />} />
                            <Separator />
                            <h3>Место работы</h3>
                            <DetailField title='Регион' value={path(['regionClient', 'name'], request)} />
                            <DetailField title='Город' value={path(['regionAncor', 'name'], request)} />
                            <DetailField title='Адрес' value={request.address} />
                            <Separator />
                            <h3>Требования к кандидату</h3>
                            <DetailField title='Пол' value={request.gender && request.gender.length ?
                                request.gender.map(gender => <div key={gender}>
                                    {path(['label'], find(propEq('value', gender), GENDERS))}
                                </div>) : null
                            } />
                            <DetailField title='Минимальный возраст' value={request.minAge} />
                            <DetailField title='Максимальный возраст' value={request.maxAge} />
                            <DetailField title='Квалификация' value={request.qualification} />
                            <DetailField title='Квалификация - доп требования' value={request.qualificationAdditions} />
                            <DetailField title='Образование' value={path(['label'], find(propEq('value', request.educationLevel), EDUCATION))} />
                            <Separator />
                            <h3>Условия</h3>
                            { request.firstWorkingDate && <DetailField title='Первый рабочий день' value={<DateFormat date={request.firstWorkingDate} />} utc />}
                            <DetailField title='График работы' value={path(['jobConditions', 'schedule'], request)} />
                            <DetailField title='Заработная плата (гросс)' value={path(['jobConditions', 'salary'], request)} />
                            <DetailField title='Бонусы (гросс)' value={path(['jobConditions', 'bonus'], request)} />
                            <DetailField title='Компенсация на дорогу' value={path(['jobConditions', 'carCompensation'], request)} />
                            <DetailField title='Комментарий' value={request.comment} />
                        </Content>
                        { !!actions.length &&
                            <HireRequestComment
                                close={close}
                                params={{
                                    id: request.id,
                                    actions
                                }} />
                        }
                    </Wrapper>
                </Tabs.TabPane>
                <Tabs.TabPane key='history' tab='История'>
                    <Wrapper>
                        <HireRequestLog id={request.id} />
                    </Wrapper>
                </Tabs.TabPane>
            </Tabs>
        </StyledModal>;
    }
}
