import React, { Component } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { omit, pathOr, path, groupBy, values } from 'ramda';
import XLSX from 'xlsx';
import { FileExcelOutlined } from '@ant-design/icons';

import { getUrlFilter } from '../../../../utils/urlParams';
import PeriodsReportFilter from '../../../forms/filters/PeriodsReportFilter';
import { Toolbar } from '../../table/Table';
import withUserContext from '../../../hocs/withUserContext';
import { getRole } from '../../../../utils/users';
import { CLIENT_MANAGER } from '../../../../constants/roles';

class PeriodsReport extends Component {
    getData = (item, filters = {}) => {
        const data = pathOr([], ['data', 'hireRequestCountStat'], item);

        if (filters.regionAncor || filters.regionClient) {
            return data;
        } else {
            return values(groupBy(path(['regionClient', 'id']), data)).map(regions => ({
                regionClient: path([0, 'regionClient'], regions),
                created: regions.reduce((res, cur) => res + cur.created, 0),
                done: regions.reduce((res, cur) => res + cur.done, 0),
                inwork: regions.reduce((res, cur) => res + cur.inwork, 0),
                inworkBefore: regions.reduce((res, cur) => res + cur.inworkBefore, 0)
            }));
        }
    }

    renderReport = (data, filters) => {
        const {
            ChartComponent,
            getData,
            field,
            value,
            formatValue,
            chartProps
        } = this.props;

        return <Spin
            spinning={data.loading}
            delay={1000}>
            <ChartComponent
                data={getData(this.getData(data, filters), filters)}
                field={field}
                value={value}
                formatValue={formatValue && formatValue().format}
                valueTitle={formatValue && formatValue().title}
                valueConfig={formatValue && formatValue().valueConfig}
                error={data.error}
                {...chartProps}
            />
        </Spin>;
    }

    downloadExcel = (reportData, filters) => {
        const data = this.getData(reportData, filters);
        const subtitle = types => {
            const sum = data.reduce((res, cur) => {
                const value = types.reduce((r, type) => r + pathOr(0, [type], cur), 0);
                return res + value;
            }, 0);

            if (filters.regionMacro && !filters.regionClient) {
                return `(Всего по макрорегиону ${this.props.regions[filters.regionMacro] || ''}: ${sum})`;
            } else if (filters.regionClient && !filters.regionAncor) {
                return `(Всего по региону ${this.props.regions[filters.regionClient] || ''}: ${sum})`;
            } else {
                return `(Всего: ${sum})`;
            }
        };
        const ws = XLSX.utils.json_to_sheet(data.map(item => {
            return {
                ...(filters.regionClient ? {
                    'Регион': path(['regionClient', 'name'], item),
                    'Город': path(['regionAncor', 'name'], item)
                } : {
                    'Регион': path(['regionClient', 'name'], item)
                }),
                [`На начало периода ${subtitle(['inworkBefore'])}`]: item.inworkBefore || 0,
                [`Поступило ${subtitle(['created'])}`]: item.created || 0,
                [`Закрыто ${subtitle(['done'])}`]: item.done || 0,
                [`В работе ${subtitle(['inworkBefore', 'created'])}`]: (item.inworkBefore || 0) + (item.created || 0)
            };
        }), {
            header: [
                ...(filters.regionClient ? ['Регион', 'Город'] : ['Регион']),
                `На начало периода ${subtitle(['inworkBefore'])}`,
                `Поступило ${subtitle(['created'])}`,
                `Закрыто ${subtitle(['done'])}`,
                `В работе ${subtitle(['inworkBefore', 'created'])}`
            ]
        });
        ws['!cols'] = [
            ...(filters.regionClient ? [{ wpx: 150 }, { wpx: 150 }] : [{ wpx: 150 }]),
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 }
        ];
		const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Отчет');

		XLSX.writeFile(wb, 'report.xlsx')
    }

    renderDownloadButton = (data, filters) => {
        return <Button
            shape='circle'
            icon={<FileExcelOutlined />}
            disabled={data.loading}
            onClick={() => this.downloadExcel(data, filters)} />;
    }

    renderData = filters => {
        const {
            disableFilters,
            additionalFilter,
            onChangeRegions
        } = this.props;

        return  <Query
            query={this.props.query}
            variables={{
                filter: omit(['year', 'period'], filters),
                ...(this.props.additionalVariables || {})
            }}
            fetchPolicy='network-only'>
                { data =>
                    <div>
                        { !disableFilters && (
                            <Toolbar>
                                <Col span={23}>
                                    <PeriodsReportFilter
                                        onChangeRegions={onChangeRegions}
                                        additionalFilter={additionalFilter}
                                        filter={{
                                            ...filters,
                                            minDate: filters.minDate || moment.utc({ day: 1, month: 0, year: moment().year() }),
                                            maxDate: filters.maxDate || moment.utc({ day: moment.utc({ month: 11, year: moment().year() }).daysInMonth(), month: 11, year: moment().year(), hour: 23, minute: 59, second: 59 })
                                        }}
                                    />
                                </Col>
                                <Col span={1}>
                                    { this.renderDownloadButton(data, filters) }
                                </Col>
                            </Toolbar>
                        )}
                        <Row>
                            <Col span={24}>
                                { this.renderReport(data, filters) }
                            </Col>
                        </Row>
                    </div>
                }
            </Query>;
    }

    render() {
        const {
            defaultFilters,
            location,
            user
        } = this.props;
        let filters = {
            ...{
                year: moment.utc().year(),
                period: 'month'
            },
            ...defaultFilters,
            ...getUrlFilter(location)
        };

        filters = getRole() === CLIENT_MANAGER && user.regionMacro ? {
            ...filters,
            regionMacro: user.regionMacro.id,
        } : filters;

        return this.renderData(filters);
    }
}

export default withRouter(withUserContext(PeriodsReport));
