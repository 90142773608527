import React, { Component } from 'react';
import { Spin, Button } from 'antd';
import { Query } from 'react-apollo';
import { path } from 'ramda';
import styled from 'styled-components';

import PasswordForm from '../forms/PasswordForm';
import { activate, checkActivation } from '../../queries/authorization';
import { setToken } from '../../utils/token';
import UserContext from '../../contexts/UserContext';

const InvalidBlock = styled.div`
    text-align: center;
    button {
        margin-top: 10px;
    }
`;

export default class Activation extends Component {
    onSuccess = ({ activate }, getUser) => {
        setToken(activate.accessToken);
        getUser().then(() => this.props.history.push('/'));
    }

    render() {
        const { token } = this.props.match.params;

        return (
            <UserContext.Consumer>
                { ({ getUser }) => (
                    <Query
                        query={checkActivation}
                        variables={{ token }}
                    >
                        {({ data, loading }) =>
                            loading ? (
                                <Spin />
                            ) : path(['checkActivation', 'id'], data) ? (
                                <PasswordForm
                                    mutation={activate}
                                    onSuccess={data => this.onSuccess(data, getUser)}
                                    token={token}
                                />
                            ) : (
                                <InvalidBlock>
                                    <div>Ссылка недействительна. Авторизуйтесь, если пароль уже был установлен</div>
                                    <Button
                                        type='primary'
                                        onClick={() => this.props.history.push('/')}>
                                        Войти
                                    </Button>
                                </InvalidBlock>
                            )
                        }
                    </Query>
                )}
            </UserContext.Consumer>
        );
    }
}
