import React, { Component } from 'react';
import { Spin, Button } from 'antd';
import { pathOr, findLastIndex, contains } from 'ramda';
import { Query, Mutation } from 'react-apollo';

import { getHireRequestLog } from '../../../queries/requests';
import {
    StyledTimeline,
    CreateEvent,
    EditEvent,
    ChangeEvent,
    CreateApplicantEvent,
    ChangeApplicantEvent,
    MeetingEvent,
    FirstWorkingDayEvent,
    ChangeVerifierEvent,
    RequestRefreshDeadlineEvent,
} from '../../user/TimeLineView';
import { cancelAction } from '../../../queries/applicants';
import { CONSULTANT, ADMIN } from '../../../constants/roles';
import { getRole } from '../../../utils/users';

export default class HireRequestLog extends Component {
    renderCancelButton = item => {
        return <Mutation
            mutation={cancelAction}
            variables={{ id: item.id }}
            refetchQueries={['hireRequestLog', 'hireRequestFilters', 'hireRequests']}>
            { (mutate, { loading }) =>
                <Button
                    pending={!!loading}
                    size='small'
                    onClick={mutate}>
                    Отменить
                </Button>
            }
        </Mutation>;
    }

    renderItem = (item, allowCancel) => {
        const props = {
            key: item.id,
            item,
            buttons: allowCancel ? this.renderCancelButton(item) : null
        };

        switch (item.type) {
            case 'HireRequest:Create':
                return <CreateEvent {...props} />;
            case 'HireRequest:Edit':
                return <EditEvent {...props} />;
            case 'HireRequest:ChangeStatus':
                return <ChangeEvent {...props} />;
            case 'Applicant:Create':
                return <CreateApplicantEvent {...props} />;
            case 'Applicant:ChangeStatus':
                return <ChangeApplicantEvent {...props} />;
            case 'Applicant:SetDateInterview':
                return <MeetingEvent {...props} />;
            case 'Applicant:SetFirstWorkingDay':
                return <FirstWorkingDayEvent {...props} />;
            case 'HireRequest:ChangeVerifier':
                return <ChangeVerifierEvent {...props} />;
            case 'HireRequest:RefreshDeadline':
                return <RequestRefreshDeadlineEvent {...props} />
            default:
                return <div key={item.id} />;
        }
    }

    renderLog = items => {
        const lastNotCanceled = findLastIndex(i => !i.canceled && i.cancelable, items);

        return <StyledTimeline style={{fontSize: '100%'}} lineStyle={{left: '50%', margin: 0, padding: 0}}>
            { items.map((item, index) => this.renderItem(item, lastNotCanceled === index && contains(getRole(), [ADMIN, CONSULTANT]))) }
        </StyledTimeline>;
    }

    render() {
        const { id } = this.props;

        return <Query
            query={getHireRequestLog}
            variables={{ id }}>
            { ({ data, loading }) =>
                loading ? <Spin /> : this.renderLog(pathOr([], ['hireRequestLog', 'items'], data))
            }
        </Query>;
    }
}
