import React, { Component } from 'react';

import UserContext from '../../contexts/UserContext';

const withUserContext = WrappedComponent =>
    class UserContextWrapper extends Component {
        render() {
            return <UserContext.Consumer>
                { ({ user }) => <WrappedComponent {...this.props} user={user} /> }
            </UserContext.Consumer>
        }
    }

export default withUserContext;
