import React, { Component } from 'react';
import { Radio, message } from 'antd';
import { pick, path, contains } from 'ramda';

import { APPLICANTS_ACTIONS, APPLICANTS_REFETCH_QUERIES, REJECT_COMMENT_REQUIRED } from '../../constants/applicants';
import ApplicantRejectForm from '../forms/ApplicantRejectForm';
import { rejectApplicant, changeApplicantStatus, setFirstWorkingDay, setApplicantInterviewFeedback, setDateInterview } from '../../queries/applicants';
import ApplicantStatusChangeForm from '../forms/ApplicantStatusChangeForm';
import { withApollo } from 'react-apollo';
import { getRole } from '../../utils/users';
import { CONSULTANT, CLIENT_MANAGER } from '../../constants/roles';
import { uniteDates } from '../../utils/date';
import Modal from './Modal';

class ApplicantChangeStatusModal extends Component {
    state = {
        selected: null
    };

    onSuccess = () => {
        message.success('Статус кандидата был успешно изменен');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить статус кандидата');

    onChange = e => this.setState({ selected: e.target.value });

    onSubmit = values => {
        const { client } = this.props;

        const changeStatus = () => (
            client.mutate({
                mutation: changeApplicantStatus,
                variables: pick(['id', 'status', 'comment'], values),
                refetchQueries: APPLICANTS_REFETCH_QUERIES
            })
                .then(() => {
                    const interviewDate = path(['interviewDate', 'date'], values);

                    if (values.date || interviewDate) {
                        if (interviewDate) {
                            const date = uniteDates(interviewDate, values.interviewDate.time);

                            if (date) {
                                return client.mutate({
                                    mutation: setDateInterview,
                                    variables: {
                                        id: values.id,
                                        date
                                    },
                                    refetchQueries: APPLICANTS_REFETCH_QUERIES
                                }).then(() => this.onSuccess())
                                .catch(() => this.onError());
                            }
                        }

                        if (values.date) {
                            return client.mutate({
                                mutation: setFirstWorkingDay,
                                variables: {
                                    id: values.id,
                                    date: values.date
                                },
                                refetchQueries: APPLICANTS_REFETCH_QUERIES
                            }).then(() => this.onSuccess())
                            .catch(() => this.onError());
                        }
                    } else {
                        this.onSuccess();
                    }
                })
                .catch(() => this.onError())
        );

        if (values.feedback) {
            return client.mutate({
                mutation: setApplicantInterviewFeedback,
                variables: pick(['id', 'feedback'], values),
            })
                .then(() => changeStatus())
                .catch(() => this.onError());
        } else {
            return changeStatus();
        }
    }

    onSubmitReject = values => {
        const { client } = this.props;

        const reject = () => (
            client.mutate({
                mutation: rejectApplicant,
                variables: pick(['id', 'status', 'reason', 'comment'], values),
                refetchQueries: APPLICANTS_REFETCH_QUERIES
            })
                .then(() => this.onSuccess())
                .catch(() => this.onError())
        );

        if (values.feedback) {
            return client.mutate({
                mutation: setApplicantInterviewFeedback,
                variables: pick(['id', 'feedback'], values),
            })
                .then(() => {
                    return reject();
                })
                .catch(() => this.onError());
        } else {
            return reject();
        }
    }

    render() {
        const { modal, params: { next, item } } = this.props;
        const { selected } = this.state;
        const requiredComment = selected === 'rejected' && contains(item.status, REJECT_COMMENT_REQUIRED);
        const isInterview = item.status === 'supervisorInterview';
        const showInterviewDateFields = selected === 'supervisorInterview' && contains(getRole(), [CONSULTANT, CLIENT_MANAGER]);
        const showInterviewFields = selected === 'securityApproval' && contains(getRole(), [CONSULTANT, CLIENT_MANAGER]);

        return <Modal
            {...modal}
            title='Изменить статус кандидата'
            footer={null}>
            <div>
                <Radio.Group
                    className='applicant-change-status-radiobuttons'
                    value={this.state.selected}
                    onChange={this.onChange}>
                    { next.map(key =>
                        <Radio.Button value={key} key={`applicant-status--${key}`}>
                            { APPLICANTS_ACTIONS[key] }
                        </Radio.Button>
                    )}
                    <Radio.Button value='rejected'>
                        Отклонить
                    </Radio.Button>
                </Radio.Group>
                { selected && (selected === 'rejected' ?
                    <ApplicantRejectForm
                        modal={modal}
                        onSubmit={this.onSubmitReject}
                        data={{ id: item.id }}
                        showInterviewFields={isInterview}
                        requiredComment={requiredComment}
                        type={item.status}
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        refetchQueries={APPLICANTS_REFETCH_QUERIES}
                        useValuesAsVariables /> :
                    <ApplicantStatusChangeForm
                        onSubmit={this.onSubmit}
                        data={{
                            id: item.id,
                            status: selected,
                            interviewDate: showInterviewDateFields ? ({
                                date: item.dateInterview,
                                time: item.dateInterview
                            }) : null
                        }}
                        showDateField={selected === 'attendanceAwaiting'}
                        showInterviewDateFields={showInterviewDateFields}
                        showInterviewFields={showInterviewFields}
                        applicant={item}
                        useValuesAsVariables />
                )}
            </div>
        </Modal>
    }
}

export default withApollo(ApplicantChangeStatusModal);
