import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Spin, Table } from 'antd';
import { path, pathOr } from 'ramda';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import XLSX from 'xlsx';

import withUserContext from '../../../hocs/withUserContext';
import { getInterviewFeedbackStat } from '../../../../queries/requests';
import { roundValue } from '../../../../utils/numbers';
import { getUrlFilter } from '../../../../utils/urlParams';
import InterviewFeedbackStatReportFilter from '../../../forms/filters/InterviewFeedbackStatReportFilter';
import { Toolbar } from '../../table/Table';
import { ErrorBlock, ErrorBlockIcon } from './Report';

class InterviewFeedbackStatReport extends Component {
    getColumns = () => {
        const { ancorStagesEnabled } = this.props.user;

        return [
            {
                title: 'Регион',
                dataIndex: ['regionClient', 'name'],
                key: 'regionClient'
            },
            {
                title: 'Позиция',
                dataIndex: ['position', 'name'],
                key: 'position'
            },
            {
                title: 'Количество проведенных интервью',
                dataIndex: 'count',
                key: 'count'
            },
            {
                title: 'Мотивация к работе',
                dataIndex: ['avg', 'motivation'],
                key: 'motivation',
                render: roundValue
            },
            ancorStagesEnabled ? ({
                title: 'Навыки продаж',
                dataIndex: ['avg', 'salesTechniques'],
                key: 'salesTechniques',
                render: roundValue
            }) : null,
            {
                title: 'Коммуникативные навыки',
                dataIndex: ['avg', 'communication'],
                key: 'communication',
                render: roundValue
            },
            {
                title: 'Ориентация на результат',
                dataIndex: ['avg', 'achievementOfResults'],
                key: 'achievementOfResults',
                render: roundValue
            },
            {
                title: 'Ответственность',
                dataIndex: ['avg', 'passionForChanges'],
                key: 'passionForChanges',
                render: roundValue
            },
        ].filter(Boolean);
    }

    downloadExcel = data => {
        const { ancorStagesEnabled } = this.props.user;

        const ws = XLSX.utils.json_to_sheet(pathOr([], ['interviewFeedbackStat'], data).map(item => ({
            'Регион': path(['regionClient', 'name'], item),
            'Позиция': path(['position', 'name'], item),
            'Количество проведенных интервью': item.count,
            'Мотивация к работе': pathOr(0, ['avg', 'motivation'], item),
            'Навыки продаж': ancorStagesEnabled ? pathOr(0, ['avg', 'salesTechniques'], item) : undefined,
            'Коммуникативные навыки': pathOr(0, ['avg', 'communication'], item),
            'Ориентация на результат': pathOr(0, ['avg', 'achievementOfResults'], item),
            'Ответственность': pathOr(0, ['avg', 'passionForChanges'], item),
        })), {
            header: [
                'Регион',
                'Позиция',
                'Количество проведенных интервью',
                'Мотивация к работе',
                ancorStagesEnabled ? 'Навыки продаж' : null,
                'Коммуникативные навыки',
                'Ориентация на результат',
                'Ответственность'
            ].filter(Boolean)
        });
        ws['!cols'] = [
            { wpx: 130 },
            { wpx: 130 },
            { wpx: 150 },
            { wpx: 130 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 130 }
        ];
		const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Отчет по результатам интервью');

		XLSX.writeFile(wb, 'interview-feedback-stat.xlsx')
    }

    render() {
        const { location } = this.props;
        const filters = getUrlFilter(location);

        return <Query
            query={getInterviewFeedbackStat}
            variables={{ filter: filters }}>
            { ({ data, loading, error }) =>
                <Fragment>
                    <Toolbar>
                        <Col span={23}>
                            <InterviewFeedbackStatReportFilter filter={filters} />
                        </Col>
                        <Col span={1}>
                            <Button
                                shape='circle'
                                icon={<FileExcelOutlined />}
                                disabled={loading}
                                onClick={() => this.downloadExcel(data)} />
                        </Col>
                    </Toolbar>
                    <Spin
                        spinning={loading}
                        delay={1000}>
                        <Table
                            columns={this.getColumns()}
                            dataSource={pathOr([], ['interviewFeedbackStat'], data).map((item, index) => ({ ...item, id: `interview-feedback-stat-${index}` }))}
                            pagination={false}
                            rowKey='id'
                            locale={{
                                emptyText: error ?
                                    <ErrorBlock>
                                        <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                                        <div>Не удалось отобразить данные</div>
                                    </ErrorBlock> :
                                    'Нет данных'
                            }} />
                    </Spin>
                </Fragment>
            }
        </Query>;
    }
}

export default withUserContext(InterviewFeedbackStatReport);
