import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import qs from 'qs';

import { positionExpiringsStat } from '../../../../queries/applicants';
import Table from '../../table/Table';
import DateFormat from '../../table/DateFormat';
import { REQUEST_STATUSES } from '../../../../constants/requests';
import { getToken } from '../../../../utils/token';
import { getUrlFilter } from '../../../../utils/urlParams';
import ApplicantsDeadlineFilter from '../../../forms/filters/ApplicantsDeadlineFilter';

export default class PositionExpiringsStat extends Component {
    getColumns = () => {
        return [
            {
                title: 'Позиция',
                dataIndex: ['position', 'name'],
                key: 'position'
            },
            {
                title: 'Регион',
                dataIndex: ['regionClient', 'name'],
                key: 'regionClient'
            },
            {
                title: 'Дата начала',
                dataIndex: 'startDate',
                key: 'startDate',
                render: date => <DateFormat date={date} utc />
            },
            {
                title: 'Дата закрытия',
                dataIndex: 'finishDate',
                key: 'finishDate',
                render: date => <DateFormat date={date} utc />
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                render: status => REQUEST_STATUSES[status].title
            },
            {
                title: 'Дата Т/У',
                dataIndex: 'workingDate',
                key: 'workingDate',
                render: date => <DateFormat date={date} utc />
            },
            {
                title: 'Срок закрытия позиции',
                dataIndex: 'days',
                key: 'days'
            },
            {
                title: 'Количество вакансий',
                dataIndex: 'requestsCount',
                key: 'requestsCount'
            }
        ];
    }

    getExportUrl = () => {
        const searchPath = qs.stringify({
            access_token: getToken(),
            filter: JSON.stringify(getUrlFilter(this.props.location))
        }, { addQueryPrefix: true });

        return `/api/hire-request/position-expirings-stat${searchPath}`;
    }

    renderToolbarButtons = () => {
        return <Tooltip title='Экспорт'>
            <Button
                icon={<FileExcelOutlined />}
                href={this.getExportUrl()}
                target='_blank'
                rel='noopener noreferrer'
                download />
        </Tooltip>;
    }

    render() {
        return <Table
            name='positionExpiringsStat'
            query={positionExpiringsStat}
            columns={this.getColumns()}
            toolbarButtons={this.renderToolbarButtons()}
            FilterForm={ApplicantsDeadlineFilter}
        />;
    }
}
