import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { pathOr, path } from 'ramda';

import { getPositions } from '../../../queries/positions';
import { getHireRequests, getHireRequest } from '../../../queries/requests';
import { getSupervisors } from '../../../queries/supervisors';
import { getRegionsAncor } from '../../../queries/regions';
import { getConsultants } from '../../../queries/consultants';
import { getClientManagers } from '../../../queries/clientManagers';
import { getRegionsCascaderData, getRegionsManagerCascaderData } from '../../../utils/requests';
import { getUrlFilter } from '../../../utils/urlParams';
import withFilterForm from '../../hocs/withFilterForm';
import withUserContext from '../../hocs/withUserContext';
import SearchInput from '../formComponents/SearchInput';
import Select, { LazyLoadSelect } from '../formComponents/Select';
import Cascader from '../formComponents/Cascader';
import HireRequestSelectSmall from '../../user/HireRequestSelectSmall';
import { getFullNameString } from '../../user/table/FullName';
import { REJECT_REASON, PDA_FILTER } from '../../../constants/applicants';
import Datepicker, { DatepickerGroup } from '../formComponents/Datepicker';

class ApplicantFilter extends Component {
    render() {
        const { defaultFilter = {}, match: { params: { type } }, user: { pdaEnabled } } = this.props;
        const values = getUrlFilter(this.props.location);
        const regionMacro = path(['regionMacro'], values);
        const regionClient = path(['regionClient'], values);
        const regionAncor = path(['regionAncor'], values);
        const position = path(['position'], values);
        const supervisor = path(['verifier'], values);
        const hireRequest = path(['hireRequest'], values);
        const rejected = type === 'rejected';

        return <Fragment>
            <Row>
                <Col span={6}>
                    <Field
                        name='name'
                        component={SearchInput}
                        placeholder='Поиск' />
                </Col>
                <Col span={6}>
                    <Query query={getRegionsAncor} variables={regionMacro ? { filter: { regionMacro } } : undefined}>
                        { ({ data }) =>
                            defaultFilter.regionMacro ? (
                                <Field
                                    name='regionClient'
                                    additionalNames={['regionAncor']}
                                    component={Cascader}
                                    placeholder='Регион/Город'
                                    disabled={!!supervisor || !!hireRequest}
                                    options={getRegionsManagerCascaderData(data)} />
                            ) : (
                                <Field
                                    name='regionMacro'
                                    additionalNames={['regionClient', 'regionAncor']}
                                    component={Cascader}
                                    placeholder='Макрорегион/Регион/Город'
                                    disabled={!!supervisor || !!hireRequest}
                                    options={getRegionsCascaderData(data)} />
                            )
                        }
                    </Query>
                </Col>
                <Col span={6}>
                    <Query
                        query={getPositions}
                        variables={{
                            filter: {
                                enabled: true
                            }
                        }}
                    >
                        { ({ data, loading }) =>
                            <Field
                                name='position'
                                component={Select}
                                options={pathOr([], ['positions', 'items'], data)}
                                loading={loading}
                                placeholder='Позиция'
                                namePath='name'
                                valuePath='id'
                                disabled={!!hireRequest}
                                allowClear />
                        }
                    </Query>
                </Col>
                <Col span={6}>
                    <Query
                        query={getConsultants}
                        variables={{
                            filter: {
                                enabled: true
                            }
                        }}
                    >
                        { ({ data, loading }) =>
                            <Field
                                name='responsible'
                                component={Select}
                                options={pathOr([], ['consultants', 'items'], data)}
                                loading={loading}
                                placeholder='Рекрутер'
                                renderName={getFullNameString}
                                valuePath='id'
                                allowClear />
                        }
                    </Query>
                </Col>
            </Row>
            <Row>
                <Col span={pdaEnabled || rejected ? 6 : 8}>
                    <Query
                        query={getClientManagers}
                        variables={{
                            filter: {
                                enabled: true,
                                regionMacro: defaultFilter.regionMacro || regionMacro,
                                regionClient: regionClient,
                            }
                        }}
                    >
                        { ({ data : dataManagers, loading: loadingManagers }) =>
                            <Query
                                query={getSupervisors}
                                variables={{
                                    filter: {
                                        enabled: true,
                                        regionMacro: defaultFilter.regionMacro || regionMacro,
                                        regionClient: regionClient,
                                        regionAncor: regionAncor,
                                    }
                                }}
                            >
                                { ({ data, loading }) =>
                                    <Field
                                        name='verifier'
                                        component={Select}
                                        options={[
                                            ...pathOr([], ['supervisors', 'items'], data),
                                            ...pathOr([], ['clientManagers', 'items'], dataManagers)
                                        ]}
                                        loading={loading || loadingManagers}
                                        placeholder='Ответственный'
                                        renderName={getFullNameString}
                                        valuePath='id'
                                        renderSmall={HireRequestSelectSmall}
                                        disabled={!!hireRequest}
                                        allowClear />
                                }
                            </Query>
                        }
                    </Query>
                </Col>
                <Col span={pdaEnabled || rejected ? 6 : 8}>
                    <Field
                        name='hireRequest'
                        component={LazyLoadSelect}
                        placeholder='Заявка'
                        optionsPath='hireRequests'
                        namePath='position.name'
                        valuePath='id'
                        renderSmall={HireRequestSelectSmall}
                        query={getHireRequests}
                        singleQuery={getHireRequest}
                        singleQueryPath='hireRequest'
                        variables={{
                            filter: {
                                initiator: supervisor,
                                regionMacro: defaultFilter.regionMacro || regionMacro,
                                regionClient: regionClient,
                                regionAncor: regionAncor,
                                position: position,
                            },
                            sorting: {
                                field: 'createdAt',
                                order: -1
                            }
                        }}
                        allowClear />
                </Col>
                { rejected &&
                    <Col span={6}>
                        <Field
                            name='reasonForRejection'
                            component={Select}
                            placeholder='Причина отклонения'
                            options={REJECT_REASON}
                            allowClear />
                    </Col>
                }
                { pdaEnabled && !rejected &&
                    <Col span={6}>
                        <Field
                            name='pdaFilter'
                            component={Select}
                            placeholder='Согласие ОПД'
                            options={PDA_FILTER}
                            allowClear />
                    </Col>
                }
                <Col span={pdaEnabled || rejected ? 6 : 8}>
                    <DatepickerGroup>
                        <Field
                            name='minCreatedAt'
                            component={Datepicker}
                            startOf={'day'}
                            placeholder='От' />
                        <Field
                            name='maxCreatedAt'
                            component={Datepicker}
                            endOf={'day'}
                            placeholder='До' />
                    </DatepickerGroup>
                </Col>
            </Row>
        </Fragment>
    }
}

export default withFilterForm(withUserContext(ApplicantFilter));
