import React, { Component } from 'react';
import { message } from 'antd';
import { path } from 'ramda';
import { withApollo } from 'react-apollo';

import HireRequestAdminForm from '../forms/HireRequestAdminForm';
import { editHireRequest, createHireRequest, sendHireRequestToClientApproval } from '../../queries/requests';
import { REQUEST_REFETCH_QUERIES } from '../../constants/requests';
import Modal from './Modal';

class HireRequestFormModal extends Component {
    onSuccess = () => {
        message.success('Заявка была успешно изменена');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить заявку');

    onCreateError = () => message.error('Не удалось создать заявку');

    onCreate = values => {
        const { client } = this.props;

        return client.mutate({
            mutation: createHireRequest,
            variables: { values }
        }).then(payload =>
            client.mutate({
                mutation: sendHireRequestToClientApproval,
                refetchQueries: REQUEST_REFETCH_QUERIES,
                variables: {
                    ...values,
                    id: path(['data', 'createHireRequest', 'id'], payload),
                }
            })
            .then(() => {
                message.success('Заявка была успешно создана');
                this.props.close();
            })
            .catch(() => this.onCreateError())
        ).catch(() => this.onCreateError());
    }

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params ? params.request.position.name : 'Создать заявку'}
            footer={null}>
            <HireRequestAdminForm
                onSuccess={this.onSuccess}
                onError={this.onError}
                mutation={params && editHireRequest}
                onSubmit={!params && this.onCreate}
                refetchQueries={REQUEST_REFETCH_QUERIES}
                request={params ? params.request : null}
                edit={!!params} />
        </Modal>;
    }
}

export default withApollo(HireRequestFormModal);
