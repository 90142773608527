import { pick, path } from 'ramda';
import jwt from 'jwt-decode';

import { getToken } from './token';

export const getUserEditInput = pick(['id', 'lastName', 'firstName', 'middleName', 'phone', 'enabled', 'username']);
export const getClientManagerEditInput = pick(['id', 'lastName', 'firstName', 'middleName', 'phone', 'enabled', 'regionMacro', 'username', 'allowHireRequestApproval', 'finalApprover', 'applicantStatusManagementEnabled']);
export const getSupervisorEditInput = pick(['id', 'lastName', 'firstName', 'middleName', 'regionClient', 'regionAncor', 'regionMacro', 'phone', 'enabled', 'username', 'applicantStatusManagementEnabled', 'hireRequestManagementEnabled']);

export const getRole = () => {
    const token = getToken();

    try {
        return token ? path(['roles', 0], jwt(token)) : token;
    } catch (e) {
        return;
    }
};
