import React, { Component } from 'react';
import { Cascader as AntdCascader } from 'antd';
import { path, drop, filter, find, propEq, fromPairs } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { FormSpy } from 'react-final-form';

class CascaderComponent extends Component {
    static defaultProps = {
        options: [],
        additionalNames: []
    };

    componentDidMount() {
        this.onChangeRegions(filter(v => !!v, [this.props.values.regionMacro, this.props.values.regionClient]));
    }

    componentDidUpdate(prev) {
        if (this.props.options.length && !prev.options.length) {
            this.onChangeRegions(filter(v => !!v, [this.props.values.regionMacro, this.props.values.regionClient]));
        }
    }

    onChangeRegions = values => {
        const { onChangeRegions, options } = this.props;
        const macroRegion = find(propEq('value', path([0], values)), options);

        macroRegion && onChangeRegions && onChangeRegions(fromPairs(values.map((val, index) => ([
            val,
            index === 0 ? path(['label'], macroRegion) : path(['label'], find(propEq('value', val), macroRegion.children))
        ]))));
    }

    onChange = values => {
        const { additionalNames, onChange, form } = this.props;

        onChange(path([0], values));
        this.onChangeRegions(values);

        form.batch(() => {
            const additionalValues = drop(1, values);

            additionalNames.forEach((name, index) =>
                form.change(name, additionalValues[index])
            )
        });
    }

    getValue = () => {
        const { input: { value }, additionalNames, values } = this.props;

        return filter(i => !!i, [value, ...additionalNames.map(name => values[name])])
    }

    filter(inputValue, path) {
        return path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    render() {
        const { placeholder, options, disabled } = this.props;

        return <AntdCascader
            value={this.getValue()}
            options={options}
            style={{ width: '100%' }}
            placeholder={placeholder}
            disabled={disabled}
            onChange={this.onChange}
            showSearch={{ filter: this.filter }}
            notFoundContent='Ничего не найдено'
            changeOnSelect />;
    }
}

const Cascader = props =>
    <FormSpy subscription={{ values: true }}>
        { ({ form, values }) => <CascaderComponent {...props} form={form} values={values} /> }
    </FormSpy>;

export default withFieldWrapper(Cascader);
