import React, { Component, Fragment } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { BatchHttpLink } from "apollo-link-batch-http";
import { ApolloLink } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import styled, { createGlobalStyle } from 'styled-components';
import { path } from 'ramda';
import RU from 'antd/lib/locale/ru_RU';

import SecureLayout from './layout/SecureLayout';
import { getToken, setToken } from '../utils/token';
import ScrollToTop from './ScrollToTop';
import { pushRollbarError } from '../utils/rollbar';
import introspectionQueryResultData from '../queries/schema.json';
import { ConfigProvider } from 'antd';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionQueryResultData.data,
});

const options = {
    uri: '/api/graphql',
    batchKey: opt => opt.operationName
};
const httpLink = ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createUploadLink(options),
    new BatchHttpLink(options)
);

const authLink = setContext((_, { headers }) => {
    const token = getToken();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
});

const errorLink = new onError(({ graphQLErrors, networkError, operation }) => {
    if (path([0, 'extensions', 'exception', 'message', 'statusCode'], graphQLErrors) === 401) {
        setToken(null);
    }

    if (path(['statusCode'], networkError) === 400) {
        pushRollbarError(networkError, { operation, graphQLErrors });
    }
});

export const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
        fragmentMatcher
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network'
        }
    }
});

const GlobalStyles = createGlobalStyle`
    html, body, #root,.guest-layout {
        height: 100%;
    }

    body {
        background: #E4E4F0;
    }

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
    .ant-layout-content{
        overflow: auto;
        height: 100%;
    }
    .ant-popover {
        z-index: 10300;
    }
`;

const ErrorWrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error, info) {
        pushRollbarError(error, info);
        this.setState({ error: true });
    }

    render() {
        return this.state.error ?
            <ErrorWrapper>Не удалось запустить приложение</ErrorWrapper> :
            <Fragment>
                <GlobalStyles />
                <ApolloProvider client={client}>
                    <ConfigProvider locale={RU}>
                        <BrowserRouter>
                            <ScrollToTop>
                                <SecureLayout />
                            </ScrollToTop>
                        </BrowserRouter>
                    </ConfigProvider>
                </ApolloProvider>
            </Fragment>;
    }
}
