import React, { Component } from 'react';
import { Button } from 'antd';

import Table from '../table/Table';
import { getPositions, editPosition } from '../../../queries/positions';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import SwitchEnabled from '../table/SwitchEnabled';
import { getPositionInput } from '../../../utils/positions';
import { LinkWrapper } from '../table/FullName';
import PositionFilter from '../../forms/filters/PositionFilter';
import { DEFAULT_POSITION_FILTER } from '../../../constants/lists';
import { PlusOutlined } from '@ant-design/icons';

export default class Positions extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                render: item =>
                    <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <LinkWrapper onClick={() => openModal(MODAL_TYPES.position, getPositionInput(item))}>
                                { item.name }
                            </LinkWrapper>
                        }
                    </ModalsContext.Consumer>
            },
            {
                title: 'Активна',
                key: 'enabled',
                width: 100,
                render: item =>
                    <SwitchEnabled
                        mutation={editPosition}
                        refetchQueries={['positions']}
                        item={getPositionInput(item)} />
            }
        ];
    }

    getToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Button
                    className='position-add-btn'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => openModal(MODAL_TYPES.position)}>
                    Добавить
                </Button>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        return <Table
            name='positions'
            query={getPositions}
            columns={this.getColumns()}
            toolbarButtons={this.getToolbarButtons()}
            FilterForm={PositionFilter}
            defaultFilter={DEFAULT_POSITION_FILTER} />;
    }
}
