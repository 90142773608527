import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { pathOr } from 'ramda';

import { getPositions } from '../../../queries/positions';
import { getRegionsAncor } from '../../../queries/regions';
import { getRegionsCascaderData } from '../../../utils/requests';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import Cascader from '../formComponents/Cascader';
import Datepicker, { DatepickerGroup } from '../formComponents/Datepicker';

class ApplicantsDeadlineFilter extends Component {
    render() {
        return <Fragment>
            <Row>
                <Col span={8}>
                    <Query query={getRegionsAncor}>
                        { ({ data }) =>
                            <Field
                                name='regionMacro'
                                additionalNames={['regionClient', 'regionAncor']}
                                component={Cascader}
                                placeholder='Макрорегион/Регион/Город'
                                options={getRegionsCascaderData(data)} />
                        }
                    </Query>
                </Col>
                <Col span={8}>
                    <Query
                        query={getPositions}
                        variables={{
                            filter: {
                                enabled: true
                            }
                        }}
                    >
                        { ({ data, loading }) =>
                            <Field
                                name='position'
                                component={Select}
                                options={pathOr([], ['positions', 'items'], data)}
                                loading={loading}
                                placeholder='Позиция'
                                namePath='name'
                                valuePath='id'
                                allowClear />
                        }
                    </Query>
                </Col>
                <Col span={8}>
                    <DatepickerGroup>
                        <Field
                            name='minDate'
                            component={Datepicker}
                            startOf={'day'}
                            placeholder='От' />
                        <Field
                            name='maxDate'
                            component={Datepicker}
                            endOf={'day'}
                            placeholder='До' />
                    </DatepickerGroup>
                </Col>
            </Row>
        </Fragment>
    }
}

export default withFilterForm(ApplicantsDeadlineFilter);
