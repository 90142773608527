import React, { Component } from 'react';
import { message } from 'antd';

import { createClientManager, editClientManager } from '../../queries/clientManagers';
import ClientManagerForm from '../forms/ClientManagerForm';
import Modal from './Modal';

export default class ClientManagerModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Менеджер был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} менеджера`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={`${ params ? 'Редактировать' : 'Добавить'} менеджера`}
            footer={null}>
            <ClientManagerForm
                mutation={params ? editClientManager : createClientManager}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['clientManagers']}
                user={params} />
        </Modal>
    }
}
