import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { pathOr } from 'ramda';

import { getRegionsClient, getRegionsMacro } from '../../../queries/regions';
import { getRegionsClientCascaderData } from '../../../utils/requests';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import SearchInput from '../formComponents/SearchInput';
import Cascader from '../formComponents/Cascader';

class UserFilter extends Component {
    render() {
        const { defaultFilter = {} } = this.props;

        const isSupervisors = this.props.match.path === '/users/supervisors';
        const isManagers = this.props.match.path === '/users/managers';
        const showAdditionalFilter = isSupervisors || (isManagers && !defaultFilter.regionMacro);

        return <Row>
            <Col span={12}>
                <Field
                    name='name'
                    component={SearchInput}
                    placeholder='Пользователь' />
            </Col>
            { showAdditionalFilter && (
                <Col span={12}>
                    { isSupervisors && (
                        !defaultFilter.regionMacro ? (
                            <Query query={getRegionsClient} variables={{ filter: { regionMacro: defaultFilter.regionMacro }}}>
                                { ({ data }) =>
                                    <Field
                                        name={'regionMacro'}
                                        additionalNames={['regionClient']}
                                        component={Cascader}
                                        placeholder='Макрорегион/Регион'
                                        options={getRegionsClientCascaderData(data)} />
                                }
                            </Query>
                        ) : (
                            <Query query={getRegionsClient}>
                                { ({ data }) =>
                                    <Field
                                        name='regionClient'
                                        component={Select}
                                        placeholder='Регион'
                                        namePath='name'
                                        valuePath='id'
                                        allowClear
                                        options={pathOr([], ['regionClient', 'items'], data)} />
                                }
                            </Query>
                        )
                    )}
                    { isManagers && (
                        <Query query={getRegionsMacro}>
                            { ({ data }) =>
                                <Field
                                    name='regionMacro'
                                    component={Select}
                                    placeholder='Макрорегион'
                                    namePath='name'
                                    valuePath='id'
                                    allowClear
                                    options={pathOr([], ['regionsMacro', 'items'], data)} />
                            }
                        </Query>
                    )}
                </Col>
            )}
        </Row>
    }
}

export default withFilterForm(UserFilter);
