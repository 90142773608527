export const BANNED = [
    { label: 'Попытка заблокирована', value: true },
    { label: 'Успешная попытка', value: false }
];

export const ENABLED = [
    { label: 'Пользователь активен', value: true },
    { label: 'Пользователь неактивен', value: false }
];

export const PASSWORD_CORRECT = [
    { label: 'Верный пароль', value: true },
    { label: 'Неверный пароль', value: false }
];

export const SUCCEED = [
    { label: 'Успешный вход', value: true },
    { label: 'Не удалось выполнить вход', value: false }
];
