import React, { Component, Fragment } from 'react';
import { Upload, Button, message } from 'antd';
import { Mutation, withApollo } from 'react-apollo';
import { PaperClipOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { uploadFile, getFile } from '../../../queries/files';

class FileUploader extends Component {
    state = {
        file: null
    };

    componentDidMount() {
        const { input: { value }, client } = this.props;

        if (value) {
            client.query({
                query: getFile,
                variables: {
                    id: value
                }
            }).then(({ data: { file }}) =>
                this.setState({ file: file.fileName })
            );
        }
    }

    beforeUpload = (file, mutate) => {
        mutate({
            variables: {
                file
            },
            context: {
                hasUpload: true,
            }
        }).then(({ data }) => {
            this.props.onChange(data.uploadFile.id);
            this.setState({ file: file.name });
            message.success('Файл успешно загружен');
        }).catch(() => message.error('Не удалось загрузить файл'));

        return false;
    }

    onRemove = () => {
        this.setState({ file: null });
        this.props.onChange(null);
    }

    render() {
        const { file } = this.state;

        return <Mutation mutation={uploadFile}>
            { (mutate, { loading }) =>
                <Fragment>
                    <Upload
                        beforeUpload={file => this.beforeUpload(file, mutate)}
                        fileList={[]}
                        disabled={loading || !!file}>
                        { !file &&
                            <Button
                                icon={<UploadOutlined />}
                                loading={loading}
                                disabled={loading}>
                                Загрузить
                            </Button>
                        }
                    </Upload>
                    { file &&
                        <div className='ant-upload-list ant-upload-list-text'>
                            <div className='ant-upload-list-item'>
                                <div className='ant-upload-list-item-info'>
                                    <PaperClipOutlined />
                                    <span className='ant-upload-list-item-name'>{ file }</span>
                                </div>
                                <CloseOutlined onClick={this.onRemove} />
                            </div>
                        </div>
                    }
                </Fragment>
            }
        </Mutation>;
    }
}

export default withFieldWrapper(withApollo(FileUploader));
