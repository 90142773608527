import React, { Component } from 'react';
import { message } from 'antd';
import { contains, pick } from 'ramda';
import { withApollo } from 'react-apollo';

import ApplicantRejectForm from '../forms/ApplicantRejectForm';
import { rejectApplicant, setApplicantInterviewFeedback } from '../../queries/applicants';
import { APPLICANT_REFETCH_QUERIES, REJECT_COMMENT_REQUIRED } from '../../constants/applicants';
import Modal from './Modal';

class ApplicantSupervisorRejectModal extends Component {
    onSubmit = values => {
        const { client } = this.props;
        const reject = () => (
            client.mutate({
                mutation: rejectApplicant,
                variables: pick(['id', 'status', 'reason', 'comment'], values),
                refetchQueries: APPLICANT_REFETCH_QUERIES
            })
                .then(() => this.onSuccess())
                .catch(() => this.onError())
        );

        if (values.feedback) {
            return client.mutate({
                mutation: setApplicantInterviewFeedback,
                variables: pick(['id', 'feedback'], values),
            })
                .then(() => reject())
                .catch(() => this.onError());
        } else {
            return reject();
        }
    }

    onSuccess = () => {
        message.success('Кандидат был успешно отклонен');
        this.props.close();
    }

    onError = () => message.error('Не удалось отклонить кандидата');

    render() {
        const { modal, params: { id, type }} = this.props;
        const requiredComment = contains(type, REJECT_COMMENT_REQUIRED);

        return <Modal
            {...modal}
            title='Отказать'
            footer={null}>
            <div className='supervisor-layout'>
                <ApplicantRejectForm
                    onSubmit={this.onSubmit}
                    data={{ id }}
                    type={type}
                    requiredComment={requiredComment}
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                    refetchQueries={APPLICANT_REFETCH_QUERIES}
                    showInterviewFields={type === 'supervisorInterview'}
                    useValuesAsVariables />
            </div>
        </Modal>
    }
}

export default withApollo(ApplicantSupervisorRejectModal);
