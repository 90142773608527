import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class Textarea extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value, name }, placeholder } = this.props;

        return (
            <div>
                <Input.TextArea
                    name={name}
                    value={value}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    autosize={{ minRows: 2, maxRows: 10 }} />
            </div>
        );
    }
}

export default withFieldWrapper(Textarea);
