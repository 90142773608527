import React, { Component } from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { toLower, find, propEq, path } from 'ramda';

import { REQUEST_STATUSES } from '../../../constants/requests';
import { EDUCATION, GENDERS } from '../../../constants/applicants';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import { formatMoney } from '../../../utils/requests';
import FullName from '../table/FullName';

export default class HireRequestView extends Component {
    renderField = (title, value) => {
        return <div className="form-group">
            <label>{ title }</label>
            <div className="box p10">
                { value }
            </div>
        </div>
    }

    render() {
        const { request, onEdit, editable } = this.props;
        const iconData = REQUEST_STATUSES[request.status];
        const jobConditions = request.jobConditions || {};

        return <div>
            <div className="item box">
                <div className='type-box' style={{ background: iconData.color }}>
                    <i className={`icon-${iconData.icon}`}></i>
                    {iconData.icon}
                    <span className="name">{ toLower(iconData.title) }</span>
                </div>
                <div className="title-box">
                    <div className="title">{ request.position.name }</div>
                    <div className="sub-title">
                        { request.deadline &&
                            <span className="date">
                                <Tooltip title='Срок'>
                                    <i className="icon-calendar"></i>{ moment.utc(request.deadline).format('DD.MM.YYYY') }
                                </Tooltip>
                            </span>
                        }
                        <span className="position">
                            <i className="icon-worker"></i>{ request.count }
                        </span>
                        <span className="date" style={{ marginLeft: 10 }}>
                            <Tooltip title='Дата создания заявки'>
                                <i className="icon-calendar"></i>{ moment.utc(request.createdAt).format('DD.MM.YYYY') }
                            </Tooltip>
                        </span>
                    </div>
                </div>
            </div>
            <h3>Основная информация</h3>
            { this.renderField('Обязанности исполнителя', request.requirements) }
            <Row gutter={10}>
                <Col xs={24} sm={12}>
                    { request.regionClient && this.renderField('Регион', request.regionClient.name) }
                </Col>
                <Col xs={24} sm={12}>
                    { request.regionAncor && this.renderField('Город', request.regionAncor.name) }
                </Col>
                <Col xs={24}>
                    { this.renderField('Ответственный', <FullName item={request.verifier} />) }
                </Col>
                { request.approver &&
                    <Col xs={24}>
                        { this.renderField('Ответственный за первое согласование', <FullName item={request.approver} />) }
                    </Col>
                }
                { request.finalApprover &&
                    <Col xs={24}>
                        { this.renderField('Ответственный за финальное согласование', <FullName item={request.finalApprover} />) }
                    </Col>
                }
            </Row>
            { request.address && this.renderField('Адрес', request.address) }
            <h3>Требования к кандидату</h3>
            <Row gutter={10}>
                <Col xs={24} sm={8}>
                    { request.gender && request.gender.length && this.renderField('Пол',
                        request.gender.map(gender => <span className='gender' key={gender}>
                            {path(['label'], find(propEq('value', gender), GENDERS))}
                        </span>)
                    )}
                </Col>
                <Col xs={24} sm={8}>
                    { request.minAge && this.renderField('Минимальный возраст', request.minAge) }
                </Col>
                <Col xs={24} sm={8}>
                    { request.maxAge && this.renderField('Маскимальный возраст', request.maxAge) }
                </Col>
            </Row>
            { request.qualification && this.renderField('Квалификация', request.qualification) }
            { request.qualificationAdditions && this.renderField('Дополнительные требования', request.qualificationAdditions) }
            { request.educationLevel && this.renderField('Образование', path(['label'], find(propEq('value', request.educationLevel), EDUCATION))) }
            <h3>Условия</h3>
            <Row gutter={10}>
                <Col xs={24} sm={12}>
                    { request.firstWorkingDate && this.renderField('Первый рабочий день', moment.utc(request.firstWorkingDate).format('DD.MM.YYYY')) }
                </Col>
                <Col xs={24} sm={12}>
                    { jobConditions.schedule && this.renderField('График работы', jobConditions.schedule) }
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={12}>
                    { jobConditions.salary && this.renderField('Заработная плата (гросс)', formatMoney(jobConditions.salary)) }
                </Col>
                <Col xs={24} sm={12}>
                    { jobConditions.bonus && this.renderField('Бонусы (гросс)', formatMoney(jobConditions.bonus)) }
                </Col>
            </Row>
            { jobConditions.carCompensation && this.renderField('Компенсация на дорогу', jobConditions.carCompensation) }
            { request.comment && this.renderField('Комментарий', request.comment) }
            <div className="controls">
                { editable && <Button type='primary' onClick={onEdit}>Редактировать</Button> }
                <ModalsContext.Consumer>
                    { ({ openModal }) =>
                        <Button
                            type='primary'
                            onClick={() => openModal(MODAL_TYPES.setVerifier, {
                                id: request.id,
                                regionClient: path(['regionClient', 'id'], request),
                                regionMacro: path(['regionMacro', 'id'], request),
                                verifierId: request.verifier.id,
                                className: 'supervisor-layout'
                            })}>
                            Назначить ответственного
                        </Button>
                    }
                </ModalsContext.Consumer>
            </div>
        </div>
    }
}
