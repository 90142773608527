
import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import 'antd/lib/date-picker/style/index.less';
import styled from 'styled-components';

import { needNativeInputs } from '../../../utils/mobile';
import withFieldWrapper from '../../hocs/withFieldWrapper';

class Datepicker extends Component {
    static defaultProps = {
        allowClear: true
    };

    onChange = value => {
        const date = value ? (
            ((this.props.startOf || this.props.endOf) ?
                this.props.startOf ? value.utc().startOf(this.props.startOf) : value.utc().endOf(this.props.endOf)
            : value.utc()).toISOString()
        ) : null;

        this.props.onChange(date);
    }

    onChangeNative = event => {
        const { disabledDate } = this.props;
        const value = moment.utc(event.target.value);
        const isValid = value.isValid() && (
            !disabledDate || !disabledDate(value)
        );

        this.onChange(isValid ? value : null);
    }

    render() {
        const { input: { value, name }, placeholder, disabledDate, allowClear } = this.props;

        return !needNativeInputs() ? (
            <DatePicker
                name={name}
                value={value ? moment.utc(value) : null}
                onChange={this.onChange}
                format='DD.MM.YYYY'
                placeholder={placeholder}
                locale={locale}
                disabledDate={disabledDate}
                allowClear={allowClear}
            />
        ) : (
            <input
                className='date-input'
                type='date'
                value={value ? moment.utc(value).format('YYYY-MM-DD') : ''}
                onChange={this.onChangeNative}
                placeholder={placeholder}
            />
        );
    }
}

export default withFieldWrapper(Datepicker);

export const DatepickerGroup = styled.div`
    position: relative;
    width: 100%;

    .ant-form-item {
        float: left;
        width: calc(50% - 4px);
        padding: 0 !important;
    }

    .ant-form-item .ant-form-item-children,
    .ant-form-item .ant-picker {
        width: 100%;
    }

    .ant-form-item .ant-picker {
        border-radius: 0 4px 4px 0;
        margin-left: -1px;
    }

    .ant-form-item .ant-picker:hover {
        z-index: 1;
    }

    .ant-form-item:first-child .ant-picker {
        border-radius: 4px 0 0 4px;
        margin-left: 0;
    }

    .ant-picker {
        width: 100%;
    }
`;
