import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { RecoveryLink } from './LoginForm';
import Input from './formComponents/Input';

const validationSchema = yup.object().shape({
    username: yup.string().email().required()
});

class RecoveryForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='username'
                component={Input}
                placeholder='Email'
                htmlType='email'
                customIcon='mail' />
            <div className='controls'>
                <SubmitButton>Восстановить пароль</SubmitButton>
                <RecoveryLink className='ant-btn btn-link-small' to='/login'>Войти</RecoveryLink>
            </div>
        </Fragment>;
    }
}

export default withFormWrapper(RecoveryForm, {
    validationSchema
});
