import React, { Component } from 'react';
import { Alert } from 'antd';

import Modal from './Modal';

export default class ErrorModal extends Component {
    render() {
        const { modal } = this.props;

        return <Modal
            {...modal}
            title='Ошибка'
            footer={null}>
            <Alert type='error' message='Не удалось открыть модал' />
        </Modal>
    }
}
