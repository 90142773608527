import React, { Component } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { Mutation } from 'react-apollo';

import Table from '../table/Table';
import { getRegionsMacro, removeRegionMacro } from '../../../queries/regions';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import RegionMacroFilter from '../../forms/filters/RegionMacroFilter';
import { getRole } from '../../../utils/users';
import { ADMIN } from '../../../constants/roles';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

export default class RegionsMacro extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name'
            },
            {
                key: 'controls',
                align: 'right',
                render: (item, _, index) =>
                    <Button.Group>
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <Button
                                    className={`regions-edit-btn-${index}`}
                                    icon={<EditOutlined />}
                                    onClick={() => openModal(MODAL_TYPES.regionMacro, item)} />
                            }
                        </ModalsContext.Consumer>
                        { getRole() === ADMIN &&
                            <Mutation
                                mutation={removeRegionMacro}
                                refetchQueries={['regionsMacro']}
                                variables={{ id: item.id }}
                                onCompleted={() => message.success('Макрорегион был успешно удален')}
                                onError={() => message.error('Не удалось удалить макрорегион')}>
                                { (mutate, { loading }) =>
                                    <Popconfirm
                                        title='Вы уверены, что хотите удалить макрорегион?'
                                        okText='Да'
                                        cancelText='Нет'
                                        onConfirm={mutate}>
                                        <Button
                                            className={`regions-delete-btn-${index}`}
                                            icon={<DeleteOutlined />}
                                            danger
                                            loading={loading} />
                                    </Popconfirm>
                                }
                            </Mutation>
                        }
                    </Button.Group>
            }
        ];
    }

    getToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Button
                    className='regions-add-btn'
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => openModal(MODAL_TYPES.regionMacro)}>
                    Добавить
                </Button>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        return <Table
            query={getRegionsMacro}
            name='regionsMacro'
            columns={this.getColumns()}
            FilterForm={RegionMacroFilter}
            toolbarButtons={this.getToolbarButtons()} />;
    }
}
