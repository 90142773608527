import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { omit } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import NumberInput from './formComponents/NumberInput';
import { DEADLINE_APPLICANTS } from '../../constants/applicants';
import { DEADLINE_REQUESTS } from '../../constants/requests';

class DeadlineForm extends Component {
    render() {
        return <Fragment>
            { ( this.props.data.type === 'applicant' ? DEADLINE_APPLICANTS : DEADLINE_REQUESTS).map(item =>
                <Field
                    key={`field-${item.type}`}
                    name={`periods.${item.type}`}
                    component={NumberInput}
                    min={0}
                    label={item.name} />
            )}
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(DeadlineForm, {
    mapPropsToValues: ({ data }) => omit(['__typename'], data)
});
