import React, { Component } from 'react';
import moment from 'moment';
import { message } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class DateTimePicker extends Component {
    onChange = event => {
        const { disabledDate } = this.props;
        const value = moment.utc(event.target.value);
        const isValid = value.isValid() && (
            !disabledDate || !disabledDate(value)
        );

        if (!isValid) {
            message.error('Дата невалидна');
        }

        this.props.onChange(isValid ? value.toDate() : null);
    }

    render() {
        const { input: { value, name }, placeholder } = this.props;

        return (
            <input
                name={name}
                className='date-input'
                type='datetime-local'
                value={value ? moment.utc(value).format('YYYY-MM-DDTHH:mm') : ''}
                onChange={this.onChange}
                placeholder={placeholder}
            />
        );
    }
}

export default withFieldWrapper(DateTimePicker);
