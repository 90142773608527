import React, { Component } from 'react';
import { Button, message, Tooltip, Popconfirm } from 'antd';
import { Mutation } from 'react-apollo';
import { contains } from 'ramda';

import { requestResetPassword } from '../../../queries/authorization';
import { getRole } from '../../../utils/users';
import { ADMIN, CONSULTANT } from '../../../constants/roles';
import { LockOutlined } from '@ant-design/icons';

export default class RecoveryButton extends Component {
    render() {
        const { username, className } = this.props;

        return contains(getRole(), [ADMIN, CONSULTANT]) ?
            <Mutation
                mutation={requestResetPassword}
                variables={{ username }}
                onCompleted={() => message.success('Ссылка на восстановление пароля была успешно отправлена')}
                onError={() => message.success('Не удалось отправить ссылку на восстановление пароля')}>
                { (mutation, { loading }) =>
                    <Popconfirm
                        title='Отправить ссылку на восстановление пароля?'
                        placement='left'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={mutation}>
                        <Tooltip
                            title='Отправить ссылку на восстановление пароля'
                            placement='left'>
                                <Button
                                    className={className}
                                    icon={<LockOutlined />}
                                    loading={loading} />
                        </Tooltip>
                    </Popconfirm>
                }
            </Mutation> : null;
    }
}
