import React, { Fragment } from 'react';
import { Row, Col, Spin, Button, Table } from 'antd';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import qs from 'qs';

import { getUrlFilter } from '../../../../utils/urlParams';
import ReportFilter from '../../../forms/filters/ReportFilter';
import { Toolbar } from '../../table/Table';
import withUserContext from '../../../hocs/withUserContext';
import { getRole } from '../../../../utils/users';
import { CLIENT_MANAGER } from '../../../../constants/roles';
import { getToken } from '../../../../utils/token';
import { find, omit, pathOr, propEq } from 'ramda';
import { getApplicantFilters } from '../../../../queries/applicants';

export const ErrorBlock = styled.div`
    color: #f5222d;
    text-align: center;
    margin: 15px 0;
`;

export const ErrorBlockIcon = styled.div`
    font-size: 36px;
`;

const ONE_DAY = 1000 * 60 * 60 * 24;
export const formatDayValue = () => ({
    format: miliseconds => {
        const value = miliseconds / ONE_DAY;
        return value ? `${value.toFixed(2)} дня` : '';
    },
    title: 'Дни',
    valueConfig: {
        min: 0,
        tickInterval: ONE_DAY,
    }
});

const columns = [
    {
        title: 'В работе на текущий момент',
        dataIndex: 'title',
    }
];

const getTableData = data => ([
    { title: `Согласование: ${pathOr(0, ['count'], find(propEq('status', 'supervisorApproval'), data))}` },
    { title: `Интервью: ${pathOr(0, ['count'], find(propEq('status', 'supervisorInterview'), data))}` },
    { title: `СБ: ${pathOr(0, ['count'], find(propEq('status', 'securityApproval'), data))}` }
]);

const ApplicantFiltersTable = ({ filters }) => {
    return <Query
        query={getApplicantFilters}
        variables={{ filter: {
            ...omit(['supervisor', 'minDate', 'maxDate'], filters),
            verifier: filters.supervisor
        }}}>
        { ({ data, loading }) =>
            <Table
                columns={columns}
                dataSource={getTableData(pathOr([], ['applicantFilters', 'statuses'], data))}
                pagination={false}
                size='small'
                rowKey='title'
                style={{ maxWidth: 220, marginTop: 15, marginBottom: 15 }}
                loading={loading}
                bordered />
        }
    </Query>
}

const Report = ({
    disableFilters,
    defaultFilters,
    additionalFilter,
    getKey,
    location,
    query,
    ChartComponent,
    getData,
    field,
    value,
    formatValue,
    user,
    chartProps,
    showTable,
    FilterComponent,
    excelButton
}) => {
    let filters = {
        ...defaultFilters,
        ...getUrlFilter(location)
    };

    filters = getRole() === CLIENT_MANAGER && user.regionMacro ? {
        ...filters,
        regionMacro: user.regionMacro.id
    } : filters;

    const searchPath = qs.stringify({
        access_token: getToken(),
        filter: JSON.stringify(filters)
    }, { addQueryPrefix: true });
    const exportUrl = `/api/user-activity/funnel${searchPath}`;
    const Filter = FilterComponent || ReportFilter

    return (
        <Query
            key={getKey && getKey(filters)}
            query={query}
            variables={{ filter: disableFilters ? undefined : filters }}
            fetchPolicy={'network-only'}
        >
            { ({ data, loading, error }) => {
                const funnel = getData(data, filters);

                return (
                    <Fragment>
                        { !disableFilters && (
                            <Toolbar>
                                <Col span={(excelButton || !FilterComponent) ? 23 : 24}>
                                    <Filter additionalFilter={additionalFilter} filter={filters} />
                                </Col>
                                { excelButton ? excelButton(data, loading) : (!FilterComponent &&
                                    <Col span={1}>
                                        <Button
                                            shape='circle'
                                            icon={<FileExcelOutlined />}
                                            disabled={loading}
                                            href={exportUrl}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            download />
                                    </Col>
                                )}
                            </Toolbar>
                        )}
                        <Row align='middle'>
                            <Col span={showTable ? 18 : 24}>
                                <Spin spinning={loading} delay={1000}>
                                    { error ?
                                        <ErrorBlock>
                                            <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                                            <div>Не удалось отобразить данные</div>
                                        </ErrorBlock> :
                                        <ChartComponent
                                            data={funnel}
                                            field={field}
                                            value={value}
                                            formatValue={formatValue && formatValue().format}
                                            valueTitle={formatValue && formatValue().title}
                                            valueConfig={formatValue && formatValue().valueConfig}
                                            {...chartProps}
                                        />
                                    }
                                </Spin>
                            </Col>
                            { showTable &&
                                <Col span={6}>
                                    <ApplicantFiltersTable filters={filters} />
                                </Col>
                            }
                        </Row>
                    </Fragment>
                );
            }}
        </Query>
    );
};

export default withRouter(withUserContext(Report));
