import React, { Component } from 'react';
import { Input, Spin, Badge, Tooltip } from 'antd';
import { path } from 'ramda';
import { Link, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import ClickOutside from 'react-click-outside';
import styled from 'styled-components';
import debounce from 'debounce';

import { APPLICANTS_STATUSES } from '../../../constants/applicants';
import { getSearch } from '../../../queries/search';
import { getUrlPath } from '../../../utils/urlParams';
import { SolutionOutlined, EnvironmentOutlined, UsergroupAddOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
    display: flex;
    .ant-input-search {
        width: auto;
        .ant-input-wrapper {
            position: relative;
        }
        input {
            width: ${({ opened }) => opened ? 600 : 200}px;
            padding-right: 28px;
            border: 1px solid #d9d9d9;
            border-radius: 2px !important;
        }
        input:focus {
            width: 600px;
        }
        .ant-input-group-addon {
            width: 0;
            position: absolute;
            top: 0;
            right: 30px;
            left: auto !important;
            z-index: 1;
            button {
                border: none;
                background: transparent;
            }
        }
    }
    position: relative;
`;

const Content = styled.div`
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 600px;
    position: absolute;
    z-index: 90;
    top: 52px;
    left: 0;

    .ant-badge-count {
        background: #1890ff;
    }
    .ant-tabs-bar {
        margin: 0;
    }
    .ant-tabs-tabpane{
        padding: 15px;
    }
    .ant-spin {
        height: auto;
        padding: 22px;
    }
`;

const CenterWrapper = styled.div`
    text-align: center;
`;
const Item = styled.div`
    border-bottom: 1px solid #e8e8e8;
    line-height: 25px;
    display: flex;

    h3 {
        margin: 0;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const ItemLink = styled(Link)`
    padding: 10px;
    color: inherit;
    width: 100%;
    border-radius: 4px;

    &:hover {
        color: inherit;
        background-color: #e6f7ff;
        cursor: pointer;
    }
`;

const RightField = styled.div`
    position: absolute;
    right: 10px;
    top: 30px;
    line-height: 10px;

    i {
        margin-right: 8px;
    }
`;

const ItemField = styled.span`
    margin-left: 8px;
`;

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipLine = styled.span`
    white-space: nowrap;

    i {
        margin: 0;
    }
`;

class FixedTooltip extends Component {
    container = React.createRef();

    getContainer = () => this.container.current;

    render() {
        return (
            <TooltipContainer ref={this.container}>
                <Tooltip {...this.props} getPopupContainer={this.getContainer}>
                    {this.props.children}
                </Tooltip>
            </TooltipContainer>
        );
    }
}

const getNameString = item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`;

class Search extends Component {
    state = {
        search: '',
        searchFilter: '',
        opened: false,
        focused: false
    };

    componentDidUpdate(prev) {
        if (this.props.location.pathname !== prev.location.pathname) {
            this.resetData();
        }
    }

    search = debounce(value => {
        if (value.length > 2) {
            this.setState({ searchFilter: value });
        } else {
            this.setState({ searchFilter: '' });
        }
    }, 400);

    onChange = e => {
        const { value } = e.target;

        this.setState({ search: value });
        this.search(value);
    }

    gotToResult = path => {
        this.props.history.push(path);

        if (path === this.props.location.pathname) {
            this.resetData();
        }
    }

    renderApplicants = (items = []) => {
        return items.length ?
            items.map(item => {
                const contact = item.contact || {};
                const request = path(['hireRequest'], item) || {};

                return (
                    <Item key={item.id}>
                        <ItemLink to={`/applicants/${item.status}/${getUrlPath({ hireRequest: request.id })}`}>
                            <div>
                                <strong dangerouslySetInnerHTML={{ __html: this.highlightSearch(getNameString(contact)) }} />
                            </div>
                            <ItemField><SolutionOutlined /> { path(['position', 'name'], request) }</ItemField>
                            <ItemField><EnvironmentOutlined /> { path(['regionAncor', 'name'], request) }</ItemField>
                            <ItemField><UsergroupAddOutlined /> { APPLICANTS_STATUSES[item.status] }</ItemField>
                        </ItemLink>
                        <RightField>
                            { contact.phone &&  (
                                <FixedTooltip placement='top' title={(
                                    <TooltipLine>
                                        <PhoneOutlined /> { contact.phone }
                                    </TooltipLine>
                                )}>
                                    <PhoneOutlined />
                                </FixedTooltip>
                            )}
                            { contact.email && (
                                <FixedTooltip placement='top' title={(
                                    <TooltipLine>
                                        <MailOutlined /> {contact.email}
                                    </TooltipLine>
                                )}>
                                    <MailOutlined />
                                </FixedTooltip>
                            )}
                        </RightField>
                    </Item>
                );
            }) :
            <CenterWrapper>Кандидаты не найдены</CenterWrapper>;
    }

    highlightSearch = (string = '') => {
        return string.replace(new RegExp(this.state.search, 'gi'), str => `<u>${str}</u>`);
    }

    renderTabWithCount = (title, items = []) => {
        return <span>{ title } <Badge count={items.length} style={{ backgroundColor: '#e71c32' }} /></span>;
    }

    renderSelector = () => {
        return this.state.searchFilter.length > 2 && this.state.opened ?
            <Content>
                <Query
                    query={getSearch}
                    variables={{
                        filter: {
                            name: this.state.searchFilter,
                        },
                    }}
                    notifyOnNetworkStatusChange
                >
                    { ({ data, loading }) => {
                        const empty = !path(['applicants', 'items', 'length'], data);

                        return loading
                            ? <CenterWrapper><Spin /></CenterWrapper>
                            : empty
                                ? <CenterWrapper>Ничего не найдено</CenterWrapper>
                                : this.renderApplicants(data.applicants.items);
                    }}
                </Query>
            </Content> :
            null;
    }

    onVisibleChange = opened => {
        this.setState({ opened });
    }

    resetData = () => {
        this.setState({ opened: false, search: '', searchFilter: '' });
    }

    close = (e) => {
        if (!this.state.focused && this.state.opened) {
            this.resetData();
        }
    }

    render() {
        return <Wrapper opened={this.state.opened}>
            <Input.Search
                onFocus={() => this.setState({ opened: true, focused: true })}
                onBlur={() => this.setState({ focused: false })}
                value={this.state.search}
                onChange={this.onChange}
                placeholder='Быстрый поиск' />
            <ClickOutside onClickOutside={this.close}>
                { this.renderSelector() }
            </ClickOutside>
        </Wrapper>;
    }
}

export default withRouter(Search);
