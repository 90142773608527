import React, { Component } from 'react';
import { compose, find, map, pathOr, propEq, filter, path } from 'ramda';

import { getApplicantFunnel } from '../../../../queries/applicants';
import { FUNNEL_APPLICANTS, FUNNEL_APPLICANTS_NOANCOR } from '../../../../constants/applicants';
import withUserContext from '../../../hocs/withUserContext';
import FunnelChart from './charts/FunnelChart';
import Report from './Report';

class ApplicantReport extends Component {
    render() {
        const { ancorStagesEnabled } = this.props.user;

        return (
            <Report
                query={getApplicantFunnel}
                ChartComponent={FunnelChart}
                getData={data => {
                    const funnel = pathOr([], ['applicantFunnel'], data);

                    return compose(
                        filter(item => !!item.count),
                        map(tab => ({
                            _id: tab.key,
                            count: tab.getData ? tab.getData(funnel) : path(['count'], find(propEq('_id', tab.key), funnel)),
                            field: tab.funnelTitle || tab.title
                        })),
                        filter(tab => (!tab.hideFunnel && find(propEq('_id', tab.key), funnel)) || tab.key === 'interviewed')
                    )(ancorStagesEnabled ? FUNNEL_APPLICANTS : FUNNEL_APPLICANTS_NOANCOR);
                }}
                value='count'
                showTable
            />
        );
    }
}

export default withUserContext(ApplicantReport)
