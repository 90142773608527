import React, { Component } from 'react';
import { message } from 'antd';

import UserForm from '../forms/UserForm';
import { createAdmin, editAdmin } from '../../queries/admins';
import Modal from './Modal';

export default class AdminModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Администратор был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} администратора`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={`${ params ? 'Редактировать' : 'Добавить'} администратора`}
            footer={null}>
            <UserForm
                mutation={params ? editAdmin : createAdmin}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['admins']}
                user={params} />
        </Modal>
    }
}
