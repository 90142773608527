import React, { Component } from 'react';
import { message } from 'antd';
import { pick } from 'ramda';
import { withApollo } from 'react-apollo';

import { changeApplicantStatus, setDateInterview, setApplicantInterviewFeedback } from '../../queries/applicants';
import ApplicantSupervisorStatusChangeForm from '../forms/ApplicantSupervisorStatusChangeForm';
import { APPLICANTS_ACTIONS, APPLICANT_REFETCH_QUERIES } from '../../constants/applicants';
import Modal from './Modal';

class ApplicantSupervisorChangeStatusModal extends Component {
    onSubmit = values => {
        const { client } = this.props;
        const changeStatus = () => (
            client.mutate({
                mutation: changeApplicantStatus,
                variables: pick(['id', 'status', 'comment'], values),
                refetchQueries: values.date ? [] : APPLICANT_REFETCH_QUERIES
            })
                .then(() => {
                    if (values.date) {
                        return client.mutate({
                            mutation: setDateInterview,
                            variables: pick(['id', 'date'], values),
                            refetchQueries: APPLICANT_REFETCH_QUERIES,
                        })
                            .then(() => this.onSuccess())
                            .catch(() => this.onError());
                    } else {
                        this.onSuccess();
                    }
                })
                .catch(() => this.onError())
        );

        if (values.feedback) {
            return client.mutate({
                mutation: setApplicantInterviewFeedback,
                variables: pick(['id', 'feedback'], values),
            })
                .then(() => changeStatus())
                .catch(() => this.onError());
        } else {
            return changeStatus();
        }
    }

    onSuccess = () => {
        message.success('Статус был успешно изменен');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить статус');

    render() {
        const { modal, params: { title, id, status }} = this.props;

        return <Modal
            {...modal}
            title={title  || APPLICANTS_ACTIONS[status]}
            footer={null}>
            <div className='supervisor-layout'>
                <ApplicantSupervisorStatusChangeForm
                    modal={modal}
                    onSubmit={this.onSubmit}
                    data={{ id, status }}
                    showInterviewFields={status === 'securityApproval'}
                    showDateFields={status === 'supervisorInterview'} />
            </div>
        </Modal>
    }
}

export default withApollo(ApplicantSupervisorChangeStatusModal);
