import React, { PureComponent } from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';

export default class AreaChartComponent extends PureComponent {
    render() {
        return (
            <div style={{maxWidth: '800px', margin: '50px auto'}}>
                <AreaChart
                    width={800}
                    height={400}
                    data={this.props.data}
                    margin={{
                        top: 20, right: 20, bottom: 20, left: 20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="field" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="registrationCount" name="Новых кандидатов" stroke="#ff7300" fill="#ff7300" />
                    <Area type="monotone" dataKey="hiredCount" name="Трудоустроенных" stroke="#5584d7" fill="#5584d7" />
                </AreaChart>
            </div>
        );
    }
}
