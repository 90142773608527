import React from 'react';
import { Switch } from 'react-router-dom';

import Activation from '../guest/Activation';
import Route from '../Route';

const withCommonLayout = Component => props => (
    <div className='guest-layout'>
        <div className="login">
            <div className="logo-red"></div>
            <Component {...props} />
        </div>
    </div>
);

const CommonRoutes = ({ children }) => (
    <Switch>
        <Route path='/activation/:token' component={withCommonLayout(Activation)} />
        {children}
    </Switch>
);

export default CommonRoutes;
