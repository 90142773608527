import React from 'react';
import {
    HomeOutlined,
    SolutionOutlined,
    UsergroupAddOutlined,
    CalendarOutlined,
    PieChartOutlined,
    DatabaseOutlined,
    TeamOutlined,
    EnvironmentOutlined,
    LoginOutlined,
    SettingOutlined
} from '@ant-design/icons';

import { ADMIN, CONSULTANT, CLIENT_MANAGER } from './roles';
import { getRole } from '../utils/users';

export const ADMIN_MENU_ITEMS = () => [
    { title: 'Главная', key: '/', icon: <HomeOutlined /> },
    { title: 'Заявки', route: '/requests/:type', key: '/requests/last', icon: <SolutionOutlined /> },
    { title: 'Кандидаты', route: '/applicants/:type', key: '/applicants/last', icon: <UsergroupAddOutlined /> },
    { title: 'Просрочки', key: '/deadlines', icon: <CalendarOutlined />, roles: [CONSULTANT, ADMIN] },
    { title: 'Отчеты', key: '/reports', icon: <PieChartOutlined />, roles: [ADMIN, CONSULTANT, CLIENT_MANAGER] },
    { title: 'Позиции', key: '/positions', icon: <DatabaseOutlined />, roles: [ADMIN, CONSULTANT] },
    { title: 'Пользователи', route: '/users/:type', key: getRole() === CLIENT_MANAGER ? '/users/supervisors' : '/users/admins', icon: <TeamOutlined /> },
    { title: 'Регионы', key: '/regions/macro', icon: <EnvironmentOutlined />, route: '/regions/:type', roles: [ADMIN, CONSULTANT] },
    { title: 'Авторизация', key: '/auth', icon: <LoginOutlined />, roles: [ADMIN] },
    { title: 'Настройки', key: '/settings/sla', route: '/settings/:type', icon: <SettingOutlined />, roles: [ADMIN] }
];
