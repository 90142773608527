import React from 'react';
import styled from 'styled-components';

const Field = styled.div`
    margin-bottom: 10px;
`;

const DetailField = ({ title, value }) =>
    value ?
        <Field>
            <strong>{ title }:</strong> { value }
        </Field> : null;

export default DetailField;
