import React, { Component } from 'react';

import RegistrationForm from '../forms/RegistrationForm';
import { register } from '../../queries/authorization';
import { setToken } from '../../utils/token';
import UserContext from '../../contexts/UserContext';

export default class Registration extends Component {
    onSuccess = ({ register }, getUser) => {
        setToken(register.accessToken);
        getUser().then(() => this.props.history.push('/request'));
    }

    render() {
        return <UserContext.Consumer>
            { ({ getUser }) =>
                <RegistrationForm
                    mutation={register}
                    onSuccess={data => this.onSuccess(data, getUser)} />
            }
        </UserContext.Consumer>;
    }
}
