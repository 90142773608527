import React from 'react';

import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import FullName from './FullName';

const UserLink = ({ item }) => (
    <ModalsContext.Consumer>
        { ({ openModal }) =>
            <FullName
                item={item}
                onClick={() => openModal(MODAL_TYPES.userView, item)}
            />
        }
    </ModalsContext.Consumer>
);

export default UserLink;
