import React, { Component, Fragment } from 'react';
import { assocPath, remove, append } from 'ramda';
import { withRouter } from 'react-router-dom';

import ModalsContext from '../../contexts/ModalsContext';
import ModalParamsContext from '../../contexts/ModalParamsContext';
import { MODAL_TYPES } from '../../constants/modals';
import { pushRollbarError } from '../../utils/rollbar';

import ErrorModal from './ErrorModal';
import AdminModal from './AdminModal';
import SupervisorModal from './SupervisorModal';
import ClientManagerModal from './ClientManagerModal';
import ConsultantModal from './ConsultantModal';
import SendHireRequestModal from './SendHireRequestModal';
import PositionModal from './PositionModal';
import HireRequestStatusChangeModal from './HireRequestStatusChangeModal';
import HireRequestViewModal from './HireRequestViewModal';
import ApplicantFormModal from './ApplicantFormModal';
import ApplicantChangeStatusModal from './ApplicantChangeStatusModal';
import ApplicantSupervisorChangeStatusModal from './ApplicantSupervisorChangeStatusModal';
import ApplicantSupervisorRejectModal from './ApplicantSupervisorRejectModal';
import FirstWorkingDayModal from './FirstWorkingDayModal';
import ApplicantDetailModal from './ApplicantDetailModal';
import HireRequestFormModal from './HireRequestFormModal';
import UserViewModal from './UserViewModal';
import SetVerifierModal from './SetVerifierModal';
import ApplicantInterviewDateModal from './ApplicantInterviewDateModal';
import RegionClientModal from './RegionClientModal';
import RegionAncorModal from './RegionAncorModal';
import RegionMacroModal from './RegionMacroModal';
import DeadlineModal from './DeadlineModal';
import LogsModal from './LogsModal';
import SupervisorCommentModal from './SupervisorCommentModal';
import AncorTechModal from './AncorTechModal';
import ApplicantCopyModal from './ApplicantCopyModal';
import ApplicantOpdModal from './ApplicantOpdModal';

const modalComponents = {
    [MODAL_TYPES.admin]: AdminModal,
    [MODAL_TYPES.supervisor]: SupervisorModal,
    [MODAL_TYPES.clientManager]: ClientManagerModal,
    [MODAL_TYPES.consultant]: ConsultantModal,
    [MODAL_TYPES.sendRequest]: SendHireRequestModal,
    [MODAL_TYPES.hireStatusChange]: HireRequestStatusChangeModal,
    [MODAL_TYPES.position]: PositionModal,
    [MODAL_TYPES.requestView]: HireRequestViewModal,
    [MODAL_TYPES.applicantForm]: ApplicantFormModal,
    [MODAL_TYPES.applicantChangeStatus]: ApplicantChangeStatusModal,
    [MODAL_TYPES.applicantSupervisorChangeStatus]: ApplicantSupervisorChangeStatusModal,
    [MODAL_TYPES.applicantSupervisorReject]: ApplicantSupervisorRejectModal,
    [MODAL_TYPES.firstWorkingDay]: FirstWorkingDayModal,
    [MODAL_TYPES.applicantDetail]: ApplicantDetailModal,
    [MODAL_TYPES.hireRequestForm]: HireRequestFormModal,
    [MODAL_TYPES.userView]: UserViewModal,
    [MODAL_TYPES.setVerifier]: SetVerifierModal,
    [MODAL_TYPES.applicantInterviewDate]: ApplicantInterviewDateModal,
    [MODAL_TYPES.regionClient]: RegionClientModal,
    [MODAL_TYPES.regionAncor]: RegionAncorModal,
    [MODAL_TYPES.regionMacro]: RegionMacroModal,
    [MODAL_TYPES.deadline]: DeadlineModal,
    [MODAL_TYPES.logs]: LogsModal,
    [MODAL_TYPES.supervisorComment]: SupervisorCommentModal,
    [MODAL_TYPES.ancorTech]: AncorTechModal,
    [MODAL_TYPES.applicantCopy]: ApplicantCopyModal,
    [MODAL_TYPES.applicantOpd]: ApplicantOpdModal,
};

class Modals extends Component {
    state = {
        modals: []
    };

    componentDidCatch(error) {
        this.setState({
            modals: [],
        });

        pushRollbarError(error);
    }

    openModal = (type, params) => {
        this.setState(prev => ({
            modals: append({
                type,
                params,
                visible: true
            }, prev.modals)
        }));
    }

    showModal = index => {
        this.setState(prev => prev.modals[index] ? ({
            modals: assocPath([index, 'visible'], true, prev.modals)
        }) : prev);
    }

    closeModal = index => {
        this.setState(prev => prev.modals[index] ? ({
            modals: assocPath([index, 'visible'], false, prev.modals)
        }) : prev);
    }

    removeModal = index => {
        this.setState(prev => prev.modals[index] ? ({
            modals: remove(index, 1, prev.modals)
        }) : prev);
    }

    getModalActions = type => {
        return {
            close: () => this.closeModal(type),
            remove: () => this.removeModal(type),
            show: () => this.showModal(type)
        };
    }

    getModalComponent = ({ type, visible, params }, index) => {
        const ModalComponent = modalComponents[type] || ErrorModal;
        const actions = this.getModalActions(index);

        const modalProps = {
            visible,
            width: 768,
            onCancel: actions.close,
            afterClose: actions.remove
        };

        return (
            <ModalComponent
                {...actions}
                index={index}
                modal={modalProps}
                params={params}
            />
        );
    }

    render() {
        const { modals } = this.state;

        return <Fragment>
            <ModalsContext.Provider value={{ openModal: this.openModal }}>
                { modals.map((modal, index) => (
                    <ModalParamsContext.Provider key={index} value={modal}>
                        {this.getModalComponent(modal, index)}
                    </ModalParamsContext.Provider>
                ))}
                { this.props.children }
            </ModalsContext.Provider>
        </Fragment>;
    }
}

export default withRouter(Modals);
