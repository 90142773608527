import React, { Component } from 'react';
import { message } from 'antd';

import FirstWorkingDayForm from '../forms/FirstWorkingDayForm';
import { setFirstWorkingDay } from '../../queries/applicants';
import { APPLICANTS_REFETCH_QUERIES } from '../../constants/applicants';
import Modal from './Modal';

export default class FirstWorkingDayModal extends Component {
    onSuccess = () => {
        message.success('Дата трудоустройства была успешно изменена');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить дату трудоустройства');

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Дата трудоустройства'
            footer={null}>
            <FirstWorkingDayForm
                mutation={setFirstWorkingDay}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={APPLICANTS_REFETCH_QUERIES}
                item={params}
                useValuesAsVariables />
        </Modal>;
    }
}
