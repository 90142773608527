import React, { Component } from 'react';
import { Switch, Link, withRouter, matchPath } from 'react-router-dom';
import cx from 'classnames';
import { contains, any, propEq } from 'ramda';

import NoMatch from '../NoMatch';
import HireRequest from '../../user/supervisors/HireRequest';
import HireRequests from '../../user/supervisors/HireRequests';
import Users from '../../user/supervisors/Users';
import Profile from '../../user/supervisors/Profile';
import CreateHireRequest from '../../user/supervisors/CreateHireRequest';
import User from '../../user/supervisors/User';
import UserInterviewDate from '../../user/supervisors/UserInterviewDate';
import Route from '../../Route';

const mainRoutes = [
    { route: '/', title: 'Люди', icon: 'users', routes: ['/user'] },
    { route: '/requests', title: 'Заявки', icon: 'project', routes: ['/request'] },
    { route: '/profile', title: 'Профиль', icon: 'profile' }
];

const notFormRoutes = [
    '/profile',
    '/requests',
    '/',
];

class SupervisorLayout extends Component {
    render() {
        const { location: { pathname }, history: { goBack }} = this.props;
        const isFormPage = !contains(pathname, notFormRoutes);

        return <div className={cx('supervisor-layout user-layout', { 'form-layout': isFormPage})}>
            <div className="head">
                <div className="max-width">
                    { !any(propEq('route', pathname), mainRoutes) &&
                        <span className="back" onClick={() => goBack()}>
                            <i className="icon-arrow-left"></i>
                        </span>
                    }
                    <Link to='/' className="logo" />
                    <div className="footer">
                        <div className="max-width">
                            <ul className="menu">
                                { mainRoutes.map(item =>
                                    <li className={cx({
                                        active: item.route === pathname || any(path => matchPath(pathname, { path }), item.routes || [])
                                    })}
                                    key={item.route}>
                                        <Link to={item.route}>
                                            <i className={`icon-${item.icon}`}></i>
                                            { item.title }
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content max-width">
                <Switch>
                    <Route path='/' exact component={Users} />
                    <Route path='/user/interview/:id' component={UserInterviewDate} />
                    <Route path='/user/:id' component={User} />
                    <Route path='/request' exact component={CreateHireRequest} />
                    <Route path='/request/:id' component={HireRequest} />
                    <Route path='/requests' component={HireRequests} />
                    <Route path='/profile' component={Profile} />
                    <Route component={NoMatch} />
                </Switch>
            </div>
        </div>
    }
}

export default withRouter(SupervisorLayout);
