import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { Form as FormComponent, Alert } from 'antd';
import { assocPath, path, pathOr, forEachObjIndexed, toLower } from 'ramda';
import styled from 'styled-components';
import { withApollo, Mutation } from 'react-apollo';

import FormLoadingContext from '../../contexts/FormLoadingContext';
import ModalParamsContext from '../../contexts/ModalParamsContext';
import ERRORS, { DEFAULT_ERROR, ERRORS_LABELS } from '../../constants/errors';
import RequiredFieldsContext from '../../contexts/RequiredFieldsContext';
import { getRequiredFields } from '../../utils/yup';
import FormServerErrorsContext from '../../contexts/FormServerErrorsContext';
import InitialValuesContext from '../../contexts/InitialValuesContext';

const ErrorAlert = styled(Alert)`
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
`;

const getServerError = (error, values) => {
    const fields = path(['graphQLErrors', 0, 'errors'], error);
    const fieldErrors = {};

    if (fields) {
        forEachObjIndexed((errors, field) => {
            errors.forEach(error => {
                const template = ERRORS[toLower(error.messageTemplate)];

                let message = error.message;

                if (template) {
                    message = template
                        .replace('$property', ERRORS_LABELS[field] || field)
                        .replace('$value', values[field]);

                    if (error.constraints) {
                        error.constraints.forEach((value, index) => {
                            message = message.replace(`$constraint${index + 1}`, value);
                        });
                    }
                }

                fieldErrors[field] = message;
            });
        }, fields);
    }

    return fieldErrors;
}

const withFormWrapper = (WrappedComponent, formOptions = {}) => {
    class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props),
                loading: false
            };
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        onSubmit = (values, mutate) => {
            const { useValuesAsVariables } = this.props;
            const mapBeforeSubmit = formOptions.mapBeforeSubmit;
            const { id, ...mappedValues } = mapBeforeSubmit ? mapBeforeSubmit(values, this.props) : values;

            mutate({
                variables: useValuesAsVariables ? { id, ...mappedValues } : { values: mappedValues, id }
            }).catch(() => {});
        }

        onCustomSubmit = values => {
            const { onSubmit } = this.props;
            const { mapBeforeSubmit } = formOptions;
            const mappedValues = mapBeforeSubmit ? mapBeforeSubmit(values, this.props) : values;

            this.setState({ loading: true });

            onSubmit(mappedValues)
                .then(() => this.setState({ loading: false }))
                .catch(() => this.setState({ loading: false }));
        }

        validate = values => {
            const schema = this.getValidationSchema();

            if (!schema) {
                return {};
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => {
                    const path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p)) ? p : Number(p));
                    return assocPath(path, error.message, errors);
                }, {});
            }
        }

        getValidationSchema = () => {
            const schema = formOptions.validationSchema;

            return schema ? (typeof schema === 'function' ? schema(this.props) : schema) : null;
        }

        renderError = e => {
            const error = pathOr({}, ['graphQLErrors', 0, 'message'], e);
            const message = error.statusCode ? (error.statusCode < 500 ? (ERRORS[error.message] || this.props.defaultError) : DEFAULT_ERROR) : DEFAULT_ERROR;

            return <ErrorAlert message={message} type='error' />;
        }

        renderForm = () => {
            const { mutation, onSuccess, onError, refetchQueries, onSubmit, formLoading } = this.props;

            return (
                <ModalParamsContext.Consumer>
                    { (modal) => {
                        const isModalClosing = !pathOr(true, ['visible'], modal);

                        return onSubmit ?
                            <Form
                                onSubmit={this.onCustomSubmit}
                                validate={this.validate}
                                subscription={{ submitting: true, invalid: true, submitFailed: true, error: true }}
                                initialValues={this.state.initialValues}
                                render={props =>
                                    <FormComponent onFinish={props.handleSubmit} noValidate>
                                        <FormLoadingContext.Provider value={this.state.loading || isModalClosing}>
                                            <WrappedComponent {...this.props} {...props} />
                                        </FormLoadingContext.Provider>
                                    </FormComponent>
                                }
                            /> :
                            <Mutation
                                mutation={mutation}
                                onCompleted={onSuccess}
                                onError={onError}
                                refetchQueries={refetchQueries}>
                                { (mutate, { loading, error, ...props }) =>
                                    <Form
                                        onSubmit={data => this.onSubmit(data, mutate)}
                                        validate={this.validate}
                                        subscription={{ submitting: true, invalid: true, submitFailed: true, error: true }}
                                        initialValues={this.state.initialValues}
                                        render={props =>
                                            <FormServerErrorsContext.Provider value={getServerError(error, props.form.getState().values)}>
                                                <FormComponent onFinish={props.handleSubmit} noValidate>
                                                    { error && this.renderError(error) }
                                                    <FormLoadingContext.Provider value={loading || isModalClosing || formLoading}>
                                                        <WrappedComponent {...this.props} {...props} />
                                                    </FormLoadingContext.Provider>
                                                </FormComponent>
                                            </FormServerErrorsContext.Provider>
                                        }
                                    />
                                }
                        </Mutation>
                    }}
                </ModalParamsContext.Consumer>
            );
        }

        render() {
            return <RequiredFieldsContext.Provider value={getRequiredFields(this.getValidationSchema())}>
                <InitialValuesContext.Provider value={this.state.initialValues}>
                    { this.renderForm() }
                </InitialValuesContext.Provider>
            </RequiredFieldsContext.Provider>;
        }
    }

    return withApollo(FormWrapper);
}

export default withFormWrapper;
