import React, { Component } from 'react';
import { Switch } from 'react-router-dom';

import Login from '../../guest/Login';
import Recovery from '../../guest/Recovery';
import Reset from '../../guest/Reset';
import Main from '../../guest/Main';
import Registration from '../../guest/Registration';
import Route from '../../Route';

export default class GuestLayout extends Component {
    getRoutes = () => {
        return <Switch>
            <Route path='/main' component={Main} />
            <Route path='/registration' component={Registration} />
            <Route path='/recovery' component={Recovery} />
            <Route path='/password-reset/:token' component={Reset} />
            <Route path='/' component={Login} />
        </Switch>;
    }

    render() {
        return <div className='guest-layout'>
            <div className="login">
                <div className="logo-red"></div>
                { this.getRoutes() }
            </div>
        </div>;
    }
}
