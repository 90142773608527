import React, { Component } from 'react';
import { message } from 'antd';

import UserInterviewDateForm from '../forms/UserInterviewDateForm';
import { setDateInterview } from '../../queries/applicants';
import { APPLICANTS_REFETCH_QUERIES } from '../../constants/applicants';
import Modal from './Modal';

export default class ApplicantInterviewDateModal extends Component {
    onSuccess = () => {
        const { close, params } = this.props;

        message.success(`Дата интервью успешно ${params.dateInterview ? 'изменена' : 'назначена'}`);
        close();
    }

    onError = () => {
        const { params } = this.props;

        message.error(`Не удалось ${params.dateInterview ? 'изменить' : 'назначить'} дату интервью`);
    }

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params.dateInterview ? 'Изменить дату интервью' : 'Назначить дату интервью'}
            footer={null}>
            <UserInterviewDateForm
                mutation={setDateInterview}
                refetchQueries={APPLICANTS_REFETCH_QUERIES}
                onSuccess={this.onSuccess}
                onError={this.onError}
                item={{
                    id: params.id,
                    date: params.dateInterview,
                    time: params.dateInterview
                }}
                applicant={params}
                hideResetBtn
                useValuesAsVariables />
        </Modal>;
    }
}
