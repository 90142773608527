
import React, { Fragment } from 'react';

import { APPLICANTS_STATUSES, APPLICANTS_STATUS_ICONS, APPLICANTS_STATUS_COLORS  } from '../../../constants/applicants';
import { StyledTextAction } from '../TimeLineView';

const Status = item => {
    return <Fragment>
        { item.status ? (
            <StyledTextAction className='nowrap' normal color={APPLICANTS_STATUS_COLORS[item.status]}>
                {APPLICANTS_STATUS_ICONS[item.status]} {APPLICANTS_STATUSES[item.status]}
            </StyledTextAction>
        ) : null}
    </Fragment>;
}

export default Status;
