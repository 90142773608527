import React, { Component } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import Route from '../../Route';
import RegionsClient from './RegionsClient';
import RegionsMacro from './RegionsMacro';
import RegionsAncor from './RegionsAncor';

const TABS = [
    { tab: 'Макрорегионы', key: 'macro', component: RegionsMacro },
    { tab: 'Регионы', key: 'client', component: RegionsClient },
    { tab: 'Города', key: 'ancor', component: RegionsAncor }
];

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        margin: 0;
    }
`;

export default class Regions extends Component {
    onChange = key => {
        this.props.history.push(`/regions/${key}`);
    }

    render() {
        const { params: { type }} = this.props.match;

        return <StyledTabs
            onChange={this.onChange}
            activeKey={type}
            animated={{ tabPane: false }}>
            { TABS.map(tab =>
                <Tabs.TabPane tab={tab.tab} key={tab.key}>
                    <Route path={`/regions/${tab.key}`} component={tab.component} />
                </Tabs.TabPane>
            )}
        </StyledTabs>;
    }
}
