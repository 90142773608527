import React, { Component } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { path, prop, pathOr } from 'ramda';
import * as yup from 'yup';
import { Row, Col, Spin } from 'antd';
import { Query } from 'react-apollo';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Datepicker from './formComponents/Datepicker';
import Textarea from './formComponents/Textarea';
import RadioButtons from './formComponents/RadioButtons';
import { GENDERS, EDUCATION, APPLICANTS_DOUBLES_STATUSES, APPLICANTS_STATUSES, APPLICANTS_STATUS_ICONS, APPLICANTS_STATUS_COLORS  } from '../../constants/applicants';
import Select from './formComponents/Select';
import { getHireRequests } from '../../queries/requests';
import { getApplicants } from '../../queries/applicants';
import HireRequestSelectSmall from '../user/HireRequestSelectSmall';
import FileUploader from './formComponents/FileUploader';
import { getConsultants } from '../../queries/consultants';
import { getFullNameString } from '../user/table/FullName';
import Applicant from '../user/table/Applicant';
import Position from '../user/table/Position';
import UserLink from '../user/table/UserLink';
import { StyledTextAction } from '../user/TimeLineView';
import ListenerField from './ListenerField';

const validationSchema = yup.object().shape({
    id: yup.string().required(),
    contact: yup.object().shape({
        lastName: yup.string().nullable().required(),
        firstName: yup.string().nullable().required(),
        email: yup.string()
            .when('phone', {
                is: val => !val,
                then: yup.string().nullable().email().required('Один из контактов обязателен'),
                otherwise:yup.string().nullable().email(),
            }),
        phone: yup.string().nullable().phone()
    })
});

class ApplicantForm extends Component {
    render() {
        const { showRequestIdSelector, isCheckApplicants } = this.props;

        return <div>
            { showRequestIdSelector &&
                <Query
                    query={getHireRequests}
                    variables={{
                        filter: {
                            status: 'inwork'
                        }
                    }}>
                    { ({ data, loading }) =>
                        <Field
                            name='id'
                            component={Select}
                            label='Заявка'
                            options={pathOr([], ['hireRequests', 'items'], data)}
                            namePath='position.name'
                            valuePath='id'
                            loading={loading}
                            renderSmall={HireRequestSelectSmall} />
                    }
                </Query>
            }
            <Row gutter={10}>
                <Col xs={24} sm={8}>
                    <Field
                        name='contact.lastName'
                        component={Input}
                        label='Фамилия'/>
                </Col>
                <Col xs={24} sm={8}>
                    <Field
                        name='contact.firstName'
                        component={Input}
                        label='Имя' />
                </Col>
                <Col xs={24} sm={8}>
                    <Field
                        name='contact.middleName'
                        component={Input}
                        label='Отчество' />
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={12}>
                    <Field
                        name='birthDate'
                        component={Datepicker}
                        label='Дата рождения'
                        startOf='day' />
                </Col>
                <Col xs={24} sm={12}>
                    <Field
                        name='gender'
                        component={RadioButtons}
                        label='Пол'
                        options={GENDERS} />
                </Col>
            </Row>
            <ListenerField listenFieldName='contact'>
                { ({ contact = {} }) => (
                    <Row gutter={10}>
                        <Col xs={24} sm={12}>
                            <Field
                                name='contact.email'
                                component={Input}
                                label='Email'
                                htmlType='email'
                                required={!contact.phone}
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Field
                                name='contact.phone'
                                component={Input}
                                label='Телефон'
                                required={!contact.email}
                            />
                        </Col>
                        { isCheckApplicants && (
                            <FormSpy subscription={{ errors: true, values: true }}>{({ errors, values }) => (
                                <Query
                                    query={getApplicants}
                                    skip={(!!path(['contact', 'email'], errors) || !!path(['contact', 'phone'], errors))}
                                    variables={{ filter: {
                                        contact: {
                                            phone: path(['contact', 'phone'], values),
                                            email: path(['contact', 'email'], values),
                                        },
                                        statuses: APPLICANTS_DOUBLES_STATUSES,
                                    }}}
                                >
                                    { ({ data, loading }) => !path(['contact', 'email'], errors) && !path(['contact', 'phone'], errors) &&
                                        <Col xs={24} sm={24}>
                                            { loading ? (
                                                <div className='copy-applicant'>Поиск двойников <Spin /></div>
                                            ) : (
                                                pathOr([], ['applicants', 'items'], data).map(item => (
                                                    <div key={item.id} className='copy-applicant'>
                                                        <div>Кандидат:  {item.depersonalized ? '-' : ''}</div>
                                                        <Applicant applicant={item} item={item.contact} />
                                                        <div>Заявка: </div>
                                                        <Position position={item.hireRequest.position.name} item={item} hideSmall />
                                                        <div>Рекрутер: </div>
                                                        <UserLink item={item.responsible} />
                                                        <StyledTextAction className='nowrap' normal color={APPLICANTS_STATUS_COLORS[item.status]}>
                                                            {APPLICANTS_STATUS_ICONS[item.status]} {APPLICANTS_STATUSES[item.status]}
                                                        </StyledTextAction>
                                                    </div>
                                                ))
                                            )}
                                        </Col>
                                    }
                                </Query>
                            )}</FormSpy>
                        )}
                    </Row>
                )}
            </ListenerField>
            <Query
                query={getConsultants}
                variables={{
                    filter: {
                        enabled: true
                    }
                }}>
                { ({ data, loading }) =>
                    <Field
                        name='responsible'
                        component={Select}
                        label='Рекрутер'
                        loading={loading}
                        options={pathOr([], ['consultants', 'items'], data)}
                        renderName={getFullNameString}
                        valuePath='id' />
                }
            </Query>
            <Field
                name='educationLevel'
                component={Select}
                label='Образование'
                options={EDUCATION} />
            <Field
                name='resume'
                component={FileUploader}
                label='Резюме' />
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>;
    }
}

export default withFormWrapper(ApplicantForm, {
    mapPropsToValues: prop('applicant'),
    validationSchema
});
