import React from 'react';
import { head } from 'ramda';
import styled, { css } from 'styled-components';

export const LinkWrapper = styled.span`
    ${({ onClick }) => !!onClick && css`
        color: #1890ff;
        cursor: pointer;
    `}
`;

const firstLetter = name => name ? `${head(name)}.` : '';

export const getShortNameString = data => {
    const item = data || {};
    return `${item.lastName || '' } ${ firstLetter(item.firstName) } ${ firstLetter(item.middleName) }`;
};

export const getFullNameString = data => {
    const item = data || {};
    return `${item.lastName || '' } ${ item.firstName || '' } ${ item.middleName || ''}`
};

export const getFullNameApplicantString = data => {
    const item = data || {};
    return getFullNameString(item.contact || {});
};

const FullName = ({ item = {}, onClick }) =>
    <LinkWrapper onClick={onClick}>{ getFullNameString(item) }</LinkWrapper>;

export default FullName;
