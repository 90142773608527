import React, { Component } from 'react';
import { message } from 'antd';

import { createHireRequest } from '../../../queries/requests';
import HireRequestForm from '../../forms/HireRequestForm';

export default class CreateHireRequest extends Component {
    onSuccess = () => {
        message.success('Заявка успешно сохранена');
        this.props.history.goBack();
    }

    onError = () => {
        message.error('Не удалось сохранить заявку');
    }

    render() {
        return <HireRequestForm
            name='hireRequest'
            mutation={createHireRequest}
            onSuccess={this.onSuccess}
            onError={this.onError} />;
    }
}
