import React, { Component } from 'react';

import ApplicantCopyForm from '../forms/ApplicantCopyForm';
import ModalsContext from '../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../constants/modals';
import Modal from './Modal';
import withUserContext from '../hocs/withUserContext';

class ApplicantFormModal extends Component {
    static defaultProps = {
        params: {}
    };

    onCopy = (values, openModal) => {
        this.props.remove();

        openModal(MODAL_TYPES.applicantForm, {
            applicant: {
                ...values.applicant,
                files: values.applicant.files && values.applicant.files.length ? [
                    values.applicant.files[0].id
                ] : [],
            },
            requestId: values.requestId,
            copy: true,
        });
    }

    render() {
        const { modal, params } = this.props;

        return <Modal {...modal} title={'Копирование кандидата'} footer={null}>
            <ModalsContext.Consumer>
                { ({ openModal }) =>
                    <ApplicantCopyForm
                        onSubmit={values => this.onCopy(values, openModal)}
                        values={{ requestId: params.requestId }}
                        requestId={params.requestId}
                    />
                }
            </ModalsContext.Consumer>
        </Modal>
    }
}

export default withUserContext(ApplicantFormModal);
