import React, { Component } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

import FullName from '../user/table/FullName';
import withUserContext from '../hocs/withUserContext';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
        overflow: hidden;
    }
`;

const Wrapper = styled.div`
    padding: 0 15px 15px;
`;

class ApplicantOpdModal extends Component {
    render() {
        const { modal, params: { applicant } } = this.props;
        return <StyledModal
            {...modal}
            title={<FullName item={applicant.contact} />}
            footer={null}
        >
            <Wrapper>
                <iframe className='opd-modal' srcdoc={applicant.pdaContent} title="СОПД" />
            </Wrapper>
        </StyledModal>;
    }
}

export default withUserContext(ApplicantOpdModal);
