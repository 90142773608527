import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { pathOr, path } from 'ramda';

import { getPositions } from '../../../queries/positions';
import { getRegionsAncor } from '../../../queries/regions';
import { getRegionsCascaderData, getRegionsManagerCascaderData } from '../../../utils/requests';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import Cascader from '../formComponents/Cascader';
import Datepicker, { DatepickerGroup } from '../formComponents/Datepicker';
import withUserContext from '../../hocs/withUserContext';

class ApplicantApprovalStatReportFilter extends Component {
    render() {
        const { additionalFilter } = this.props;
        const regionMacro = path(['user', 'regionMacro', 'id'], this.props);

        return (
            <Fragment>
                <Row gutter={4}>
                    { additionalFilter && (
                        <Col span={8}>
                            <Field
                                component={Select}
                                {...additionalFilter}
                            />
                        </Col>
                    )}
                    <Col span={8}>
                        <Query
                            query={getPositions}
                            variables={{
                                filter: {
                                    enabled: true
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='position'
                                    component={Select}
                                    options={pathOr([], ['positions', 'items'], data)}
                                    loading={loading}
                                    placeholder='Позиция'
                                    namePath='name'
                                    valuePath='id'
                                    allowClear />
                            }
                        </Query>
                    </Col>
                    <Col span={8}>
                        <Query
                            query={getRegionsAncor}
                            variables={{ filter: { regionMacro }}}
                        >
                            { ({ data }) =>
                                <Field
                                    name={regionMacro ? 'regionClient' : 'regionMacro'}
                                    additionalNames={regionMacro ? ['regionAncor'] : ['regionClient', 'regionAncor']}
                                    component={Cascader}
                                    placeholder={regionMacro ? 'Регион/Город' : 'Макрорегион/Регион/Город'}
                                    options={regionMacro ? getRegionsManagerCascaderData(data) : getRegionsCascaderData(data)}
                                />
                            }
                        </Query>
                    </Col>
                    <Col span={8}>
                        <DatepickerGroup>
                            <Field
                                name='minDate'
                                component={Datepicker}
                                 startOf={'day'}
                                placeholder='От' />
                            <Field
                                name='maxDate'
                                component={Datepicker}
                                endOf={'day'}
                                placeholder='До' />
                        </DatepickerGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withFilterForm(withUserContext(ApplicantApprovalStatReportFilter));
