import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { omit, path, pathOr, isNil, find, propEq } from 'ramda';
import { Query, withApollo } from 'react-apollo';
import { Button, message, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import NumberInput from './formComponents/NumberInput';
import Datepicker from './formComponents/Datepicker';
import Input from './formComponents/Input';
import { getPositions } from '../../queries/positions';
import Select from './formComponents/Select';
import CheckboxGroup from './formComponents/CheckboxGroup';
import Textarea from './formComponents/Textarea';
import ModalsContext from '../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../constants/modals';
import { GENDERS, EDUCATION } from '../../constants/applicants';
import { getRegionsAncor, getRegionsClient, getRegionsMacro } from '../../queries/regions';
import { sendHireRequestToClientApproval, createHireRequest } from '../../queries/requests';
import { formatMoney } from '../../utils/requests';
import withUserContext from '../hocs/withUserContext';
import ListenerField from './ListenerField';
import { getClientManagers } from '../../queries/clientManagers';
import { getFullNameString } from '../user/table/FullName';
import { getRole } from '../../utils/users';
import { CLIENT_MANAGER, CONSULTANT } from '../../constants/roles';
import { getSupervisors } from '../../queries/supervisors';
import { getConsultants } from '../../queries/consultants';

export const validationSchema = yup.object().shape({
    position: yup.string().required(),
    count: yup.number().positive().nullable().required(),
    requirements: yup.string().required(),
    regionMacro: yup.string().required(),
    regionClient: yup.string().required(),
    regionAncor: yup.string().required(),
    address: yup.string().nullable().required(),
    gender: yup.array().min(1).nullable().required(),
    minAge: yup.number().nullable().positive().required(),
    maxAge: yup.number().positive().nullable().required()
        .when('minAge', {
            is: val => !isNil(val),
            // eslint-disable-next-line no-template-curly-in-string
            then: yup.number().nullable().moreThan(yup.ref('minAge'), 'Максимальный возраст должен быть больше ${more}').required()
        }),
    qualification: yup.string().nullable().required(),
    qualificationAdditions: yup.string().nullable().required(),
    educationLevel: yup.string().nullable().required(),
    firstWorkingDate: yup.date().nullable().required(),
    jobConditions: yup.object().shape({
        schedule: yup.string().required(),
        salary: yup.number().nullable().required(),
        bonus: yup.number().nullable().required(),
        carCompensation: yup.string().required()
    })
});

const omitFields = ['status', '__typename', 'deadline', 'initiator', 'createdAt', 'statusComment'];
export const omitRequestBeforeSubmit = omit(['regionMacro', 'regionClient', 'regionAncor']);

export const mapPropsToValues = ({ request, user }) => request ? ({
    ...omit(omitFields, request),
    position: request.position.id,
    regionMacro: path(['regionMacro', 'id'], request),
    regionClient: path(['regionClient', 'id'], request),
    regionAncor: path(['regionAncor', 'id'], request),
    verifier: path(['verifier', 'id'], request),
    finalApprover: path(['finalApprover', 'id'], request),
    approver: path(['approver', 'id'], request),
    recruiter: path(['recruiter', 'id'], request),
    jobConditions: request.jobConditions ? omit(['__typename'], request.jobConditions) : {}
}) : ({
    jobConditions: {
        schedule: 'пн-пт с 9:00 до 18:00',
    },
    regionMacro: path(['regionMacro', 'id'], user),
    regionClient: path(['regionClient', 'id'], user),
    regionAncor: path(['regionAncor', 'id'], user)
});

export class HireRequestFields extends Component {
    onChangePosition = (value, data) => {
        const { form } = this.props;

        if (!form.getState().initialValues.requirements) {
            form.change('requirements', data.requirements);
        }
    }

    onCompletedManagers = () => {
        if (!this.props.request && getRole() === CLIENT_MANAGER) {
            this.props.form.change('approver', this.props.user.id);
        }
    }

    onChangeRegion = () => {
        this.props.form.change('verifier', null);
    }

    onGetRegionsMacro = (data) => {
        const isEdit = !!this.props.form.getState().initialValues.id;
        const items = pathOr([], ['regionsMacro', 'items'], data);

        if (!isEdit && (items.length === 1) && !this.props.form.getState().initialValues.regionMacro) {
            setTimeout(() => this.props.form.change('regionMacro', items[0].id), 100);
        }
    }

    onGetRegions = (data) => {
        const isEdit = !!this.props.form.getState().initialValues.id;
        const items = pathOr([], ['regionsClient', 'items'], data);

        if (!isEdit && (items.length === 1) && !this.props.form.getState().initialValues.regionClient) {
            setTimeout(() => this.props.form.change('regionClient', items[0].id), 100);
        }
    }

    render() {
        const { ancorStagesEnabled } = this.props.user;
        const { showRegionMacro } = this.props;
        const isEdit = !!this.props.form.getState().initialValues.id;

        return <Fragment>
            <Query
                query={getPositions}
                variables={{
                    filter: {
                        enabled: true
                    }
                }}>
                { ({ data, loading }) =>
                    <Field
                        name='position'
                        component={Select}
                        label='Позиция'
                        options={pathOr([], ['positions', 'items'], data)}
                        namePath='name'
                        valuePath='id'
                        loading={loading}
                        onChange={this.onChangePosition}
                        placeholder='название должности'
                        disabled={isEdit} />
                }
            </Query>
            <Field
                name='count'
                component={NumberInput}
                label='Необходимое количество исполнителей'
                placeholder='введите количество' />
            <Field
                name='requirements'
                component={Textarea}
                label='Обязанности исполнителя'
                placeholder='ключевые обязанности' />
            <h3>Место работы</h3>
            <Row gutter={10}>
                <Col xs={24} sm={showRegionMacro ? 8 : 0}>
                    { showRegionMacro &&
                        <Query
                            query={getRegionsMacro}
                            onCompleted={this.onGetRegionsMacro}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='regionMacro'
                                    component={Select}
                                    label='Макрорегион'
                                    loading={loading}
                                    options={pathOr([], ['regionsMacro', 'items'], data)}
                                    namePath='name'
                                    valuePath='id'
                                    onChange={this.onChangeRegion} />
                            }
                        </Query>
                    }
                </Col>
                <Col xs={24} sm={showRegionMacro ? 8 : 12}>
                    <ListenerField listenFieldName='regionMacro'>
                        { ({ regionMacro }) =>
                            (regionMacro || !showRegionMacro) &&
                                <Query
                                    query={getRegionsClient}
                                    variables={{
                                        filter: {
                                            regionMacro
                                        }
                                    }}
                                    onCompleted={this.onGetRegions}
                                >
                                    { ({ data, loading }) =>
                                        <Field
                                            name='regionClient'
                                            component={Select}
                                            label='Регион'
                                            loading={loading}
                                            options={pathOr([], ['regionsClient', 'items'], data)}
                                            namePath='name'
                                            valuePath='id'
                                            disabled={!!this.props.form.getState().initialValues.regionClient}
                                            onChange={this.onChangeRegion} />
                                    }
                                </Query>
                        }
                    </ListenerField>
                </Col>
                <Col xs={24} sm={showRegionMacro ? 8 : 12}>
                    <ListenerField listenFieldName='regionClient'>
                        { ({ regionClient }) =>
                            regionClient ?
                                <Query
                                    query={getRegionsAncor}
                                    variables={{
                                        filter: {
                                            regionClient
                                        }
                                    }}>
                                    { ({ data, loading }) =>
                                        <Field
                                            name='regionAncor'
                                            component={Select}
                                            label='Город'
                                            loading={loading}
                                            options={pathOr([], ['regionsAncor', 'items'], data)}
                                            namePath='name'
                                            valuePath='id'
                                            disabled={isEdit}
                                            required />
                                    }
                                </Query> : null
                        }
                    </ListenerField>
                </Col>
            </Row>
            { getRole() === CLIENT_MANAGER &&
                <ListenerField listenFieldName={['regionMacro', 'regionClient']}>
                    { ({ regionMacro, regionClient }) =>
                        <Query
                            query={getClientManagers}
                            variables={{
                                filter: {
                                    regionMacro
                                }
                            }}>
                            { ({ data: managersData, loading: managersLoading }) =>
                                <Query
                                    query={getSupervisors}
                                    variables={{
                                        filter: {
                                            regionClient
                                        }
                                    }}>
                                    { ({ data, loading }) =>
                                        <Field
                                            name='verifier'
                                            component={Select}
                                            label='Ответственный'
                                            loading={loading || managersLoading}
                                            options={[...pathOr([], ['clientManagers', 'items'], managersData), ...pathOr([], ['supervisors', 'items'], data)]}
                                            valuePath='id'
                                            renderName={getFullNameString}
                                            placeholder='выберите ответственного'
                                            disabled={!regionMacro || !regionClient} />
                                    }
                                </Query>
                            }
                        </Query>
                    }
                </ListenerField>
            }
            { isEdit &&
                <Query
                    query={getConsultants}
                    variables={{
                        filter: {
                            enabled: true,
                        }
                    }}
                >
                    { ({ data, loading }) =>
                        <Field
                            name='recruiter'
                            component={Select}
                            label='Рекрутер'
                            loading={loading}
                            options={pathOr([], ['consultants', 'items'], data)}
                            valuePath='id'
                            renderName={getFullNameString}
                            disabled={getRole() !== CONSULTANT}
                            placeholder='выберите рекрутера' />
                    }
                </Query>
            }
            { ancorStagesEnabled && (
                <Query
                    query={getClientManagers}
                    variables={{ filter: { approver: true }}}
                    onCompleted={this.onCompletedManagers}>
                    { ({ data, loading }) => {
                        let options = pathOr([], ['clientManagers', 'items'], data);
                        options = getRole() === CLIENT_MANAGER && !find(propEq('id', this.props.user.id), options) ? [
                            ...options,
                            this.props.user
                        ] : options;

                        return options.length ?
                            <Field
                                name='approver'
                                component={Select}
                                label='Ответственный за первое согласование'
                                placeholder='выберите ответственного за первое согласование'
                                options={options}
                                loading={loading}
                                renderName={getFullNameString}
                                valuePath='id'
                                allowClear /> : null;
                    }}
                </Query>
            )}
            { ancorStagesEnabled && (
                <Query query={getClientManagers} variables={{ filter: { finalApprover: true }}}>
                    { ({ data, loading }) => {
                        const options = pathOr([], ['clientManagers', 'items'], data);

                        return <Field
                            name='finalApprover'
                            component={Select}
                            label='Ответственный за финальное согласование'
                            placeholder='выберите ответственного за финальное согласование'
                            options={options}
                            loading={loading}
                            renderName={getFullNameString}
                            valuePath='id'
                            disabled={!options.length}
                            allowClear />;
                    }}
                </Query>
            )}
            <Field
                name='regionMacro'
                component={() => null} />
            <Field
                name='address'
                component={Input}
                label='Адрес' />
            <h3>Требования к кандидату</h3>
            <Row gutter={10}>
                <Col xs={24} md={10}>
                    <Field
                        name='gender'
                        component={CheckboxGroup}
                        label='Пол'
                        options={GENDERS}
                    />
                </Col>
                <Col xs={12} md={7}>
                    <Field
                        name='minAge'
                        component={NumberInput}
                        label='Минимальный возраст' />
                </Col>
                <Col xs={12} md={7}>
                    <Field
                        name='maxAge'
                        component={NumberInput}
                        label='Максимальный возраст' />
                </Col>
            </Row>
            <Field
                name='qualification'
                component={Textarea}
                label='Квалификация' />
            <Field
                name='qualificationAdditions'
                component={Textarea}
                label='Дополнительные требования' />
            <Field
                name='educationLevel'
                component={Select}
                label='Образование'
                options={EDUCATION} />
            <h3>Условия</h3>
            <Row gutter={10}>
                <Col xs={24} sm={12}>
                    <Field
                        name='firstWorkingDate'
                        component={Datepicker}
                        label='Первый рабочий день' />
                </Col>
                <Col xs={24} sm={12}>
                    <Field
                        name='jobConditions.schedule'
                        component={Input}
                        label='График работы' />
                </Col>
            </Row>
            <Row gutter={10}>
                <Col sm={12}>
                    <Field
                        name='jobConditions.salary'
                        component={NumberInput}
                        formatter={formatMoney}
                        step='1000'
                        label='Заработная плата (гросс)' />
                </Col>
                <Col sm={12}>
                    <Field
                        name='jobConditions.bonus'
                        component={NumberInput}
                        formatter={formatMoney}
                        step='1000'
                        label='Бонусы (гросс)' />
                </Col>
            </Row>
            <Field
                name='jobConditions.carCompensation'
                component={Input}
                label='Компенсация на дорогу' />
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий' />
        </Fragment>;
    }
}

class HireRequestForm extends Component {
    sendRequest = (e, openModal) => {
        const { invalid, form, request, client } = this.props;

        if (!invalid) {
            e.preventDefault();

            if (request) {
                openModal(MODAL_TYPES.sendRequest, {
                    request: omitRequestBeforeSubmit(form.getState().values)
                });
            } else {
                const { values } = form.getState();

                client.mutate({
                    mutation: createHireRequest,
                    variables: { values }
                }).then(payload => {
                    client.mutate({
                        mutation: sendHireRequestToClientApproval,
                        variables: {
                            ...values,
                            id: path(['data', 'createHireRequest', 'id'], payload)
                        }
                    })
                    .then(() => this.onSuccess())
                    .catch(() => this.onError());
                }).catch(() => this.onError());
            }
        }
    }

    onSuccess = () => {
        message.success('Заявка был успешно отправлена');
        this.props.history.goBack();
    }

    onError = () => message.error('Не удалось отправить заявку');

    render() {
        const { request } = this.props;

        return <div>
            <h1>{ request ? 'Редактировать заявку' : 'Новая заявка' }</h1>
            <HireRequestFields {...this.props} />
            <div className="controls">
                <ModalsContext.Consumer>
                    { ({ openModal }) =>
                        <Button
                            type='primary'
                            htmlType='submit'
                            onClick={e => this.sendRequest(e, openModal)}>
                            Отправить
                        </Button>
                    }
                </ModalsContext.Consumer>
                <SubmitButton className='btn-default'>
                    Сохранить черновик
                </SubmitButton>
            </div>
        </div>;
    }
}

export default withUserContext(withFormWrapper(withApollo(withRouter(HireRequestForm)), {
    validationSchema,
    mapPropsToValues,
    mapBeforeSubmit: values => values.id ? omitRequestBeforeSubmit(values) : values
}));
