import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Spin, Table } from 'antd';
import { pathOr, path } from 'ramda';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import XLSX from 'xlsx';

import { getSentApplicantsStat } from '../../../../queries/requests';
import { getUrlFilter } from '../../../../utils/urlParams';
import SentApplicantsStatReportFilter from '../../../forms/filters/SentApplicantsStatReportFilter';
import { Toolbar } from '../../table/Table';
import { ErrorBlock, ErrorBlockIcon } from './Report';

export default class SentApplicantsStatReport extends Component {
    getColumns = () => {
        return [
            {
                title: 'Регион',
                dataIndex: ['regionClient', 'name'],
                key: 'regionClient'
            },
            {
                title: 'Город',
                dataIndex: ['regionAncor', 'name'],
                key: 'regionAncor'
            },
            {
                title: 'Позиция',
                dataIndex: ['position', 'name'],
                key: 'position'
            },
            {
                title: 'Направлено кандидатов',
                dataIndex: 'created',
                key: 'created',
                align: 'center'
            },
            {
                title: 'Проведено интервью',
                dataIndex: 'interview',
                key: 'interview',
                align: 'center'
            },
        ];
    }

    downloadExcel = data => {
        const ws = XLSX.utils.json_to_sheet(pathOr([], ['sentApplicantsStat'], data).map(item => ({
            'Регион': path(['regionClient', 'name'], item),
            'Город': path(['regionAncor', 'name'], item),
            'Позиция': path(['position', 'name'], item),
            'Направлено кандидатов': item.created,
            'Проведено интервью': item.interview
        })), {
            header: [
                'Регион',
                'Город',
                'Позиция',
                'Направлено кандидатов',
                'Проведено интервью'
            ]
        });
        ws['!cols'] = [
            { wpx: 130 },
            { wpx: 130 },
            { wpx: 130 },
            { wpx: 140 },
            { wpx: 130 }
        ];
		const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Отчёт');

		XLSX.writeFile(wb, 'sent-applicants-stat.xlsx')
    }

    render() {
        const { location } = this.props;
        const filters = getUrlFilter(location);

        return <Query
            query={getSentApplicantsStat}
            variables={{ filter: filters }}>
            { ({ data, loading, error }) =>
                <Fragment>
                    <Toolbar>
                        <Col span={23}>
                            <SentApplicantsStatReportFilter filter={filters} />
                        </Col>
                        <Col span={1}>
                            <Button
                                shape='circle'
                                icon={<FileExcelOutlined />}
                                disabled={loading}
                                onClick={() => this.downloadExcel(data)} />
                        </Col>
                    </Toolbar>
                    <Spin
                        spinning={loading}
                        delay={1000}>
                        <Table
                            columns={this.getColumns()}
                            dataSource={pathOr([], ['sentApplicantsStat'], data).map((item, index) => ({ ...item, id: `sent-applicants-stat-${index}` }))}
                            pagination={false}
                            rowKey='id'
                            locale={{
                                emptyText: error ?
                                    <ErrorBlock>
                                        <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                                        <div>Не удалось отобразить данные</div>
                                    </ErrorBlock> :
                                    'Нет данных'
                            }} />
                    </Spin>
                </Fragment>
            }
        </Query>;
    }
}
