import React from 'react';
import { Alert } from 'antd';

const Error = ({ error }) => error.graphQLErrors && error.graphQLErrors.map(({ message }, index) => (
    <Alert
        key={index}
        type='error'
        message={`${message.statusCode} - ${message.message}`}
    />
));

export default Error;
