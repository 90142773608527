import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';

import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import { ENABLED_STATE } from '../../../constants/lists';

class PositionFilter extends Component {
    render() {
        return <Row>
            <Col span={12}>
                <Field
                    name='enabled'
                    component={Select}
                    options={ENABLED_STATE} />
            </Col>
        </Row>
    }
}

export default withFilterForm(PositionFilter);
