import moment from 'moment';
import { addWorkingDays } from '@experium/business-days-calculator';

export const DEADLINE_WARNING_HOURS = 18;

export const getDeadlineClass = ({ deadline }) => {
    const dateDiff = moment.duration(moment.utc(deadline).diff(moment.utc())).asHours();

    if (dateDiff < 0) {
        return 'deadline-expired';
    } else if (dateDiff < DEADLINE_WARNING_HOURS) {
        return 'deadline-soon';
    } else {
        return null;
    }
}

export const isNextWorkWeekFrom = currentDate => date => {
    currentDate = moment.utc(currentDate);
    const maxDate = moment.utc(addWorkingDays(currentDate.toDate(), 5));

    return date && (!date.isSameOrAfter(currentDate) || !date.isSameOrBefore(maxDate));
};

export const isNextWorkWeek = isNextWorkWeekFrom(moment.utc());
