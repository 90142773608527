import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { message } from 'antd';

import { getApplicant } from '../../../queries/applicants';
import { APPLICANT_REFETCH_QUERIES } from '../../../constants/applicants';
import UserInterviewDateForm from '../../forms/UserInterviewDateForm';
import { setDateInterview } from '../../../queries/applicants';
import UserItem from './UserItem';

class UserInterviewDate extends Component {
    onSuccess = () => {
        message.success('Дата интервью успешно назначена');
        this.props.history.goBack();
    }

    onError = () => {
        message.error('Не удалось сохранить заявку');
    }

    render() {
        const { data: { applicant = {}}} = this.props;

        return applicant.id ? <Fragment>
            <UserItem
                item={applicant}
                title='Интервью'
                hideDate={true}
            />
            <UserInterviewDateForm
                mutation={setDateInterview}
                refetchQueries={APPLICANT_REFETCH_QUERIES}
                onSuccess={this.onSuccess}
                onError={this.onError}
                item={{
                    id: applicant.id,
                    date: applicant.dateInterview,
                    time: applicant.dateInterview
                }}
                applicant={applicant}
                useValuesAsVariables />
        </Fragment> : null;
    }
}

export default graphql(getApplicant, {
    options: ({ match }) => ({
        variables: {
            id: match.params.id
        }
    })
})(UserInterviewDate);
