import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import { Spin } from 'antd';
import styled from 'styled-components';

import withUser from '../hocs/withUser';
import Modals from '../modals/Modals';
import CommonRoutes from './CommonRoutes';
import GuestLayout from './guest/GuestLayout';
import SupervisorLayout from './user/SupervisorLayout';
import AdminLayout from './user/AdminLayout';
import { getRole } from '../../utils/users';
import { SUPERVISOR } from '../../constants/roles';

const SpinContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

class SecureLayout extends Component {
    getUserLayout = () => {
        const { logout } = this.props;
        const role = getRole();
        const Layout = role === SUPERVISOR ? SupervisorLayout : AdminLayout;

        return (
            <Modals>
                <Layout logout={logout} />
            </Modals>
        );
    }

    render() {
        const { user, authenticating } = this.props;

        if (authenticating) {
            return <SpinContainer>
                <Spin />
            </SpinContainer>;
        }

        return <Fragment>
            <CommonRoutes>
                { user ?
                    this.getUserLayout() :
                    <GuestLayout />
                }
            </CommonRoutes>
        </Fragment>;
    }
}

export default withApollo(withUser(SecureLayout, true));
