import React, { Component } from 'react';
import { Checkbox } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class CheckboxGroup extends Component {
    static defaultProps = {
        options: []
    };

    onChange = checkedValues => this.props.onChange(checkedValues);

    render() {
        const { options, input: { value, name } } = this.props;

        return (
            <Checkbox.Group name={name} value={value || null} style={{ width: '100%' }} onChange={this.onChange}>
                { options.map(option =>
                    <Checkbox key={option.value} value={option.value}>
                        { option.label }
                    </Checkbox>
                )}
            </Checkbox.Group>
        );
    }
}

export default withFieldWrapper(CheckboxGroup);
