import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Query } from 'react-apollo';

import { MODAL_TYPES } from '../../../constants/modals';
import { getDeadlineSettings } from '../../../queries/deadline';
import ModalsContext from '../../../contexts/ModalsContext';
import { pathOr } from 'ramda';

const Title = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    h3 {
        margin-bottom: 0;
    }
`;

export default class SlaSettings extends Component {
    render() {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Fragment>
                    <Title>
                        <h3>SLA (заявки)</h3>
                        <Button
                            type='primary'
                            onClick={() => openModal(MODAL_TYPES.deadline, {
                                type: 'hireRequest'
                            })}>
                            Редактировать
                        </Button>
                    </Title>
                    <Query
                        query={getDeadlineSettings}
                        variables={{ type: 'hireRequest' }}>
                        { ({ data }) =>
                            <div style={{ padding: 15, borderBottom: '1px solid #f0f0f0' }}>
                                <div><strong>Согласование:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'clientApproval'], data) }</div>
                                <div><strong>Финальное согласование:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'finalClientApproval'], data) }</div>
                                <div><strong>Ожидание Анкор:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'ancorAwaiting'], data) }</div>
                                <div><strong>Согласование Анкор:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'ancorApproval'], data) }</div>
                                <div><strong>В работе:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'inwork'], data) }</div>
                            </div>
                        }
                    </Query>
                    <Title>
                        <h3>SLA (кандидаты)</h3>
                        <Button
                            type='primary'
                            onClick={() => openModal(MODAL_TYPES.deadline, {
                                type: 'applicant'
                            })}>
                            Редактировать
                        </Button>
                    </Title>
                    <Query
                        query={getDeadlineSettings}
                        variables={{ type: 'applicant' }}>
                        { ({ data }) =>
                            <div style={{ padding: 15 }}>
                                <div><strong>Согласование:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'supervisorApproval'], data) }</div>
                                <div><strong>Интервью:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'supervisorInterview'], data) }</div>
                                <div><strong>СБ:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'securityApproval'], data) }</div>
                                <div><strong>Оформление:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'registration'], data) }</div>
                                <div><strong>Ожидание выхода:</strong> { pathOr(0, ['deadlineSettings', 'periods', 'attendanceAwaiting'], data) }</div>
                            </div>
                        }
                    </Query>
                </Fragment>
            }
        </ModalsContext.Consumer>
    }
}
