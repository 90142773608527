import gql from 'graphql-tag';

export const getSearch = gql`
    query search($filter: ApplicantFilterInput, $pagination: PaginationInput, $sorting: ApplicantSortInput) {
        applicants(filter: $filter, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                status,
                contact {
                    firstName,
                    lastName,
                    middleName,
                    phone,
                    email,
                },
                hireRequest {
                    id,
                    position {
                        name,
                        id
                    },
                    regionAncor {
                        id,
                        name
                    },
                },
            }
        }

    }
`;
