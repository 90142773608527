import * as yup from 'yup';
import { setLocale } from 'yup';
import { has, forEachObjIndexed, trim } from 'ramda';
import * as validator from 'class-validator';

import validationLocale from '../constants/validationLocale';

setLocale(validationLocale);

export const required = value => value === undefined && 'Обязательное поле';

yup.addMethod(yup.string, 'phone', function phone(ref, msg) {
    return this.test({
        name: 'phone',
        exclusive: false,
        message: msg || 'Введите телефон в формате: +79123456789',
        test: (value) => value ? validator.isMobilePhone(value, 'ru-RU') : true
    });
});

yup.addMethod(yup.string, 'required', function required() {
    return this.test({
        name: 'required',
        exclusive: false,
        message: validationLocale.mixed.required,
        test: (value) => !!trim(value || '')
    });
});

export const getRequiredFields = schema => {
    const requiredFields = [];

    if (!schema) {
        return requiredFields;
    }

    const fn = (fields, path) => forEachObjIndexed((field, key) => {
        const fieldPath = path ? `${path}.${key}` : key;
        field.fields ?
            fn(field.fields, fieldPath) :
            has('required', field.exclusiveTests) && requiredFields.push(fieldPath);
    }, fields);

    fn(schema.fields);

    return requiredFields;
};
