import React, { Component, Fragment } from 'react';
import { path, contains } from 'ramda';

import FullName from '../user/table/FullName';
import DetailField from '../user/DetailField';
import UserEmail from '../user/table/UserEmail';
import DateFormat from '../user/table/DateFormat';
import Modal from './Modal';

export default class UserViewModal extends Component {
    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={<FullName item={params} />}
            footer={null}>
            <DetailField title='ФИО' value={<FullName item={params} />} />
            <DetailField title='Email' value={<UserEmail email={params.username} activated={params.activated} />} />
            <DetailField title='Телефон' value={params.phone} />
            <DetailField title='Макрорегион' value={path(['regionMacro', 'name'], params)} />
            <DetailField title='Регион' value={path(['regionClient', 'name'], params)} />
            <DetailField title='Город' value={path(['regionAncor', 'name'], params)} />
            <DetailField title='Активен' value={params.enabled ? 'Да' : 'Нет'} />
            { params.__typename === 'ClientManager' &&
                <Fragment>
                    <DetailField title='Согласование заявок' value={params.allowHireRequestApproval ? 'Да' : 'Нет'} />
                    <DetailField title='Первое согласование заявок' value={params.approver ? 'Да' : 'Нет'} />
                    <DetailField title='Финальное согласование заявок' value={params.finalApprover ? 'Да' : 'Нет'} />
                </Fragment>
            }
            { contains(params.__typename, ['ClientManager', 'Supervisor']) &&
                <DetailField title='Управление статусами кандидатов' value={params.applicantStatusManagementEnabled ? 'Да' : 'Нет'} />
            }
            { params.__typename === 'Supervisor' &&
                <DetailField title='Создание заявок' value={params.hireRequestManagementEnabled ? 'Да' : 'Нет'} />
            }
            { params.lastLoginAt && <DetailField title='Дата последнего входа' value={<DateFormat date={params.lastLoginAt} />} /> }
        </Modal>
    }
}
