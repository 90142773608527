import React, { Component } from 'react';
import { Button, Layout } from 'antd';
import { Switch, Link } from 'react-router-dom';
import styled from 'styled-components';

import NoMatch from '../NoMatch';
import AdminMenu from './AdminMenu';
import Search from './Search';
import Dashboard from "../../user/admin/Dashboard";
import Users from '../../user/admin/Users';
import HireRequests from '../../user/HireRequests';
import Positions from '../../user/admin/Positions';
import ApplicantsDeadline from '../../user/admin/ApplicantsDeadline';
import SecureRoute from './SecureRoute';
import { ADMIN, CONSULTANT, CLIENT_MANAGER } from '../../../constants/roles';
import Applicants from '../../user/admin/Applicants';
import Reports from '../../user/consultant/Reports';
import AuthLog from '../../user/admin/AuthLog';
import Route from '../../Route';
import Regions from '../../user/admin/Regions';
import Settings from '../../user/admin/Settings';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { includes } from 'ramda';
import { getRole } from '../../../utils/users';
import { MODAL_TYPES } from '../../../constants/modals';
import ModalsContext from '../../../contexts/ModalsContext';

const MainLayout = styled.div`
    height: 100%;
`;

const Container = styled(Layout)`
    height: 100%;
`;

const Content = styled(Layout)`
    background: #E6ECF6;
`;

const Wrapper = styled.div`
    margin: 30px;
    background: #fff;
`;
const Logo = styled(Link)`
    display: inline-block;
    background-image: url("../../assets/logo.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 21px;
`;

const TechButton = styled(Button)`
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 2;
`;

export default class AdminLayout extends Component {
    render() {
        return <MainLayout className='user-layout'>
            <Container>
                <Layout.Header>
                    <Logo to="/" />
                    <Search />
                    <Button ghost onClick={this.props.logout} className='logout-btn'>Выйти</Button>
                </Layout.Header>
                <Layout>
                    <AdminMenu />
                    <Content>
                        <Layout.Content>
                            <Wrapper>
                                <Switch>
                                    <Route path='/' exact component={Dashboard} />
                                    <Route path='/requests/:type' component={HireRequests} />
                                    <Route path='/users/:type' component={Users} />
                                    <Route path='/applicants/:type' component={Applicants} />
                                    <SecureRoute path='/positions' component={Positions} roles={[ADMIN, CONSULTANT]} />
                                    <SecureRoute path='/reports/:report?' component={Reports} roles={[CONSULTANT, ADMIN, CLIENT_MANAGER]} />
                                    <SecureRoute path='/deadlines' component={ApplicantsDeadline} roles={[CONSULTANT, ADMIN]} />
                                    <SecureRoute path='/auth' component={AuthLog} roles={[ADMIN]} />
                                    <SecureRoute path='/regions/:type' component={Regions} roles={[ADMIN, CONSULTANT]} />
                                    <SecureRoute path='/settings/:type' component={Settings} roles={[ADMIN, CONSULTANT]} />
                                    <Route component={NoMatch} />
                                </Switch>
                            </Wrapper>
                        </Layout.Content>
                        { includes(getRole(), [CONSULTANT, ADMIN]) &&
                            <ModalsContext.Consumer>
                                { ({ openModal }) =>
                                    <TechButton
                                        type='primary'
                                        shape='circle'
                                        size='large'
                                        icon={<CustomerServiceOutlined />}
                                        onClick={() => openModal(MODAL_TYPES.ancorTech)} />
                                }
                            </ModalsContext.Consumer>
                        }
                    </Content>
                </Layout>
            </Container>
        </MainLayout>;
    }
}
