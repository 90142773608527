import React, { Component } from 'react';

import Modal from './Modal';
import RegionAncorForm from '../forms/RegionAncorForm';
import { editRegionAncor, createRegionAncor } from '../../queries/regions';
import { message } from 'antd';

export default class RegionAncorModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Город был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} город`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать город' : 'Добавить город'}
            footer={null}>
            <RegionAncorForm
                mutation={params ? editRegionAncor : createRegionAncor}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['regionsAncor']}
                item={params} />
        </Modal>;
    }
}
