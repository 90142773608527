import React, { Fragment } from 'react';
import { Empty, Button, Alert } from 'antd';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';

export default function EmptyList({ items, refetch, error, loading }) {
    if (items.length === 0) {
        return (
            <div className="empty-box">
                { loading ? <LoadingOutlined style={{ fontSize: 42 }} /> : (
                    <Fragment>
                        { error ? (
                            <Alert type='error' message='Не удалось загрузить данные' />
                        ) : (
                            <Empty description="Пока нет кандидатов" />
                        )}
                        <br />
                        <Button onClick={() => refetch()}>
                            <SyncOutlined /> Обновить
                        </Button>
                    </Fragment>
                )}
            </div>
        );
    }
}
