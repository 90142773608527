import React, { Component } from 'react';
import { path } from 'ramda';
import { message, Spin } from 'antd';
import { Query } from 'react-apollo';

import HireRequestForm from '../../forms/HireRequestForm';
import { editHireRequest, getHireRequest } from '../../../queries/requests';
import HireRequestView from './HireRequestView';
import Error from '../Error';

export default class HireRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: !path(['match', 'params', 'id'], props)
        };
    }

    onSuccessEdit = () => {
        message.success('Заявка успешно изменена');
        this.setState({ edit: false });
    }

    onError = () => {
        message.error('Не удалось сохранить заявку');
    }

    onSuccessRemove = () => {
        message.success('Заявка успешно удалена');
        this.props.history.goBack();
    }

    edit = () => this.setState({ edit: true });

    renderWithId = id => {
        return <Query
            query={getHireRequest}
            variables={{ id }}>
            { ({ loading, error, data }) =>
                loading ? <Spin /> :
                    error ? <Error error={error} /> :
                        this.state.edit ?
                            <HireRequestForm
                                mutation={editHireRequest}
                                onSuccess={this.onSuccessEdit}
                                onError={this.onError}
                                request={data.hireRequest}
                                refetchQueries={['hireRequest']} /> :
                            <HireRequestView
                                request={data.hireRequest}
                                onEdit={this.edit}
                                editable={data.hireRequest.status === 'draft'} />
                }
        </Query>
    }

    render() {
        const id = path(['match', 'params', 'id'], this.props);

        return this.renderWithId(id);
    }
}
