import gql from 'graphql-tag';

export const getFile = gql(`
    query file($id: ID!) {
        file(id: $id) {
            id,
            fileName
        }
    }
`);

export const uploadFile = gql(`
    mutation uploadFile($file: Upload!) {
        uploadFile(file: $file) {
            id,
            fileName
        }
    }
`);
