import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import moment from 'moment';

import DateTimePicker from './formComponents/DateTimePicker';
import Datepicker from './formComponents/Datepicker';
import Timepicker from './formComponents/Timepicker';
import { needNativeInputs } from '../../utils/mobile';

export default class InterviewDateFields extends Component {
    getName = name => {
        const { prefix } = this.props;

        return prefix ? `${prefix}.${name}` : name;
    }

    render() {
        const disabled = value => value.isBefore(moment(), 'day');

        return needNativeInputs() ? (
            <Field
                name={this.getName('date')}
                component={DateTimePicker}
                disabledDate={disabled}
                label='Дата Время' />
        ) : (
            <Fragment>
                <Field
                    name={this.getName('date')}
                    component={Datepicker}
                    disabledDate={disabled}
                    placeholder='Выберите дату'
                    label='Дата' />
                <Field
                    name={this.getName('time')}
                    component={Timepicker}
                    label='Время' />
            </Fragment>
        );
    }
}
