import React, { Component } from 'react';
import { Input as AntdInput } from 'antd';
import styled, { css } from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const Container = styled.div`
    ${({ customIcon }) => customIcon && css`
        position: relative;
        .custom-icon {
            position: absolute;
            font-size: 18px;
            line-height: 40px;
            padding: 0 10px;
            z-index: 2;
        }
        input {
            padding-left: 40px !important;
        }
    `}
`;

class Input extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value, name }, icon, placeholder, htmlType, customIcon } = this.props;

        return <Container customIcon={customIcon}>
            { customIcon && <i className={`custom-icon icon-${customIcon}`} /> }
            <AntdInput
                name={name}
                value={value}
                onChange={this.onChange}
                prefix={icon}
                placeholder={placeholder}
                type={htmlType} />
        </Container>;
    }
}

export default withFieldWrapper(Input);
