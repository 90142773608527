import 'react-app-polyfill/ie11';
import 'string.prototype.startswith';
import 'ie-array-find-polyfill';
import 'core-js/es/array/find-index';
import 'core-js/es/array/includes';
import 'core-js/es/object/values';
import 'core-js/es/symbol';

import rollbarInit from './utils/rollbar';

import React from 'react';
import ReactDOM from 'react-dom';
import { locale } from 'moment';
import { message } from 'antd';
import 'moment/locale/ru';
import 'yup';

import 'antd/dist/antd.css';
import './styles/index.scss';
import './utils/yup';
import App from './components/App';

locale('ru');

message.config({
    top: 50,
});

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: 'a1c195744f4547daabd8cb5894fad59e',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

ReactDOM.render(<App />, document.getElementById('root'));
