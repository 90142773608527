import React, { Component } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { Mutation } from 'react-apollo';

import Table from '../table/Table';
import { getRegionsClient, removeRegionClient } from '../../../queries/regions';
import RegionClientFilter from '../../forms/filters/RegionClientFilter';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import { ADMIN } from '../../../constants/roles';
import { getRole } from '../../../utils/users';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

export default class RegionsClient extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Макрорегион',
                key: 'regionMacro',
                dataIndex: ['regionMacro', 'name']
            },
            {
                key: 'controls',
                align: 'right',
                render: item =>
                    <Button.Group>
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => openModal(MODAL_TYPES.regionClient, item)} />
                            }
                        </ModalsContext.Consumer>
                        { getRole() === ADMIN &&
                            <Mutation
                                mutation={removeRegionClient}
                                refetchQueries={['regionsClient']}
                                variables={{ id: item.id }}
                                onCompleted={() => message.success('Регион был успешно удален')}
                                onError={() => message.error('Не удалось удалить регион')}>
                                { (mutate, { loading }) =>
                                    <Popconfirm
                                        title='Вы уверены, что хотите удалить регион?'
                                        okText='Да'
                                        cancelText='Нет'
                                        onConfirm={mutate}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            loading={loading} />
                                    </Popconfirm>
                                }
                            </Mutation>
                        }
                    </Button.Group>
            }
        ];
    }

    getToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Button
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => openModal(MODAL_TYPES.regionClient)}>
                    Добавить
                </Button>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        return <Table
            query={getRegionsClient}
            name='regionsClient'
            columns={this.getColumns()}
            FilterForm={RegionClientFilter}
            toolbarButtons={this.getToolbarButtons()} />;
    }
}
