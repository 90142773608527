import gql from 'graphql-tag';

export const getDeadlineSettings = gql(`
    query deadlineSettings($type: DeadlineEntityType!) {
        deadlineSettings(type: $type) {
            type,
            periods
        }
    }
`);

export const setDeadlineSettings = gql(`
    mutation setDeadlineSettings($values: DeadlineInput!) {
        setDeadlineSettings(deadline: $values) {
            id
        }
    }
`);

export const removeDeadlineSettings = gql(`
    mutation removeDeadlineSettings($type: DeadlineEntityType!) {
        removeDeadlineSettings(type: $type) {
            id
        }
    }
`);
