import React, { Component, Fragment } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import { FormSpy } from 'react-final-form';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class RangeComponent extends Component {
    static propTypes = {
        placeholder: PropTypes.array,
        input: PropTypes.object,
        form: PropTypes.object,
        values: PropTypes.object,
        onChange: PropTypes.func,
        className: PropTypes.string,
        toPath: PropTypes.string,
        change: PropTypes.func,
        t: PropTypes.func
    }

    static defaultProps = {
        placeholder: ['от', 'до'],
    }

    onChange = (value, change) => {
        const { onChange, toPath } = this.props;
        const hasValue = !isEmpty(value);

        if (!value || !hasValue) {
            onChange(undefined);
            change(toPath, undefined);
        } else {
            if (value[0]) {
                onChange(value[0].startOf('day'));
                change(toPath, value[1].endOf('day'));
            } else {
                onChange(value.startOf('day'));
                change(toPath, null);
            }
        }
    }

    render() {
        const { form, values, placeholder, input: { value }, className, toPath } = this.props;
        const pickerValue = value ? [moment(value), values[toPath] ? moment(values[toPath]) : null] : undefined;

        return (
            <Fragment>
                <DatePicker.RangePicker
                    value={pickerValue}
                    className={className}
                    onChange={e => this.onChange(e, form.change)}
                    placeholder={placeholder}
                    format='DD-MM-YYYY'
                />
            </Fragment>
        );
    }
}

export default withFieldWrapper(props => (
    <FormSpy subscription={{ values: true }}>
        { formProps =>
            <RangeComponent {...formProps} {...props} />
        }
    </FormSpy>
));
