import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Textarea from './formComponents/Textarea';

class HireRequestCommentForm extends Component {
    render() {
        return <div className={this.props.className}>
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий' />
            <SubmitButton>Отправить</SubmitButton>
        </div>
    }
}

export default withFormWrapper(HireRequestCommentForm, {
    mapPropsToValues: prop('request')
});
