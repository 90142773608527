import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { omit } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import { getRegionsClient } from '../../queries/regions';
import { getRegionsClientCascaderData } from '../../utils/requests';
import Cascader from './formComponents/Cascader';

class RegionAncorForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Query query={getRegionsClient}>
                { ({ data }) =>
                    <Field
                        name='regionMacro'
                        additionalNames={['regionClient']}
                        component={Cascader}
                        label='Регион'
                        placeholder='Макрорегион/Регион'
                        options={getRegionsClientCascaderData(data)} />
                }
            </Query>
            <SubmitButton>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    regionMacro: yup.string().required(),
    regionClient: yup.string().required()
});

export default withFormWrapper(RegionAncorForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item ? ({
        id: item.id,
        name: item.name,
        regionMacro: item.regionClient.regionMacro.id,
        regionClient: item.regionClient.id
    }) : null,
    mapBeforeSubmit: omit(['regionMacro'])
});
