import React, { Component } from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { uniteDates } from '../../utils/date';
import { needNativeInputs } from '../../utils/mobile';
import InterviewDateFields from './InterviewDateFields';

const validationSchema = () => yup.object().shape(!needNativeInputs() ? ({
    date: yup.date().nullable().required(),
    time: yup.date().nullable().required(),
}) : ({
    date: yup.date().nullable().required(),
}));

class UserInterviewDateForm extends Component {
    render() {
        const { applicant, hideResetBtn } = this.props;

        return <div>
            <InterviewDateFields applicant={applicant} />
            <div className="controls">
                <SubmitButton>Сохранить</SubmitButton>
                { !hideResetBtn && <Button className="btn-default" onClick={() => this.props.history.goBack()}>Отменить</Button> }
            </div>
        </div>
    }
}

export default withFormWrapper(withRouter(UserInterviewDateForm), {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        ...item,
        time: item.date
    }),
    mapBeforeSubmit: values => ({
        id: values.id,
        date: uniteDates(values.date, values.time)
    })
});
