import React, { Component, Fragment } from 'react';
import { Modal, Popover, Tabs, Button, message } from 'antd';
import { find, propEq, path } from 'ramda';
import { Mutation, withApollo } from 'react-apollo';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import FullName from '../user/table/FullName';
import DetailField from '../user/DetailField';
import DateFormat from '../user/table/DateFormat';
import { editApplicant, sendPdaNotification } from '../../queries/applicants';
import { MODAL_TYPES } from '../../constants/modals';
import { CONSULTANT, ADMIN } from '../../constants/roles';
import { APPLICANTS_REFETCH_QUERIES, GENDERS, EDUCATION, APPLICANTS_TABS, REJECT_REASON, APPLICANTS_DEPERSONALIZATION_REASONS } from '../../constants/applicants';
import ApplicantLogs from '../user/admin/ApplicantLogs';
import { InfoCircleTwoTone } from '@ant-design/icons';
import DeadlineFormat from '../user/table/DeadlineFormat';
import withUserContext from '../hocs/withUserContext';
import ModalsContext from '../../contexts/ModalsContext';
import { getRole } from '../../utils/users';

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: #e8e8e8;
    margin: 15px 0;
`;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
        overflow: hidden;
    }
`;

const Wrapper = styled.div`
    padding: 0 15px 15px;
`;

export const InterviewResults = ({ interviewFeedback }) => (
    <Popover
        trigger='click'
        content={(
            <div>
                <DetailField title='Достижение результатов' value={path(['achievementOfResults'], interviewFeedback)} />
                <DetailField title='Мотивация к работе' value={path(['motivation'], interviewFeedback)} />
                <DetailField title='Увлеченность изменениями' value={path(['passionForChanges'], interviewFeedback)} />
                <DetailField title='Эффективная коммуникация' value={path(['communication'], interviewFeedback)} />
                <DetailField title='Техники продаж / Привлечение клиентов' value={path(['salesTechniques'], interviewFeedback)} />
            </div>
        )}
    >
        <InfoCircleTwoTone />
    </Popover>
);

class ApplicantDetailModal extends Component {
    openOpd = (openModal, applicant) => {
        openModal(MODAL_TYPES.applicantOpd, {
            applicant,
        });
    }

    renewOpd = (editApplicant, applicant = {}) => {
        editApplicant({
            variables: {
                id: applicant.id,
                values: {
                    birthDate: applicant.birthDate,
                    gender: applicant.gender,
                    contact: {
                        firstName: applicant.contact.firstName,
                        middleName: applicant.contact.middleName,
                        lastName: applicant.contact.lastName,
                        email: applicant.contact.email,
                        phone: applicant.contact.phone,
                        pdaAgreement: applicant.contact.pdaAgreement,
                        communicationsAgreement: applicant.contact.communicationsAgreement,
                        passportId: applicant.contact.passportId,
                        passportDate: applicant.contact.passportDate,
                        passportIssuer: applicant.contact.passportIssuer,
                        address: applicant.contact.address,
                    },
                    educationLevel: applicant.educationLevel,
                    resume: applicant.resume,
                    comment: applicant.comment,
                    responsible: path(['responsible', 'id'], applicant),
                    resetDepersonalization: true,
                    pdaContent: '',
                }
            },
            refetchQueries: APPLICANTS_REFETCH_QUERIES
        })
            .then(() => {
                this.props.client.mutate({
                    mutation: sendPdaNotification,
                    variables: {
                        id: applicant.id,
                    },
                    refetchQueries: APPLICANTS_REFETCH_QUERIES
                })
                    .then(() => {
                        message.success('СОПД успешно отправлено');
                        this.props.close();
                    })
                    .catch(() => message.error('Не удалось отправить СОПД'));
            })
            .catch(() => message.error('Не удалось обновить кандидата'));
    }

    sendOpd = (mutate, id) => {
        mutate({
            variables: {
                id
            },
            refetchQueries: APPLICANTS_REFETCH_QUERIES
        })
            .then(() => {
                message.success('Уведомление СОПД успешно отправлено');
                this.props.close();
            })
            .catch(() => message.error('Не удалось отправить уведомление СОПД'));
    }

    copyOpd(_, result) {
        result ?
            message.success('Ссылка скопирована в буфер обмена') :
            message.error('Не удалось скопировать ссылку');
    }

    cancelOpd(mutate = editApplicant, applicant) {}

    downloadOpd(applicant) {
        const data = applicant.pdaContent;
        const filename = `СОПД-${applicant.contact.lastName}_${applicant.contact.firstName}.html`;

        const blob = new Blob([data], { type: 'text/html' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }

    render() {
        const { modal, params: { applicant = { contact: {} }, history, opd }, user } = this.props;
        const interviewFeedback = path(['interviewFeedback'], applicant);
        const { pdaEnabled } = user;
        const isConsultant = getRole() === CONSULTANT;
        const isAdmin = getRole() === ADMIN;
        const hasOpd = applicant.contact.pdaAgreement || !applicant.pdaKey;

        return <StyledModal
            {...modal}
            title={applicant.depersonalized ? '-' : <FullName item={applicant.contact} />}
            footer={null}>
            <Tabs defaultActiveKey={opd ? 'opd' : history ? 'history' : 'info'}>
                <Tabs.TabPane tab='Информация' key='info'>
                    <Wrapper>
                        <DetailField title='ФИО' value={applicant.depersonalized ? '-' : <FullName item={applicant.contact} />} />
                        <DetailField title='Позиция' value={applicant.hireRequest.position.name} />
                        <DetailField title='Создана' value={<FullName item={applicant.initiator} />} />
                        <DetailField title='Рекрутер' value={<FullName item={applicant.responsible} />} />
                        <DetailField title='Email' value={applicant.contact.email} />
                        <DetailField title='Телефон' value={applicant.contact.phone} />
                        { applicant.birthDate && <DetailField title='Дата рождения' utc value={<DateFormat date={applicant.birthDate} />} />}
                        <DetailField title='Пол' value={path(['label'], find(propEq('value', applicant.gender), GENDERS))} />
                        <DetailField title='Образование' value={path(['label'], find(propEq('value', applicant.educationLevel), EDUCATION))} />
                        { applicant.resume &&
                            <DetailField title='Резюме' value={
                                <a
                                    href={`/api/file/${applicant.resume}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    download
                                >
                                    Скачать
                                </a>
                            } />
                        }
                        <DetailField title='Комментарий' value={applicant.comment} />
                        <Separator />
                        <DetailField title='Статус' value={path(['title'], find(propEq('key', applicant.status), APPLICANTS_TABS))} />
                        <DetailField title='Дата добавления' value={<DateFormat date={applicant.createdAt} />} />
                        { applicant.status === 'attendanceAwaiting' ?
                            applicant.firstWorkingDay && <DetailField title='Дата трудоустройства' value={<DateFormat date={applicant.firstWorkingDay} />} /> :
                            applicant.deadline && <DetailField title='Срок' value={<DeadlineFormat date={applicant.deadline} />} />
                        }
                        { applicant.status === 'supervisorInterview' && applicant.dateInterview &&
                            <DetailField title='Дата интервью' value={<DateFormat date={applicant.dateInterview} utc />} />
                        }
                        <DetailField title='Причина отклонения' value={path(['label'], find(propEq('value', applicant.reasonForRejection), REJECT_REASON))} />
                        <DetailField title='Комментарий к статусу' value={applicant.statusComment} />
                        <DetailField
                            title='Результаты интервью'
                            value={interviewFeedback && (
                                <InterviewResults interviewFeedback={interviewFeedback} />
                            )}
                        />
                    </Wrapper>
                </Tabs.TabPane>
                { pdaEnabled && (isAdmin || isConsultant) ? (
                    <Tabs.TabPane tab='Согласие на ОПД' key='pda'>
                        <Wrapper>
                            <DetailField title='ФИО' value={applicant.depersonalized ? '-' : <FullName item={applicant.contact} />} />
                            <DetailField title='Email' value={applicant.contact.email} />
                            <DetailField title='Телефон' value={applicant.contact.phone} />
                            { !!applicant.pdaKey && applicant.firstPdaSentAt && (
                                <DetailField title='Дата отправки СОПД' utc value={<DateFormat date={applicant.firstPdaSentAt} />} />
                            )}
                            { !hasOpd ? (
                                <Fragment>
                                    { !!applicant.pdaKey && applicant.lastPdaSentAt && (
                                        <DetailField title='Дата последней отправки СОПД' utc value={<DateFormat date={applicant.lastPdaSentAt} />} />
                                    )}
                                    {!applicant.depersonalized && !!applicant.pdaKey && applicant.pdaKeyExpiringAt && (
                                        <DetailField title='Дата истечения СОПД' utc value={<DateFormat date={applicant.pdaKeyExpiringAt} />} />
                                    )}
                                </Fragment>
                            ) : (
                                !!applicant.pdaKey && (
                                    <DetailField title='Дата получения согласия' utc value={<DateFormat date={applicant.lastPdaSentAt} />} />
                                )
                            )}
                            <DetailField title='Статус' value={
                                hasOpd ? (
                                    'Получено'
                                ) : (
                                    applicant.depersonalized
                                        ? applicant.depersonalizationReason === 'rejected' ? 'Отказался дать СОПД' : 'Обезличен'
                                        : 'Не получено'
                                )
                            } />
                            <div>
                                { hasOpd ? (
                                    <div className='btn-line'>
                                        <ModalsContext.Consumer>
                                            { ({ openModal }) =>
                                                <Button onClick={() => this.openOpd(openModal, applicant)}>
                                                    Посмотреть ОПД
                                                </Button>
                                            }
                                        </ModalsContext.Consumer>
                                        <Button onClick={() => this.downloadOpd(applicant)}>
                                            Скачать ОПД
                                        </Button>
                                        {/* isConsultant && (
                                            <Mutation mutation={editApplicant}>
                                                { (mutate, { loading }) =>
                                                    <Button loading={loading} onClick={() => this.cancelOpd(mutate, applicant)}>
                                                        Обезличить
                                                    </Button>
                                                }
                                            </Mutation>
                                        )*/}
                                    </div>
                                ) : applicant.depersonalized ? isConsultant && (
                                    <Fragment>
                                        <DetailField title='Обезличен' value={APPLICANTS_DEPERSONALIZATION_REASONS[applicant.depersonalizationReason || 'notAccepted']} />
                                        <div>
                                            <Mutation mutation={editApplicant}>
                                                { (mutate, { loading: loadingEdit }) =>
                                                    <Button loading={loadingEdit} onClick={() => this.renewOpd(mutate, applicant)}>
                                                        Отправить повторно
                                                    </Button>
                                                }
                                            </Mutation>
                                        </div>
                                    </Fragment>
                                ) : applicant.pdaKey ? isConsultant && (
                                    <div className='btn-line'>
                                        <Mutation mutation={sendPdaNotification}>
                                            { (mutate, { loading }) =>
                                                <Button loading={loading} onClick={() => this.sendOpd(mutate, applicant.id)}>
                                                    Отправить повторно
                                                </Button>
                                            }
                                        </Mutation>
                                        <CopyToClipboard onCopy={this.copyOpd} text={`${window.location.origin}/pda/${applicant.pdaKey}`}>
                                            <Button>
                                                Скопировать ссылку ОПД
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                ) : null}
                                {/*) : isConsultant && (
                                    <Mutation mutation={editApplicant}>
                                        { (mutate, { loading }) =>
                                            {<Button loading={loading} onClick={() => this.cancelOpd(mutate, applicant)}>
                                                Обезличить
                                            </Button>}
                                        }
                                    </Mutation>
                                )}*/}
                            </div>
                        </Wrapper>
                    </Tabs.TabPane>
                ) : null}
                <Tabs.TabPane tab='История' key='history'>
                    <Wrapper>
                        <ApplicantLogs id={applicant.id} />
                    </Wrapper>
                </Tabs.TabPane>
            </Tabs>
        </StyledModal>;
    }
}

export default withUserContext(withApollo(ApplicantDetailModal));
