import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { head, prop, filter, contains } from 'ramda';

import withFormWrapper from '../hocs/withFormWrapper';
import Select from './formComponents/Select';
import { REJECT_REASON, ALLOWED_REJECT_REASONS, REJECT_REASONS } from '../../constants/applicants';
import Textarea from './formComponents/Textarea';
import SubmitButton from './formComponents/SubmitButton';
import FeedbackFields from './FeedbackFields';
import ListenerField from './ListenerField';
import { required } from '../../utils/yup';
import withUserContext from '../hocs/withUserContext';

class ApplicantRejectForm extends Component {
    getRejectReasons = () => {
        const { type, user } = this.props;
        const allowedRejectReasons = ALLOWED_REJECT_REASONS[type] || [];
        const rejectReasons = type === 'supervisorInterview' && user.allowSelfRejectOnInterview ? [...allowedRejectReasons, 'selfByPhone'] : allowedRejectReasons;

        return filter(reason => contains(reason.value, rejectReasons), REJECT_REASON);
    }

    validateComment = value => {
        return this.props.requiredComment ? required(value) : undefined;
    }

    render() {
        const { showInterviewFields, requiredComment, user } = this.props;
        const rejectReasons = this.getRejectReasons();

        return <Fragment>
            { !!rejectReasons.length && (
                <Field
                    name='reason'
                    component={rejectReasons.length === 1 ? () => null : Select}
                    label='Причина'
                    options={rejectReasons}
                    initialValue={prop('value', head(rejectReasons))} />

            )}
            { showInterviewFields &&
                <Fragment>
                    <Field
                        name='reasonOfRejection'
                        component={Select}
                        label='Причина отклонения'
                        options={REJECT_REASONS}
                        required
                        validate={required} />
                    <ListenerField listenFieldName='reason'>
                        { ({ reason }) => reason !== 'selfByPhone' && <FeedbackFields user={user} /> }
                    </ListenerField>
                </Fragment>
            }
            <Field
                name='comment'
                component={Textarea}
                validate={this.validateComment}
                required={requiredComment}
                label='Комментарий' />
            <SubmitButton>
                Отправить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(withUserContext(ApplicantRejectForm), {
    mapPropsToValues: prop('data')
});
