import React, { Component } from 'react';
import { Tabs } from 'antd';

import ApplicantReport from './reports/ApplicantReport';
import ApplicantApprovalStatReport from './reports/ApplicantApprovalStatReport';
import { StyledTabs } from '../admin/Users';
import HireRequestCountStatReport from './reports/HireRequestCountStatReport';
import InterviewFeedbackStatReport from './reports/InterviewFeedbackStatReport';
import SentApplicantsStatReport from './reports/SentApplicantsStatReport';
import PositionExpiringsStat from './reports/PositionExpiringsStat';

export default class Reports extends Component {
    changeTab = key => {
        this.props.history.replace(`/reports/${key}`);
    }

    render() {
        const { report } = this.props.match.params;

        return (
            <StyledTabs animated={false} destroyInactiveTabPane={true} activeKey={report || 'applicants'} onChange={this.changeTab}>
                <Tabs.TabPane tab='Кандидаты' key='applicants'>
                    <ApplicantReport {...this.props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Переход по статусам' key='applicantApprovalStat'>
                    <ApplicantApprovalStatReport {...this.props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Срок закрытия позиции' key='positionExpirationStat'>
                    <PositionExpiringsStat {...this.props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Отчет по количеству заявок/позиций' key='hireRequestCountStat'>
                    <HireRequestCountStatReport {...this.props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Отчет по результатам интервью' key='interviewFeedbackStat'>
                    <InterviewFeedbackStatReport {...this.props} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Отчёт по направленным кандидатам' key='sentApplicantsStat'>
                    <SentApplicantsStatReport {...this.props} />
                </Tabs.TabPane>
            </StyledTabs>
        );
    }
}
