import React, { Component, Fragment } from 'react';
import { contains, find, propEq, pick, path, pathOr, isEmpty, reduce } from 'ramda';
import { Tabs, Button, Tooltip, Popconfirm, message } from 'antd';
import qs from 'qs';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';

import { REJECT_REASON, PDA_FILTERS } from '../../../constants/applicants';
import Table from '../table/Table';
import { getApplicants, getApplicantFilters, removeApplicant } from '../../../queries/applicants';
import UserLink from '../table/UserLink';
import Applicant, { ApplicantOPD } from '../table/Applicant';
import Position from '../table/Position';
import { StyledTabs, Badge } from '../HireRequests';
import { APPLICANTS_TABS, APPLICANTS_TABS_NOANCOR, APPLICANTS_MANAGER_ACTIONS, APPLICANTS_STATUSES, APPLICANTS_STATUS_ICONS, APPLICANTS_STATUS_COLORS } from '../../../constants/applicants';
import { getRole } from '../../../utils/users';
import { CONSULTANT, CLIENT_MANAGER, ADMIN, SUPERVISOR } from '../../../constants/roles';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import DateFormat from '../table/DateFormat';
import DeadlineFormat from '../table/DeadlineFormat';
import ApplicantFilter from '../../forms/filters/ApplicantFilter';
import { getUrlFilter } from '../../../utils/urlParams';
import { getDeadlineClass } from '../../../utils/deadline';
import withUserContext from '../../hocs/withUserContext';
import { StyledTextAction } from '../TimeLineView';
import Route from '../../Route';
import { getToken } from '../../../utils/token';
import { StepForwardOutlined, CalendarOutlined, EditOutlined, InfoCircleOutlined, DeleteOutlined, UserAddOutlined, FileExcelOutlined, SettingOutlined } from '@ant-design/icons';

const filterFields = ['name', 'hireRequest', 'verifier', 'position', 'regionMacro', 'regionClient', 'regionAncor', 'minCreatedAt', 'maxCreatedAt'];

class Applicants extends Component {
    componentDidMount() {
        const { user, location } = this.props;
        const { type } = this.props.match.params;

        if (!user.ancorStagesEnabled && (type === 'last')) {
            this.props.history.replace(`/applicants/${APPLICANTS_TABS_NOANCOR[0].key}${location.search || ''}`);
        }
    }

    getColumns = (disabledEdit) => {
        const { pdaEnabled } = this.props.user;
        const { type } = this.props.match.params;
        const attendanceAwaiting = type === 'attendanceAwaiting';
        const hired = type === 'hired';
        const rejected = type === 'rejected';
        const absence = type === 'absence';
        const inteview = type === 'supervisorInterview';
        const showDateColumn = !hired && !rejected && !absence;
        const showInterviewDate = absence || inteview;
        const manager = getRole() === CLIENT_MANAGER;
        const consultant = getRole() === CONSULTANT;
        const admin = getRole() === ADMIN;
        const supervisor = getRole() === SUPERVISOR;

        return [
            {
                title: 'ФИО',
                dataIndex: 'contact',
                key: 'name',
                render: (item, applicant) => (
                    <Fragment>
                        { applicant.depersonalized ? <Applicant text='-' item={item} applicant={applicant} /> : <Applicant applicant={applicant} item={item} /> }
                        { pdaEnabled && !supervisor && !manager && (
                            <ApplicantOPD item={applicant} />
                        )}
                    </Fragment>
                )
            },
            {
                title: 'Позиция',
                dataIndex: ['hireRequest', 'position', 'name'],
                key: 'position',
                render: (position, item) => <Position position={position} item={item} />
            },
            {
                title: 'Ответственный',
                dataIndex: ['hireRequest', 'verifier'],
                key: 'verifier',
                render: item => <UserLink item={item} />
            },
            {
                title: 'Контакты',
                dataIndex: 'contact',
                key: 'contacts',
                render: contact => (
                    <Fragment>
                        <div>{contact.email}</div>
                        <div>{contact.phone}</div>
                    </Fragment>
                )
            },
            {
                title: 'Дата добавления',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateFormat date={date} />
            },
            showDateColumn && {
                title: attendanceAwaiting ? 'Дата Т/У' : 'Срок',
                dataIndex: attendanceAwaiting ? 'firstWorkingDay' : 'deadline',
                key: 'date',
                width: 100,
                render: date => <DeadlineFormat date={date} />
            },
            showInterviewDate && {
                title: 'Дата интервью',
                dataIndex: 'dateInterview',
                key: 'dateInterview',
                width: 100,
                render: date => <DateFormat date={date} utc />
            },
            rejected && {
                title: 'Причина отклонения',
                dataIndex: 'reasonForRejection',
                key: 'reasonForRejection',
                render: value => path(['label'], find(propEq('value', value), REJECT_REASON))
            },
            type === 'last' && ({
                title: 'Статус',
                dataIndex: 'status',
                key: 'type',
                render: status => (
                    <StyledTextAction className='nowrap' normal color={APPLICANTS_STATUS_COLORS[status]}>
                        {APPLICANTS_STATUS_ICONS[status]} {APPLICANTS_STATUSES[status]}
                    </StyledTextAction>
                ),
            }),
            {
                key: 'actions',
                align: 'center',
                render: (item, _, index) => {
                    const actions = manager && !this.props.user.applicantStatusManagementEnabled ? APPLICANTS_MANAGER_ACTIONS : APPLICANTS_TABS;
                    const { next = [], rejectable } = find(propEq('key', item.status), actions) || {};

                    return <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <Mutation
                                mutation={removeApplicant}
                                variables={{ id: item.id }}
                                refetchQueries={['applicants', 'applicantFilters']}
                                onCompleted={() => message.success('Кандидат был успешно удален')}
                                onError={() => message.error('Не удалось удалить кандидата')}>
                                { (mutate, { loading }) =>
                                    <Button.Group>
                                        { !disabledEdit && (rejectable || !!next.length) &&
                                            <Tooltip title='Изменить статус кандидата'>
                                                <Button
                                                    id={`applicant-status-change-btn-${index}`}
                                                    type='primary'
                                                    icon={<StepForwardOutlined />}
                                                    disabled={loading}
                                                    onClick={() => openModal(MODAL_TYPES.applicantChangeStatus, {
                                                        next,
                                                        item
                                                    })} />
                                            </Tooltip>
                                        }
                                        { consultant && attendanceAwaiting &&
                                            <Tooltip title='Дата трудоустройства'>
                                                <Button
                                                    icon={<CalendarOutlined />}
                                                    disabled={loading}
                                                    onClick={() => openModal(MODAL_TYPES.firstWorkingDay, { id: item.id, date: item.firstWorkingDay })} />
                                            </Tooltip>
                                        }
                                        { inteview && (consultant || getRole() === CLIENT_MANAGER) &&
                                            <Tooltip title='Назначить дату интервью'>
                                                <Button
                                                    icon={<CalendarOutlined />}
                                                    disabled={loading}
                                                    onClick={() => openModal(MODAL_TYPES.applicantInterviewDate, item)} />
                                            </Tooltip>
                                        }
                                        { consultant &&
                                            <Tooltip title='Редактировать'>
                                                <Button
                                                    id={`applicant-edit-btn-${index}`}
                                                    icon={<EditOutlined />}
                                                    disabled={loading}
                                                    onClick={() => openModal(MODAL_TYPES.applicantForm, {
                                                        applicant: item,
                                                    })} />
                                            </Tooltip>
                                        }
                                        <Tooltip title='История'>
                                            <Button
                                                icon={<InfoCircleOutlined />}
                                                disabled={loading}
                                                onClick={() => openModal(MODAL_TYPES.applicantDetail, {
                                                    applicant: item,
                                                    history: true
                                                })} />
                                        </Tooltip>
                                        { (consultant || admin) &&
                                            ( loading ? <Button
                                                icon={<DeleteOutlined />}
                                                danger
                                                loading={loading} /> :
                                                <Popconfirm
                                                    title='Вы уверены, что хотите удалить кандидата?'
                                                    onConfirm={mutate}
                                                    okText='Да'
                                                    cancelText='Нет'>
                                                    <Button
                                                        id={`applicant-delete-btn-${index}`}
                                                        icon={<DeleteOutlined />}
                                                        danger />
                                                </Popconfirm>
                                            )
                                        }
                                    </Button.Group>
                                }
                            </Mutation>
                        }
                    </ModalsContext.Consumer>;
                }
            }
        ];
    }

    getExportUrl = () => {
        const { type } = this.props.match.params;

        const searchPath = qs.stringify({
            access_token: getToken(),
            filter: JSON.stringify({
                ...pick(filterFields, this.getFilter()),
                ...(type === 'last' ? {
                    maxDeadlineDate: moment.utc().add(2, 'days').format(),
                    statuses: ['securityApproval', 'registration', 'attendanceAwaiting'],
                } : type === 'all' ? (
                    pick(filterFields, this.getFilter())
                ) : {
                    status: type,
                })
            }),
            sorting: JSON.stringify({
                field: 'deadline',
                order: 1,
            })
        }, { addQueryPrefix: true });

        return `/api/applicant/export${searchPath}`;
    }

    renderToolbarButtons = () => {
        const { type } = this.props.match.params;

        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Button.Group>
                    { contains(type, ['supervisorApproval', 'last']) && getRole() === CONSULTANT &&
                        <Tooltip title='Добавить кандидата'>
                            <Button
                                id='applicant-add-btn'
                                type='primary'
                                icon={<UserAddOutlined />}
                                onClick={() => openModal(MODAL_TYPES.applicantForm)} />
                        </Tooltip>
                    }
                    <Tooltip title='Экспорт'>
                        <Button
                            icon={<FileExcelOutlined />}
                            href={this.getExportUrl()}
                            target='_blank'
                            rel='noopener noreferrer'
                            download />
                    </Tooltip>
                    { getRole() === ADMIN &&
                        <Button
                            icon={<SettingOutlined />}
                            onClick={() => openModal(MODAL_TYPES.deadline, {
                                type: 'applicant'
                            })} />
                    }
                </Button.Group>
            }
        </ModalsContext.Consumer>;
    }

    mapFilter = filters => {
        return {
            ...filters,
            ...(PDA_FILTERS[filters.pdaFilter] || {}),
            pdaFilter: undefined,
        };
    }

    getFilter = () => {
        return this.mapFilter(getUrlFilter(this.props.location));
    }

    onChange = key => {
        const filter = pick(filterFields, this.getFilter());
        const searchPath = isEmpty(filter) ? '' : qs.stringify({
            filter: JSON.stringify(filter)
        }, { addQueryPrefix: true, strictNullHandling: true });

        this.props.history.push(`/applicants/${key}${searchPath}`);
    }

    renderTabTitle = (title, data, key) => {
        const count = key === 'all' ? (
            reduce((count, status) => count + status.count, 0, pathOr([], ['applicantFilters', 'statuses'], data))
        ) : (
            path(['count'], find(propEq('status', key), pathOr([], ['applicantFilters', 'statuses'], data)))
        );

        return <span>{ title } { count ? <Badge>{ count }</Badge> : null }</span>;
    }

    renderTabs = (table, data) => {
        const { type } = this.props.match.params;
        const { ancorStagesEnabled } = this.props.user;

        return <StyledTabs
            onChange={this.onChange}
            activeKey={type}
            animated={{ tabPane: false }}>
            { (ancorStagesEnabled ? APPLICANTS_TABS : APPLICANTS_TABS_NOANCOR).map(tab =>
                <Tabs.TabPane
                    tab={this.renderTabTitle(tab.title, data, tab.key)}
                    key={tab.key}>
                    <Route path={`/applicants/${tab.key}`} render={() => table} />
                </Tabs.TabPane>
            )}
            <Tabs.TabPane
                    tab={this.renderTabTitle('Все', data, 'all')}
                    key={'all'}>
                <Route path={`/applicants/all`} render={() => table} />
            </Tabs.TabPane>
        </StyledTabs>
    }

    renderTable = ({ data }) => {
        const { match: { params: { type }}, user } = this.props;

        const isManager = getRole() === CLIENT_MANAGER;
        const isSupervisor = getRole() === SUPERVISOR;
        const regionMacro = isManager ? (
            path(['regionMacro', 'id'], this.props.user)
        ) : undefined;
        const disableEdit = isManager ? !regionMacro && !user.applicantStatusManagementEnabled :
            isSupervisor ? !user.applicantStatusManagementEnabled : false;

        const defaultFilter = {
            regionMacro,
        };

        return <Table
            name='applicants'
            query={getApplicants}
            rowClassName={getDeadlineClass}
            variables={{
                sorting: {
                    field: 'deadline',
                    order: 1,
                },
            }}
            columns={this.getColumns(disableEdit).filter(Boolean)}
            toolbarButtons={this.renderToolbarButtons()}
            defaultFilter={defaultFilter}
            staticFilter={type === 'last' ? {
                maxDeadlineDate: moment.utc().add(2, 'days').format(),
                statuses: ['securityApproval', 'registration', 'attendanceAwaiting'],
            } : type === 'all' ? {} : {
                status: type,
            }}
            FilterForm={ApplicantFilter}
            mapFilter={this.mapFilter}
            customWrapper={table => this.renderTabs(table, data)} />;
    }

    render() {
        return <Query
            query={getApplicantFilters}
            variables={{
                filter: pick(filterFields, this.getFilter())
            }}>
            { this.renderTable }
        </Query>
    }
}

export default withUserContext(Applicants);
