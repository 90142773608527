import gql from 'graphql-tag';

export const User = {
    fragments: {
        fields: gql`
            fragment userFields on User {
                id,
                username,
                lastName,
                firstName,
                middleName,
                phone,
                enabled,
                activated,
                applicantStatusManagementEnabled,
                allowSelfRejectOnInterview,
                pdaEnabled,
                ancorStagesEnabled,

                ... on ClientManager {
                    allowHireRequestApproval,
                    finalApprover,
                    approver,
                    regionMacro {
                        id,
                        name
                    }
                }

                ... on Supervisor {
                    regionClient {
                        id,
                        name
                    },
                    regionAncor {
                        id,
                        name
                    },
                    regionMacro {
                        id,
                        name
                    },
                    hireRequestManagementEnabled
                }
            }
        `,
    }
};

export const register = gql(`
    mutation register($values: SupervisorInput!) {
        register(registration: $values) {
            accessToken
        }
    }
`);

export const requestResetPassword = gql(`
    mutation requestResetPassword($username: String!) {
        requestResetPassword(username: $username) {
            username
        }
    }
`);

export const resetPassword = gql(`
    mutation resetPassword($values: ResetPasswordInput!) {
        resetPassword(request: $values) {
            accessToken
        }
    }
`);

export const login = gql(`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            accessToken
        }
    }
`);

export const getUser = gql`
    query me {
        me {
            ...userFields
        }
    }

    ${User.fragments.fields}
`;

export const checkActivation = gql(`
    query checkActivation($token: String!) {
        checkActivation(token: $token) {
            id,
            username
        }
    }
`);
export const activate = gql(`
    mutation activate($values: ActivationInput!) {
        activate(activation: $values) {
            accessToken
        }
    }
`);

export const getAuthLog = gql(`
    query authLog($filter: LoginAttemptFilterInput, $pagination: PaginationInput, $sorting: AuthLogSortInput) {
        authLog(filter: $filter, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                username,
                ip,
                userAgent,
                enabled,
                banned,
                passwordCorrect,
                succeed,
                createdAt
            },
            _meta {
                count
            }
        }
    }
`);

export const checkResetPasswordRequest = gql(`
    query checkResetPasswordRequest($token: String!) {
        checkResetPasswordRequest(token: $token) {
            id,
            expiredAt
        }
    }
`);
