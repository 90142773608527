import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { pathOr, find, propEq, pick } from 'ramda';
import { Query } from 'react-apollo';

import { LINES_PER_PAGE } from '../../../constants/lists';
import { getApplicants } from '../../../queries/applicants';
import { APPLICANTS_ALL_STATUSES, APPLICANTS_TABS } from '../../../constants/applicants';
import EmptyList from './EmptyList';
import UserItem from './UserItem';
import ApplicantsRecuestFilter from "../../forms/filters/ApplicantsRecuestFilter";
import { getUrlFilter } from '../../../utils/urlParams';

const filterFields = ['hireRequest', 'minCreatedAt', 'maxCreatedAt'];

export default class Users extends Component {
    state = {
        offset: 0,
        loading: false
    };

    getVariables = (offset = 0) => {
        const filters = getUrlFilter(this.props.location);

        return {
            pagination: {
                offset,
                limit: LINES_PER_PAGE
            },
            sorting: {
                field: 'status',
                order: 1,
            },
            filter: {
                ...pick(filterFields, filters),
                statuses: filters.type === 'all' ?
                    APPLICANTS_ALL_STATUSES :
                    ['supervisorApproval', 'supervisorInterview', 'securityApproval', 'registration', 'attendanceAwaiting']
            }
        };
    }

    loadMore = (page, fetchMore) => {
        const offset = page * LINES_PER_PAGE;

        this.setState({ loading: true, offset }, () => {
            fetchMore({
                variables: this.getVariables(offset),
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return prev;
                    }

                    return {
                        applicants: {
                            ...fetchMoreResult.applicants,
                            items: pathOr([], ['applicants', 'items'], prev).concat(fetchMoreResult.applicants.items)
                        }
                    };
                }
            }).then(() => this.setState({ loading: false }));
        });
    }

    renderList = (props) => {
        const { fetchMore, data } = props;
        const items = pathOr([], ['applicants', 'items'], data);
        const count = pathOr(0, ['applicants', '_meta', 'count'], data);

        if (items.length === 0) {
            return (
                <EmptyList items={items} {...props} />
            );
        }

        return <div className="list-box">
            <InfiniteScroll
                pageStart={0}
                loadMore={page => this.loadMore(page, fetchMore)}
                hasMore={count > this.state.offset + LINES_PER_PAGE && !this.state.loading}>
                { items.map(item =>
                    <UserItem
                        key={item.id}
                        item={item}
                        title={find(propEq('key', item.status), APPLICANTS_TABS).title}
                        onClick={() => this.props.history.push(`/user/${item.id}`)}
                    />
                )}
            </InfiniteScroll>
        </div>;
    }

    render() {
        return <div>
            <ApplicantsRecuestFilter filter={{
                type: 'active',
                ...getUrlFilter(this.props.location)
            }} defaultFilter={{ type: 'active' }} />
            <Query
                query={getApplicants}
                variables={this.getVariables()}>
                { this.renderList }
            </Query>
        </div>;
    }
}
