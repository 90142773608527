import Rollbar from 'rollbar';
import { merge, always } from 'ramda';
import jwt from 'jwt-decode';

import { getToken } from './token';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

const initRollbar = function (options) {
    options = merge(defaults, options);

    window._rollbarConfig = {
        enabled: true,
        accessToken: options.token,
        captureUncaught: true,
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        transform: payload => {
            const token = getToken();

            try {
                payload.person = token ? jwt(token) : null;
            } catch(e) {
                payload.person = null;
            }
        }
    };

    Rollbar.init(window._rollbarConfig);
}

export const pushRollbarError = (error, info = null) => {
    if (window._rollbarConfig) {
        Rollbar.configure({
            payload: {
                info
            }
        });

        window.rollbar.error(error);
    } else {
        // eslint-disable-next-line
        console.error(error, info);
    }
}

export default initRollbar;
