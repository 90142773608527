import React, { Component } from 'react';
import { InputNumber } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { detector } from '../../../utils/mobile';

class NumberInput extends Component {
    onChange = value => {
        if (/^\d*$/.test(value)) {
            this.props.onChange(value);
        }
    }

    render() {
        const { input: { value, name }, placeholder, step, formatter } = this.props;

        return <InputNumber
            name={name}
            type={detector.mobile() ? 'number' : 'text'}
            value={value}
            onChange={this.onChange}
            placeholder={placeholder}
            step={step}
            formatter={v => {
                const parsedValue = /^\d*$/.test(v) ? v : value;
                return !detector.mobile() && formatter ? formatter(parsedValue) : parsedValue
            }}
            min={0} />;
    }
}

export default withFieldWrapper(NumberInput);
