import React, { Component } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { filter, contains } from 'ramda';

import ClientManagers from './ClientManagers';
import Consultants from './Consultants';
import Admins from './Admins';
import Supervisors from './Supervisors';
import { ADMIN, CONSULTANT } from '../../../constants/roles';
import { getRole } from '../../../utils/users';
import Route from '../../Route';

const USER_ITEMS = [
    { title: 'Администраторы', key: 'admins', component: Admins, roles: [ADMIN, CONSULTANT] },
    { title: 'Супервайзеры', key: 'supervisors', component: Supervisors },
    { title: 'Менеджеры', key: 'managers', component: ClientManagers, roles: [ADMIN, CONSULTANT] },
    { title: 'Рекрутеры', key: 'consultants', component: Consultants, roles: [ADMIN, CONSULTANT] }
];

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        margin: 0;
    }
`;

export default class Users extends Component {
    onChange = key => {
        this.props.history.push(`/users/${key}`);
    }

    render() {
        const { params: { type }} = this.props.match;
        const tabs = filter(tab => !tab.roles || contains(getRole(), tab.roles), USER_ITEMS);

        return <StyledTabs
            onChange={this.onChange}
            activeKey={type}
            animated={{ tabPane: false }}>
            { tabs.map(item =>
                <Tabs.TabPane key={item.key} tab={item.title}>
                    <Route path={`/users/${item.key}`} component={item.component} />
                </Tabs.TabPane>
            )}
        </StyledTabs>;
    }
}
