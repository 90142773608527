import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';

import { getRegionsClient } from '../../../queries/regions';
import withFilterForm from '../../hocs/withFilterForm';
import SearchInput from '../formComponents/SearchInput';
import Cascader from '../formComponents/Cascader';
import { getRegionsClientCascaderData } from '../../../utils/requests';

class RegionAncorFilter extends Component {
    render() {
        return <Row>
            <Col span={12}>
                <Field
                    name='name'
                    component={SearchInput}
                    placeholder='Название' />
            </Col>
            <Col span={12}>
                <Query query={getRegionsClient}>
                    { ({ data }) =>
                        <Field
                            name='regionMacro'
                            additionalNames={['regionClient']}
                            component={Cascader}
                            placeholder='Макрорегион/Регион'
                            options={getRegionsClientCascaderData(data)} />
                    }
                </Query>
            </Col>
        </Row>
    }
}

export default withFilterForm(RegionAncorFilter);
