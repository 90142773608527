import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Spin, Button } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import ResetForm from '../forms/ResetForm';
import { resetPassword, checkResetPasswordRequest } from '../../queries/authorization';
import UserContext from '../../contexts/UserContext';
import { setToken } from '../../utils/token';
import { DEFAULT_RESET_ERROR } from '../../constants/errors';

const InvalidBlock = styled.div`
    text-align: center;
    button {
        margin-top: 10px;
    }
`;

export default class Reset extends Component {
    onSuccess = ({ resetPassword }, getUser) => {
        setToken(resetPassword.accessToken);
        getUser();
    }

    renderContent = ({ data, loading, error }) => {
        const { token } = this.props.match.params;

        return loading ? <Spin /> :
            (error || moment.utc().isSameOrAfter(data.checkResetPasswordRequest.expiredAt)) ?
            <InvalidBlock>
                <div>Ссылка устарела. Авторизуйтесь, если пароль уже был установлен</div>
                <Button
                    type='primary'
                    onClick={() => this.props.history.push('/')}>
                    Войти
                </Button>
            </InvalidBlock> :
            <UserContext.Consumer>
                { ({ getUser }) =>
                    <ResetForm
                        mutation={resetPassword}
                        onSuccess={data => this.onSuccess(data, getUser)}
                        defaultError={DEFAULT_RESET_ERROR}
                        token={token} />
                }
            </UserContext.Consumer>;
    }

    render() {
        const { token } = this.props.match.params;

        return <Query
            query={checkResetPasswordRequest}
            variables={{ token }}>
            { this.renderContent }
        </Query>;
    }
}
