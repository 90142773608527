import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { path } from 'ramda';

import UserContext from '../../../contexts/UserContext';

export default class Profile extends Component {
    renderProfile = ({ user, logout }) => {
        return <Fragment>
            <div className="item box">
                <div className="type-box type-blue">
                    <i className="icon-worker"></i>
                    <span className="name">супервайзер</span>
                </div>
                <div className="title-box">
                    <div className="title">
                        { user.lastName } { user.firstName } { user.middleName }
                    </div>
                    <div className="sub-title">{ path(['regionClient', 'name'], user) }</div>
                </div>
            </div>
            <div className="controls contact">
                { user.phone && <a href={`tel:${user.phone}`} className="ant-btn btn-default"><i className="icon-phone"></i>{ user.phone }</a> }
                <a href={`mailto:${user.username}`} className="ant-btn btn-default"><i className="icon-mail"></i>{ user.username }</a>
            </div>
            <div className="controls">
                {/* <Button type='primary'>Редактировать</Button> */}
                <Button className='btn-default' onClick={logout}>Выйти</Button>
            </div>
        </Fragment>;
    }

    render() {
        return <UserContext.Consumer>
            { this.renderProfile }
        </UserContext.Consumer>;
    }
}
