import React, { Component } from 'react';
import { message } from 'antd';

import { editPosition, createPosition } from '../../queries/positions';
import PositionForm from '../forms/PositionForm';
import Modal from './Modal';

export default class PositionModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Позиция была успешно ${params ? 'изменена' : 'добавлена'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} позицию`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={`${ params ? 'Редактировать' : 'Добавить'} позицию`}
            footer={null}>
            <PositionForm
                mutation={params ? editPosition : createPosition}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['positions']}
                position={params} />
        </Modal>
    }
}
