import * as MobileDetect from 'mobile-detect';
import { path } from 'ramda';

import { getRole } from './users';
import { SUPERVISOR } from '../constants/roles';

export const detector = new MobileDetect(path(['navigator', 'userAgent'], window));

export const needNativeInputs = () => (
    detector.mobile() && getRole() === SUPERVISOR
);
