import React, { Component } from 'react';
import { contains } from 'ramda';

import { APPLICANT_LOG_STATUSES, APPLICANT_CHANGE_STATUS, APPLICANTS_STATUSES } from '../../../constants/applicants';
import { applicantDeadlineLog } from '../../../queries/applicants';
import Table from '../table/Table';
import DateFormat from '../table/DateFormat';
import DeadlineFormat from '../table/DeadlineFormat';
import UserLink from '../table/UserLink';
import Applicant from '../table/Applicant';
import Position from '../table/Position';
import { ArrowRightOutlined } from '@ant-design/icons';

export default class ApplicantsDeadline extends Component {
    getColumns = () => {
        return [
            {
                title: 'Кандидат',
                dataIndex: ['data', 'contact'],
                key: 'name',
                render: (item, { applicant }) => <Applicant applicant={applicant} item={item} />,
            },
            {
                title: 'Заявка',
                dataIndex: ['hireRequest', 'position', 'name'],
                key: 'hireRequest',
                render: (position, item) => <Position position={position} item={item} />
            },
            {
                title: 'Действие',
                dataIndex: 'type',
                key: 'type',
                render: (type, { data = {} }) => type === APPLICANT_CHANGE_STATUS ?
                    <span>{ APPLICANTS_STATUSES[data.previousStatus] } <ArrowRightOutlined /> { APPLICANTS_STATUSES[data.status] }</span> :
                    APPLICANT_LOG_STATUSES[type],
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                key: 'date',
                width: 100,
                render: date => <DateFormat date={date} utc />
            },
            {
                title: 'Срок',
                dataIndex: ['data', 'previousDeadline'],
                key: 'previousDeadline',
                width: 100,
                render: date => <DeadlineFormat date={date} />
            },
            {
                title: 'Ответственный',
                dataIndex: ['hireRequest', 'verifier'],
                key: 'initiator',
                render: (verifier, { data = {}, initiator }) => contains(data.previousStatus, ['supervisorApproval', 'supervisorInterview']) ?
                    <UserLink item={verifier} /> :
                    <UserLink item={initiator} />
            },
        ];
    }

    render() {
        return <Table
            name='applicantDeadlineLog'
            query={applicantDeadlineLog}
            columns={this.getColumns()}
            variables={{
                sorting: {
                    field: 'date',
                    order: -1
                }
            }}
        />;
    }
}
