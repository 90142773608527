import React, { Fragment } from 'react';

import UserEmail from './UserEmail';

const Contacts = ({ email, phone, activated }) =>
    <Fragment>
        <div><UserEmail email={email} activated={activated} /></div>
        <div>{ phone }</div>
    </Fragment>;

export default Contacts;
