import React, { Component } from 'react';
import { message } from 'antd';

import Modal from './Modal';
import RegionMacroForm from '../forms/RegionMacroForm';
import { editRegionMacro, createRegionMacro } from '../../queries/regions';

export default class RegionMacroModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Макрорегион был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} макрорегион`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать макрорегион' : 'Добавить макрорегион'}
            footer={null}>
            <RegionMacroForm
                mutation={params ? editRegionMacro : createRegionMacro}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['regionsMacro']}
                item={params} />
        </Modal>;
    }
}
