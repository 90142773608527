import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { path, pathOr } from 'ramda';
import * as yup from 'yup';
import { Query } from 'react-apollo';

import Input from './formComponents/Input';
import Select from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { getRegionsClient, getRegionsMacro } from '../../queries/regions';
import Switch from './formComponents/Switch';
import ListenerField from './ListenerField';

const validationSchema = yup.object().shape({
    id: yup.string(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    username: yup.string().email().required(),
    phone: yup.string().nullable().phone(),
});

export const labelCol = {
    xs: { span: 24 },
    sm: { span: 9 },
};

export const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 15 },
};

class ClientManagerForm extends Component {
    onChangeRegionMacro = () => {
        this.props.form.change('regionClient', null);
    }

    render() {
        return <div>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='username'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                required />
            <Field
                name='phone'
                component={Input}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Query
                query={getRegionsMacro}
            >
                { ({ data, loading }) =>
                    <Field
                        name='regionMacro'
                        component={Select}
                        label='Макрорегион'
                        options={pathOr([], ['regionsMacro', 'items'], data)}
                        onChange={this.onChangeRegionMacro}
                        loading={loading}
                        namePath='name'
                        valuePath='id'
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        allowClear
                    />
                }
            </Query>
            <ListenerField listenFieldName='regionMacro'>
                { ({ regionMacro }) =>
                    regionMacro &&
                        <Query
                            query={getRegionsClient}
                            variables={{
                                filter: {
                                    regionMacro
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='regionClient'
                                    component={Select}
                                    label='Регион'
                                    loading={loading}
                                    options={pathOr([], ['regionsClient', 'items'], data)}
                                    namePath='name'
                                    valuePath='id'
                                    allowClear
                                    wrapperCol={wrapperCol}
                                    labelCol={labelCol} />
                            }
                        </Query>
                }
            </ListenerField>
            <Field
                name='allowHireRequestApproval'
                component={Switch}
                label='Согласование заявок'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='approver'
                component={Switch}
                label='Первое согласование'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='finalApprover'
                component={Switch}
                label='Финальное согласование'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='applicantStatusManagementEnabled'
                component={Switch}
                label='Управление статусами кандидатов'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>
    }
}

export default withFormWrapper(ClientManagerForm, {
    validationSchema,
    mapPropsToValues: ({ user }) => user ? ({
        ...user,
        regionMacro: path(['regionMacro', 'id'], user),
        regionClient: path(['regionClient', 'id'], user),
    }) : null
});
