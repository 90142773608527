import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Query } from 'react-apollo';
import { pathOr, path } from 'ramda';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import SubmitButton from './formComponents/SubmitButton';
import { getRegionsClient } from '../../queries/regions';

const validationSchema = yup.object().shape({
    username: yup.string().email().required(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    phone: yup.string().phone().nullable(),
    regionClient: yup.string().required()
});

class RegistrationForm extends Component {
    onChangeregionClient = (value, data) => {
        const { change } = this.props.form;

        change('regionMacro', path(['regionMacro', 'id'], data));
    }

    render() {
        return <Fragment>
            <Field
                name='username'
                component={Input}
                placeholder='Email'
                htmlType='email' />
            <Field
                name='lastName'
                component={Input}
                placeholder='Фамилия' />
            <Field
                name='firstName'
                component={Input}
                placeholder='Имя' />
            <Field
                name='middleName'
                component={Input}
                placeholder='Отчество' />
            <Field
                name='phone'
                component={Input}
                placeholder='Телефон' />
             <Query
                query={getRegionsClient}>
                { ({ data, loading }) =>
                    <Field
                        name='regionClient'
                        component={Select}
                        placeholder='Регион'
                        loading={loading}
                        options={pathOr([], ['regionsClient', 'items'], data)}
                        namePath='name'
                        valuePath='id'
                        allowClear
                        onChange={this.onChangeregionClient} />
                }
            </Query>
            <Field
                name='regionMacro'
                component={() => null} />
            <div className='controls'>
                <SubmitButton>
                    Далее
                </SubmitButton>
            </div>
        </Fragment>;
    }
}

export default withFormWrapper(RegistrationForm, {
    validationSchema
});
