import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { find, propEq, propOr, pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { AuditOutlined, FileSearchOutlined, FileSyncOutlined } from '@ant-design/icons';

import { getHireRequestFilters } from '../../../queries/requests';
import DailyReport from '../consultant/reports/DailyReport';
import withUserContext from '../../hocs/withUserContext';

const BgWrap = styled.div`
    background-color: #E6ECF6;
`;

const WidgetRow = styled(Row)`
    display: flex;
    color: #fff;
    align-items: stretch;
`;
const WidgetBox = styled(Col)`
    color: #fff;
`;

const WidgetBody = styled(Link)`
    display: block;
    height: 100%;
    padding: 16px;
    color: #fff;

    &:hover {
        color: #fff;
    }
`;

const WidgetHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: srtretch;
    font-size: 2.2rem;
    i{
        position: relative;
        top: 8px;
    }
`;

const WidgetLabel = styled.div`
    font-weight: bold;
`;

const Count = styled.div`
    display: flow-root;
`;

const ChartsBox = styled.div`
     background-color: #fff;
     margin-top: 30px;
     padding: 16px;
`;

class Dashboard extends Component {
    getCount(data, status) {
        const statuses = pathOr([], ['hireRequestFilters', 'statuses'], data);
        const requestStatus = find(propEq('status', status), statuses);
        return propOr(0, 'count', requestStatus);
    }

    render() {
        const { ancorStagesEnabled } = this.props.user;

        return <BgWrap>
            <Query query={getHireRequestFilters}>
                {({ data }) => (
                    <WidgetRow gutter={10}>
                        <WidgetBox xs={ancorStagesEnabled ? 8 : 12}>
                            <WidgetBody to={ancorStagesEnabled ? '/requests/ancorApproval' : '/requests/clientApproval'} style={{ backgroundColor: '#33C787' }}>
                                <WidgetHead>
                                    <Count>
                                        {this.getCount(data, ancorStagesEnabled ? 'ancorApproval' : 'clientApproval')}
                                    </Count>
                                    <AuditOutlined />
                                </WidgetHead>
                                <WidgetLabel>Количество заявок на согласовании</WidgetLabel>
                            </WidgetBody>
                        </WidgetBox>
                        <WidgetBox xs={ancorStagesEnabled ? 8: 12}>
                            <WidgetBody to='/requests/inwork' style={{ backgroundColor: '#1990FF' }}>
                                <WidgetHead>
                                    <Count>
                                        {this.getCount(data, 'inwork')}
                                    </Count>
                                    <FileSearchOutlined />
                                </WidgetHead>
                                <WidgetLabel>Количество заявок в работе</WidgetLabel>
                            </WidgetBody>
                        </WidgetBox>
                        { ancorStagesEnabled && (
                            <WidgetBox xs={8}>
                                <WidgetBody to='/requests/ancorAwaiting' style={{ backgroundColor: '#F5C942' }}>
                                    <WidgetHead>
                                        <Count>
                                            {this.getCount(data, 'clientApproval')}
                                        </Count>
                                        <FileSyncOutlined />
                                    </WidgetHead>
                                    <WidgetLabel>Количество заявок в ожидании</WidgetLabel>
                                </WidgetBody>
                            </WidgetBox>
                        )}
                    </WidgetRow>
                )}
            </Query>
            <ChartsBox>
                <DailyReport />
            </ChartsBox>
        </BgWrap>
    }
}

export default withUserContext(Dashboard);
