const validationLocale = {
    mixed: {
        required: 'Это поле обязательно'
    },
    string: {
        email: 'Неверный формат email'
    },
    number: {
        positive: 'Число должно быть больше 0',
    },
};

export default validationLocale;
