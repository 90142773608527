import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import UserContext from '../../contexts/UserContext';
import { getUser } from '../../queries/authorization';
import { setToken, getToken, setOnChangeHandler } from '../../utils/token';

const withUser = (WrappedComponent, addUserListeners) => {
    class User extends Component {
        state = {
            user: null,
            authenticating: true
        };

        async componentDidMount() {
            const token = getToken();

            if (token) {
                this.getUser();
            } else {
                this.setState({ authenticating: false });
            }

            if (addUserListeners) {
                window.addEventListener('storage', this.handleTokenChange);
                setOnChangeHandler(this.onTokenChange);
            }
        }

        componentWillUnmount() {
            if (addUserListeners) {
                window.removeEventListener('storage', this.handleTokenChange);
                setOnChangeHandler();
            }
        }

        handleTokenChange = event => {
            if (event.key === 'token') {
                this.onTokenChange(event.newValue, event.oldValue);
            }
        }

        onTokenChange = (newToken, oldToken) => {
            if (!newToken) {
                this.clearUser();
            } else if (newToken !== oldToken) {
                this.getUser();
            }
        }

        getUser = async () => {
            if (!this.state.user && !this.state.authenticating) {
                this.setState({ authenticating: true });
            }

            try {
                const data = await this.props.client.query({
                    query: getUser
                });

                this.setState({ user: data.data.me, authenticating: false });
            } catch (e) {
                this.logout();
            }
        };

        logout = () => {
            setToken(null);
        };

        clearUser = () => {
            if (this.state.user || this.state.authenticating) {
                this.setState({ user: null, authenticating: false });
                this.props.client.resetStore();
            }
        };

        render() {
            const { user, authenticating } = this.state;

            return <UserContext.Provider value={{
                user,
                getUser: this.getUser,
                logout: this.logout
            }}>
                <WrappedComponent
                    {...this.props}
                    user={user}
                    authenticating={authenticating}
                    logout={this.logout} />
            </UserContext.Provider>
        }
    }

    return withApollo(User);
}

export default withUser;
