
import React, { Fragment } from 'react';

import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import { LinkWrapper } from '../table/FullName';
import { HireRequestSmall } from '../HireRequestSelectSmall';

const Position = ({ position, item, hideSmall = false }) => (
    <ModalsContext.Consumer>
        { ({ openModal }) =>
            <Fragment>
                <div>
                    <LinkWrapper onClick={() => openModal(MODAL_TYPES.requestView, { request: item.hireRequest })}>
                        { position }
                    </LinkWrapper>
                </div>
                { !hideSmall && <small>{ HireRequestSmall(item.hireRequest) }</small>}
            </Fragment>
        }
    </ModalsContext.Consumer>
);

export default Position;
