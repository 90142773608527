import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class SearchInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.input.value
        };
    }

    onChange = e => this.setState({ value: e.target.value });

    render() {
        const { placeholder, onChange } = this.props;

        return <Input.Search
            value={this.state.value}
            placeholder={placeholder}
            onSearch={onChange}
            onChange={this.onChange} />
    }
}

export default withFieldWrapper(SearchInput);
