import React, { Component } from 'react';
import { message } from 'antd';

import SupervisorForm from '../forms/SupervisorForm';
import { createSupervisor, editSupervisor } from '../../queries/supervisors';
import Modal from './Modal';

export default class SupervisorModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Супервайзер был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} супервайзера`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={`${params ? 'Редактировать' : 'Добавить'} супервайзера`}
            footer={null}>
            <SupervisorForm
                mutation={params ? editSupervisor : createSupervisor}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['supervisors']}
                supervisor={params} />
        </Modal>
    }
}
