import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withApollo } from 'react-apollo';
import {Row, Col, Button} from 'antd';
import { withRouter } from 'react-router-dom';
import { FileExcelOutlined } from '@ant-design/icons';
import qs from 'qs';
import { pick } from 'ramda';

import withFilterForm from '../../hocs/withFilterForm';
import Select, { LazyLoadSelect } from '../formComponents/Select';
import { APPLICANTS_ALL_STATUSES, SUPERVISOR_APPLICANTS_STATUSES } from '../../../constants/applicants';
import withUserContext from '../../hocs/withUserContext';
import { getToken } from '../../../utils/token';
import { getUrlFilter } from '../../../utils/urlParams';
import { getHireRequests, getHireRequest } from '../../../queries/requests';
import HireRequestSelectSmall from '../../user/HireRequestSelectSmall';
import RangeDatePicker from '../formComponents/RangeDatePicker';

const filterFields = ['hireRequest', 'minCreatedAt', 'maxCreatedAt'];

export class HireRequestFields extends Component {
    getExportUrl = () => {
        const filters = getUrlFilter(this.props.location);

        const searchPath = qs.stringify({
            access_token: getToken(),
            filter: JSON.stringify({
                ...pick(filterFields, filters),
                statuses: filters.type === 'all' ?
                    APPLICANTS_ALL_STATUSES :
                    ['supervisorApproval', 'supervisorInterview', 'securityApproval', 'registration', 'attendanceAwaiting']
            }),
            sorting: JSON.stringify({
                field: 'deadline',
                order: 1,
            })
        }, { addQueryPrefix: true });

        return `/api/applicant/export${searchPath}`;
    }

    render() {
        return <Fragment>
            <Row gutter={10}>
                <Col xs={24} sm={8}>
                    <Field
                        name='hireRequest'
                        component={LazyLoadSelect}
                        placeholder='Заявка'
                        optionsPath='hireRequests'
                        namePath='position.name'
                        valuePath='id'
                        renderSmall={HireRequestSelectSmall}
                        query={getHireRequests}
                        singleQuery={getHireRequest}
                        singleQueryPath='hireRequest'
                        variables={{}}
                        allowClear />
                </Col>
                <Col xs={24} sm={8}>
                    <Field
                        name='minCreatedAt'
                        toPath='maxCreatedAt'
                        component={RangeDatePicker}
                        placeholder={['от', 'до']} />
                </Col>
                <Col xs={24} sm={8}>
                    <Row gutter={[10 , 0]}>
                        <Col flex="auto">
                            <Field
                                name='type'
                                component={Select}
                                options={SUPERVISOR_APPLICANTS_STATUSES}
                                placeholder='Статус' />
                        </Col>
                        <Col flex="40px">
                            <Button
                                className="btn-default"
                                icon={<FileExcelOutlined />}
                                href={this.getExportUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                                download />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>;
    }
}

export default withUserContext(withFilterForm(withApollo(withRouter(HireRequestFields)), {}));
