import React from 'react';
import { Button } from 'antd';

import FormLoadingContext from '../../../contexts/FormLoadingContext';

const SubmitButton = props =>
    <FormLoadingContext.Consumer>
        { loading =>
            <Button
                htmlType='submit'
                type={props.className ? null : 'primary'}
                className={props.className}
                loading={loading}
                disabled={props.disabled}>
                { props.children }
            </Button>
        }
    </FormLoadingContext.Consumer>;

export default SubmitButton;
