import gql from 'graphql-tag';

import { User } from './authorization';

export const getHireRequest = gql`
    query hireRequest($id: String!) {
        hireRequest(id: $id) {
            id,
            position {
                id,
                name
            },
            requirements,
            status,
            count,
            deadline,
            address,
            gender,
            minAge,
            maxAge,
            qualification,
            qualificationAdditions,
            educationLevel,
            verifier {
                ...userFields
            },
            finalApprover {
                ...userFields
            },
            approver {
                ...userFields
            },
            recruiter {
                ...userFields
            },
            firstWorkingDate,
            jobConditions {
                schedule,
                salary,
                bonus,
                carCompensation
            },
            initiator {
                ...userFields
            },
            comment,
            regionMacro {
                id,
                name
            },
            regionClient {
                id,
                name
            },
            regionAncor {
                id,
                name
            },
            createdAt
        }
    }

    ${User.fragments.fields}
`;

export const getHireRequests = gql`
    query hireRequests($filter: HireRequestFilterInput, $pagination: PaginationInput, $sorting: HireRequestSortInput) {
        hireRequests(filter: $filter, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                position {
                    id,
                    name
                },
                status,
                initiator {
                    ...userFields
                },
                count,
                deadline,
                requirements,
                regionMacro {
                    id,
                    name
                },
                regionClient {
                    id,
                    name
                },
                regionAncor {
                    id,
                    name
                },
                createdAt,
                statusComment,
                address,
                gender,
                minAge,
                maxAge,
                qualification,
                qualificationAdditions,
                educationLevel,
                verifier {
                    ...userFields
                },
                recruiter {
                    ...userFields
                },
                finalApprover {
                    ...userFields
                },
                approver {
                    ...userFields
                },
                firstWorkingDate,
                jobConditions {
                    schedule,
                    salary,
                    bonus,
                    carCompensation
                },
                comment
            },
            _meta {
                count
            }
        }
    }

    ${User.fragments.fields}
`;

export const createHireRequest = gql(`
    mutation createHireRequest($values: CreateHireRequestInput!) {
        createHireRequest(request: $values) {
            id
        }
    }
`);

export const editHireRequest = gql(`
    mutation editHireRequest($values: EditHireRequestInput!, $id: ID!) {
        editHireRequest(request: $values, id: $id) {
            id
        }
    }
`);

export const removeHireRequest = gql(`
    mutation removeHireRequest($id: ID!) {
        removeHireRequest(id: $id)
    }
`);

export const sendHireRequestToClientApproval = gql(`
    mutation sendHireRequestToClientApproval($id: ID!, $comment: String) {
        sendHireRequestToClientApproval(id: $id, comment: $comment) {
            id
        }
    }
`);

export const returnHireRequestToDraft = gql(`
    mutation returnHireRequestToDraft($id: ID!, $comment: String) {
        returnHireRequestToDraft(id: $id, comment: $comment) {
            id
        }
    }
`);

export const approveHireRequest = gql(`
    mutation approveHireRequest($id: ID!, $comment: String) {
        approveHireRequest(id: $id, comment: $comment) {
            id
        }
    }
`);

export const rejectHireRequest = gql(`
    mutation rejectHireRequest($id: ID!, $comment: String) {
        rejectHireRequest(id: $id, comment: $comment) {
            id
        }
    }
`);

export const holdHireRequest = gql(`
    mutation holdHireRequest($id: ID!, $comment: String) {
        holdHireRequest(id: $id, comment: $comment) {
            id
        }
    }
`);

export const takeHireRequestToAncorApproval = gql(`
    mutation takeHireRequestToAncorApproval($id: ID!, $comment: String) {
        takeHireRequestToAncorApproval(id: $id, comment: $comment) {
            id
        }
    }
`);

export const takeHireRequestToWork = gql(`
    mutation takeHireRequestToWork($id: ID!, $comment: String) {
        takeHireRequestToWork(id: $id, comment: $comment) {
            id
        }
    }
`);

export const markHireRequestAsDone = gql(`
    mutation markHireRequestAsDone($id: ID!, $comment: String) {
        markHireRequestAsDone(id: $id, comment: $comment) {
            id
        }
    }
`);

export const getHireRequestLog = gql`
    query hireRequestLog($id: ID!, $pagination: PaginationInput, $sorting: UserActivitySortInput) {
        hireRequestLog(id: $id, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                type,
                data,
                date,
                initiator {
                    ...userFields
                },
                verifier {
                    ...userFields
                },
                previousVerifier {
                    ...userFields
                },
                hireRequest {
                    position {
                        name
                    },
                    status,
                    count,
                    deadline,
                    comment
                },
                cancelable,
                canceled,
                canceledAt,
                canceledBy {
                    ...userFields
                }
            }
        }
    }

    ${User.fragments.fields}
`;

export const getHireRequestFilters = gql(`
    query hireRequestFilters($filter: HireRequestFilterInput) {
        hireRequestFilters(filter: $filter) {
            statuses {
                status,
                count
            }
        }
    }
`);

export const setHireRequestVerifier = gql(`
    mutation setHireRequestVerifier($id: ID!, $verifierId: ID!) {
        setHireRequestVerifier(id: $id, verifierId: $verifierId) {
            id
        }
    }
`);

export const getHireRequestStatusStat = gql(`
    query hireRequestStatusStat($filter: ReportFilterInput) {
        hireRequestStatusStat(filter: $filter)
    }
`);

export const finalApproveHireRequest = gql(`
    mutation finalApproveHireRequest($id: ID!, $comment: String) {
        finalApproveHireRequest(id: $id, comment: $comment) {
            id
        }
    }
`);

export const availableHireRequestStatuses = gql(`
    query availableHireRequestStatuses {
        availableHireRequestStatuses
    }
`);

export const getHireRequestCountStat = gql`
    query hireRequestCountStat($filter: HireRequestCountReportFilterInput!, $countPositions: Boolean) {
        hireRequestCountStat(filter: $filter, countPositions: $countPositions) {
            regionMacro {
                name,
                id
            },
            regionAncor {
                name,
                id
            },
            regionClient {
                name,
                id
            },
            created,
            inwork,
            done,
            inworkBefore
        }
    }
`;

export const getInterviewFeedbackStat = gql`
    query interviewFeedbackStat($filter: ReportFilterInput) {
        interviewFeedbackStat(filter: $filter) {
            regionMacro {
                name
            },
            regionClient {
                name
            },
            regionAncor {
                name
            },
            position {
                name
            },
            count,
            total,
            avg
        }
    }
`;

export const getApplicantStatusesStat = gql`
    query applicantStatusesStat($filter: ReportFilterInput) {
        applicantStatusesStat(filter: $filter) {
            regionMacro {
                name
            },
            regionClient {
                name
            },
            regionAncor {
                name
            },
            statuses
        }
    }
`;

export const getSentApplicantsStat = gql`
    query sentApplicantsStat($filter: ReportFilterInput) {
        sentApplicantsStat(filter: $filter) {
            regionMacro {
                name
            },
            regionClient {
                name
            },
            regionAncor {
                name
            },
            position {
                name
            },
            created,
            interview
        }
    }
`;
