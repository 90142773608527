import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Spin, message } from 'antd';

import Modal from './Modal';
import DeadlineForm from '../forms/DeadlineForm';
import { setDeadlineSettings, getDeadlineSettings } from '../../queries/deadline';

export default class DeadlineModal extends Component {
    onSuccess = () => {
        message.success('Дедлайны успешно изменены');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить дедлайны');

    render() {
        const { modal, params: { type }} = this.props;

        return <Modal
            {...modal}
            title='Дедлайны'
            footer={null}>
            <Query query={getDeadlineSettings} variables={{ type }}>
                { ({ data, loading }) =>
                    loading ?
                        <Spin /> :
                        <DeadlineForm
                            mutation={setDeadlineSettings}
                            data={data.deadlineSettings}
                            onSuccess={this.onSuccess}
                            onError={this.onError}
                            refetchQueries={[type === 'applicant' ? 'applicants' : 'hireRequests', 'deadlineSettings']} />
                }
            </Query>
        </Modal>;
    }
}
