import React, { Component } from 'react';
import { message } from 'antd';

import UserForm from '../forms/UserForm';
import { createConsultant, editConsultant } from '../../queries/consultants';
import Modal from './Modal';

export default class ConsultantModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Рекрутер был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} рекрутера`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={`${ params ? 'Редактировать' : 'Добавить'} рекрутера`}
            footer={null}>
            <UserForm
                mutation={params ? editConsultant : createConsultant}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['consultants']}
                user={params} />
        </Modal>
    }
}
