import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

const DeadlineFormat = ({ date }) => date ?
    <Tooltip title={ `Перевести до ` + moment.utc(date).format('DD.MM.YYYY HH:mm') }>
        <span className="nowrap"><CalendarOutlined /> { moment.utc(date).add(-1, 'day').format('DD.MM.YYYY') }</span>
    </Tooltip> : null

export default DeadlineFormat;
