import React, { Component, Fragment } from 'react';
import * as yup from 'yup';
import { pathOr } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { Field } from 'react-final-form';
import Select from './formComponents/Select';
import Input from './formComponents/Input';
import { Query } from 'react-apollo';
import { getRegionsMacro } from '../../queries/regions';

class RegionClientForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Query
                query={getRegionsMacro}>
                { ({ data, loading }) =>
                    <Field
                        name='regionMacro'
                        component={Select}
                        label='Макрорегион'
                        options={pathOr([], ['regionsMacro', 'items'], data)}
                        loading={loading}
                        namePath='name'
                        valuePath='id'
                    />
                }
            </Query>
            <SubmitButton>Сохранить</SubmitButton>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    regionMacro: yup.string().required()
});

export default withFormWrapper(RegionClientForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item ? ({
        id: item.id,
        name: item.name,
        regionMacro: item.regionMacro.id
    }) : null
});
