import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { RecoveryLink } from './LoginForm';
import Input from './formComponents/Input';

const validationSchema = yup.object().shape({
    password: yup.string().required(),
});

class PasswordForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password'
                customIcon='password' />
            <div className='controls'>
                <SubmitButton>Задать пароль</SubmitButton>
                <RecoveryLink className='ant-btn btn-link-small' to='/login'>Войти</RecoveryLink>
            </div>
        </Fragment>;
    }
}

export default withFormWrapper(PasswordForm, {
    validationSchema,
    mapPropsToValues: ({ token }) => ({
        token
    }),
});
