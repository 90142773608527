import React, { PureComponent } from 'react';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { isEmpty, filter, isNil, equals, complement } from 'ramda';

import { extendSearchPath } from '../../utils/urlParams';
import InitialValuesContext from '../../contexts/InitialValuesContext';

const filterValues = filter(complement(isNil));

const withFilterForm = WrappedComponent => {
    class FormContent extends PureComponent {
        componentDidMount() {
            this.onChange();
        }

        componentDidUpdate(prev) {
            if (!equals(filterValues(prev.values), filterValues(this.props.values))) {
                this.onChange();
            }
        }

        onChange = () => {
            const vals = filterValues(this.props.values);

            this.props.history.replace(
                extendSearchPath(this.props.location, {
                    filter: isEmpty(vals) ? null : JSON.stringify(vals)
                })
            );
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    class FilterForm extends PureComponent {
        renderForm = props => {
            return <FormContent {...this.props} {...props} />;
        }

        render() {
            return <InitialValuesContext.Provider value={this.props.filter}>
                <Form
                    onSubmit={() => {}}
                    render={this.renderForm}
                    subscription={{ values: true }}
                    initialValues={this.props.filter} />
            </InitialValuesContext.Provider>;
        }
    }

    return withRouter(FilterForm);
}

export default withFilterForm;
