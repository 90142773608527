import React, { Component } from 'react';
import styled from 'styled-components';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import { HireRequestFields, mapPropsToValues, validationSchema, omitRequestBeforeSubmit } from './HireRequestForm';
import withUserContext from '../hocs/withUserContext';

const Wrapper = styled.div`
    .ant-row {
        .ant-input-number,
        .ant-picker {
            width: 100%;
        }
    }
`;

class HireRequestAdminForm extends Component {
    render() {
        return <Wrapper>
            <HireRequestFields {...this.props} showRegionMacro={!this.props.user.regionMacro && !this.props.edit} />
            <SubmitButton>Сохранить</SubmitButton>
        </Wrapper>
    }
}

export default withUserContext(withFormWrapper(HireRequestAdminForm, {
    mapPropsToValues,
    validationSchema,
    mapBeforeSubmit: values => values.id ? omitRequestBeforeSubmit(values) : values
}));
