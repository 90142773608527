import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Main extends Component {
    render() {
        return <div className='guest'>
            <h2>Система подбора кандидатов <br />Анкор</h2>
            <h1>у вас есть профиль в системе?</h1>
            <div className="controls">
                <Link to='/' className="ant-btn ant-btn-primary">Да</Link>
                <Link to='/registration' className="ant-btn btn-default">Нет</Link>
            </div>
        </div>;
    }
}
