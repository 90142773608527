import React, { Component } from 'react';
import { message } from 'antd';
import { pick } from 'ramda';

import SetVerifierForm from '../forms/SetVerifierForm';
import { setHireRequestVerifier } from '../../queries/requests';
import Modal from './Modal';

export default class SetVerifierModal extends Component {
    onSuccess = () => {
        message.success('Ответственный был успешно назначен');
        this.props.close();
    }

    onError = () => message.error('Не удалось назначить ответственного');

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title='Назначить ответственного'
            footer={null}>
            <div className={params.className}>
                <SetVerifierForm
                    mutation={setHireRequestVerifier}
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                    refetchQueries={['hireRequests']}
                    item={pick(['id', 'verifierId'], params)}
                    regionMacro={params.regionMacro}
                    regionClient={params.regionClient}
                    useValuesAsVariables />
            </div>
        </Modal>;
    }
}
