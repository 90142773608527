import qs from 'qs';
import { propOr, filter, isNil, isEmpty } from 'ramda';

export const getUrlParams = location => {
    return qs.parse(propOr('', 'search', location), { ignoreQueryPrefix: true, strictNullHandling: true });
};

export const extendSearchPath = (location, searchPath = {}) => {
    const current = qs.parse(location.search, { ignoreQueryPrefix: true });
    const query = filter(v => !isNil(v), { ...current, ...searchPath });

    return `${location.pathname}${qs.stringify(query, { addQueryPrefix: true, strictNullHandling: true })}`;
};

export const getUrlFilter = location => {
    const { filter } = getUrlParams(location);

    try {
        return JSON.parse(filter);
    } catch(e) {
        return {};
    }
};

export const getUrlPath = filter => {
    return qs.stringify({
        filter: isEmpty(filter) ? null : JSON.stringify(filter)
    }, { addQueryPrefix: true, strictNullHandling: true });
};
