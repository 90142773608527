import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { prop } from 'ramda';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import Datepicker from './formComponents/Datepicker';

const validationSchema = yup.object().shape({
    date: yup.date().nullable().required()
});

class FirstWorkingDayForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='date'
                component={Datepicker}
                label='Дата' />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(FirstWorkingDayForm, {
    validationSchema,
    mapPropsToValues: prop('item')
});
