import { path } from 'ramda';
import moment from 'moment';
import React, { Fragment } from 'react';

import { getShortNameString } from './table/FullName';
import { EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';

export const HireRequestSmall = item => {
    const region = path(['regionClient', 'name'], item);
    const city = path(['regionAncor', 'name'], item);
    const cityString = (region || city) ? `${city || region} ` : '';

    return <Fragment>
        { cityString &&
            <Fragment>
                <span><span className="nowrap"><EnvironmentOutlined /> { cityString }</span> </span>
            </Fragment>
        }
        { item.createdAt && (
            <Fragment>
                <span className="nowrap"><CalendarOutlined /> { moment.utc(item.createdAt).format('DD.MM.YYYY') }</span>
            </Fragment>
        )}
    </Fragment>;

};

const HireRequestSelectSmall = item => {
    return <Fragment>
        { !!item.initiator &&
            <Fragment>
                <span className="nowrap">{getShortNameString(item.initiator)}</span>
            </Fragment>
        }
        {HireRequestSmall(item)}
    </Fragment>;
}

export default HireRequestSelectSmall;
