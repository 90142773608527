import React, { Component } from 'react';
import { Table as AntdTable, Row, Col } from 'antd';
import { Query } from 'react-apollo';
import { pathOr, any, equals, toPairs, has, isEmpty, find, propEq, mapObjIndexed, is, path, isNil } from 'ramda';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { LINES_PER_PAGE } from '../../../constants/lists';
import { getUrlParams, extendSearchPath, getUrlFilter } from '../../../utils/urlParams';

const Wrapper = styled.div`
    .ant-table-pagination {
        margin-right: 15px;
    }
`;

const ToolbarButtons = styled.div`
    position: absolute;
    right: 15px;
    text-align: right;
`;

export const Toolbar = styled(Row)`
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #e8e8e8;
    display: flex !important;
    align-items: center;

    .ant-form-item {
        margin-bottom: 0;
        padding-right: 10px;

        .select {
            .select__control {
                height: 32px;
                min-height: 32px;
            }
            .select__value-container {
                height: 31px;
            }
        }
    }

    .
`;

const ErrorBlock = styled.div`
    color: #f5222d;
`;

const ErrorBlockIcon = styled.div`
    font-size: 36px;
`;

class Table extends Component {
    state = {
        pagination: {
            limit: LINES_PER_PAGE
        }
    };

    static defaultProps = {
        columns: [],
        variables: {},
        staticFilter: {}
    };

    setOffset = offset => {
        const { history, location, statelessParams } = this.props;

        if (statelessParams) {
            this.setState(prev => ({
                pagination: { ...prev.pagination, offset }
            }))
        } else {
            history.replace(extendSearchPath(location, { offset }));
        }
    }

    getPagination = () => {
        const { offset = 0, limit = LINES_PER_PAGE } = getUrlParams(this.props.location);

        return this.props.statelessParams ? this.state.pagination : {
            offset: Number(offset),
            limit: Number(limit)
        };
    }

    getPaginationConfig = data => {
        const { name, onPageChange } = this.props;
        const { offset, limit } = this.getPagination();
        const count = pathOr(0, [name, '_meta', 'count'], data);

        if (count <= limit) {
            return false;
        }

        return {
            total: count,
            pageSize: Number(limit),
            current: (Number(offset) / Number(limit)) + 1,
            defaultCurrent: 0,
            onChange: (page, pageSize) => {
                onPageChange && onPageChange(page);
                this.setOffset((page - 1) * pageSize);
            }
        };;
    }

    getFilter = () => {
        const { defaultFilter, location,  } = this.props;
        const filter = getUrlFilter(location);

        return {
            ...defaultFilter,
            ...filter,
        };
    }

    onChange = (_, filter) => {
        const currentFilter = this.getFilter();
        const newFilter = { ...currentFilter, ...mapObjIndexed((value, key) => {
            const field = find(propEq('key', key), this.props.columns) || {};
            return has('filterMultiple', field) && !field.filterMultiple && is(Array, value) ? path([0], value) : value;
        }, filter) };

        if (any(([key, value]) => !equals(value, currentFilter[key]), toPairs(newFilter))) {
            this.props.history.replace(extendSearchPath(this.props.location, {
                filter: isEmpty(newFilter) ? null : JSON.stringify(newFilter),
                offset: 0
            }));
        }
    }

    getColumns = () => {
        const filter = this.getFilter();

        return this.props.columns.map(column =>
            !isNil(filter[column.key]) && column.filters ? {
                ...column,
                filteredValue: has('filterMultiple', column) && !column.filterMultiple ? [filter[column.key]] : filter[column.key]
            } : column
        )
    }

    renderTableComponent = ({ loading, data, error }) => {
        const { name, rowSelection, rowClassName } = this.props;
        const tableData = error ? null : data;
        const dataSource = pathOr([], [name, 'items'], tableData);

        return <AntdTable
            rowKey='id'
            rowClassName={rowClassName}
            dataSource={dataSource}
            columns={this.getColumns()}
            loading={loading}
            locale={{
                emptyText: error ?
                    <ErrorBlock>
                        <ErrorBlockIcon><ExclamationCircleOutlined /></ErrorBlockIcon>
                        <div>Не удалось отобразить данные</div>
                    </ErrorBlock> :
                    'Нет данных',
                filterConfirm: 'ОК',
                filterReset: 'Сбросить'
            }}
            pagination={this.getPaginationConfig(tableData)}
            rowSelection={rowSelection}
            onChange={this.onChange} />;
    }

    renderTable = props => {
        const { toolbarButtons, FilterForm, customWrapper, defaultFilter } = this.props;
        const table = this.renderTableComponent(props);

        return <Wrapper>
            { (toolbarButtons || FilterForm) &&
                <Toolbar style={{ paddingRight: !!toolbarButtons ? 135 : 15 }}>
                    <Col span={24}>
                        { FilterForm ?
                            <FilterForm filter={this.getFilter()} defaultFilter={defaultFilter} />
                            : null
                        }
                    </Col>
                    { toolbarButtons ?
                        <ToolbarButtons>
                            { toolbarButtons }
                        </ToolbarButtons>
                        : null
                    }
                </Toolbar>
            }
            { customWrapper ? customWrapper(table) : table }
        </Wrapper>;
    }

    render() {
        const { query, staticFilter, mapFilter = f => f } = this.props;
        const variables = {
            ...this.props.variables,
            pagination: this.getPagination(),
            filter: mapFilter({
                ...this.getFilter(),
                ...staticFilter
            })
        };

        return <Query
            query={query}
            variables={variables}
            fetchPolicy='network-only'>
            { this.renderTable }
        </Query>;
    }
}

export default withRouter(Table);
