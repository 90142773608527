import React, { Component } from 'react';
import { Radio } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class RadioButtons extends Component {
    static defaultProps = {
        options: []
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { options, input: { value }} = this.props;

        return <Radio.Group value={value} onChange={this.onChange}>
            { options.map(option =>
                <Radio.Button key={option.value} value={option.value}>
                    { option.label }
                </Radio.Button>
            )}
        </Radio.Group>
    }
}

export default withFieldWrapper(RadioButtons);
