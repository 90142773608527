export const MODAL_TYPES = {
    admin: 'admin',
    supervisor: 'supervisor',
    clientManager: 'clientManager',
    consultant: 'consultant',
    sendRequest: 'sendRequest',
    position: 'position',
    hireStatusChange: 'hireStatusChange',
    requestView: 'requestView',
    applicantForm: 'applicantForm',
    applicantChangeStatus: 'applicantChangeStatus',
    applicantSupervisorChangeStatus: 'applicantSupervisorChangeStatus',
    applicantSupervisorReject: 'applicantSupervisorReject',
    firstWorkingDay: 'firstWorkingDay',
    applicantDetail: 'applicantDetail',
    hireRequestForm: 'hireRequestForm',
    userView: 'userView',
    setVerifier: 'setVerifier',
    applicantInterviewDate: 'applicantInterviewDate',
    regionClient: 'regionClient',
    regionMacro: 'regionMacro',
    regionAncor: 'regionAncor',
    deadline: 'deadline',
    logs: 'logs',
    supervisorComment: 'supervisorComment',
    ancorTech: 'ancorTech',
    applicantCopy: 'applicantCopy',
    applicantOpd: 'applicantOpd',
};
