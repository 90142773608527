import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { pathOr, path } from 'ramda';
import moment from 'moment';

import { getPositions } from '../../../queries/positions';
import { getSupervisors } from '../../../queries/supervisors';
import { getClientManagers } from '../../../queries/clientManagers';
import { getHireRequest, getHireRequests } from '../../../queries/requests';
import { getUrlFilter } from '../../../utils/urlParams';
import withFilterForm from '../../hocs/withFilterForm';
import HireRequestSelectSmall from '../../user/HireRequestSelectSmall';
import { getFullNameString } from '../../user/table/FullName';
import Select, { LazyLoadSelect } from '../formComponents/Select';
import withUserContext from '../../hocs/withUserContext';
import Datepicker, { DatepickerGroup } from '../formComponents/Datepicker';
import { getRegionsCascaderData } from '../../../utils/requests';
import { getRegionsAncor } from '../../../queries/regions';
import Cascader from '../formComponents/Cascader';

class ReportFilter extends Component {
    render() {
        const { additionalFilter, location, onChangeRegions } = this.props;

        const values = getUrlFilter(location);
        const position = path(['position'], values);
        const supervisor = path(['supervisor'], values);
        const hireRequest = path(['hireRequest'], values);
        const minDate = path(['minDate'], values);
        const maxDate = path(['maxDate'], values);
        const regionMacro = path(['user', 'regionMacro', 'id'], this.props);
        const regionMacroFilter = path(['regionMacro'], values);
        const regionClient = path(['regionClient'], values);

        return (
            <Fragment>
                <Row gutter={4}>
                    { additionalFilter && (
                        <Col span={8}>
                            <Field
                                component={Select}
                                {...additionalFilter}
                            />
                        </Col>
                    )}
                    <Col span={additionalFilter ? 8 : 12}>
                        <Query
                            query={getPositions}
                            variables={{
                                filter: {
                                    enabled: true
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='position'
                                    component={Select}
                                    options={pathOr([], ['positions', 'items'], data)}
                                    loading={loading}
                                    placeholder='Позиция'
                                    namePath='name'
                                    valuePath='id'
                                    disabled={!!supervisor || !!hireRequest}
                                    allowClear />
                            }
                        </Query>
                    </Col>
                    <Col span={additionalFilter ? 8 : 12}>
                        <Query
                            query={getRegionsAncor}
                            variables={{ filter: { regionMacro }}}
                        >
                            { ({ data }) =>
                                <Field
                                    name='regionMacro'
                                    additionalNames={['regionClient', 'regionAncor']}
                                    component={Cascader}
                                    onChangeRegions={onChangeRegions}
                                    placeholder='Макрорегион/Регион/Город'
                                    options={getRegionsCascaderData(data)}
                                />
                            }
                        </Query>
                    </Col>
                </Row>
                <Row gutter={4}>
                    <Col span={8}>
                        <Query
                            query={getClientManagers}
                            variables={{
                                filter: {
                                    regionMacro: regionMacroFilter || regionMacro,
                                    regionClient: regionClient,
                                }
                            }}
                        >
                            { ({ data : dataManagers, loading: loadingManagers }) =>
                                <Query query={getSupervisors}>
                                    { ({ data, loading }) =>
                                        <Field
                                            name='supervisor'
                                            component={Select}
                                            options={[
                                                ...pathOr([], ['supervisors', 'items'], data),
                                                ...pathOr([], ['clientManagers', 'items'], dataManagers),
                                            ]}
                                            loading={loading || loadingManagers}
                                            placeholder='Ответственный'
                                            renderName={getFullNameString}
                                            valuePath='id'
                                            renderSmall={HireRequestSelectSmall}
                                            allowClear
                                            disabled={!!hireRequest}
                                        />
                                    }
                                </Query>
                            }
                        </Query>
                    </Col>
                    <Col span={8}>
                        <Field
                            name='hireRequest'
                            component={LazyLoadSelect}
                            placeholder='Заявка'
                            optionsPath='hireRequests'
                            namePath='position.name'
                            valuePath='id'
                            renderSmall={HireRequestSelectSmall}
                            query={getHireRequests}
                            singleQuery={getHireRequest}
                            singleQueryPath='hireRequest'
                            variables={{
                                filter: {
                                    position: position,
                                    verifier: supervisor,
                                },
                                sorting: {
                                    field: 'createdAt',
                                    order: -1
                                }
                            }}
                            allowClear />
                    </Col>
                    <Col span={8}>
                        <DatepickerGroup>
                            <Field
                                name='minDate'
                                component={Datepicker}
                                startOf={'day'}
                                placeholder='От'
                                allowClear={false}
                                disabledDate={date => date.isAfter(moment.utc(maxDate))} />
                            <Field
                                name='maxDate'
                                component={Datepicker}
                                endOf={'day'}
                                placeholder='До'
                                allowClear={false}
                                disabledDate={date => date.isBefore(moment.utc(minDate))} />
                        </DatepickerGroup>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withFilterForm(withUserContext(ReportFilter));
