let onChange;

export function setToken(token) {
    const currentToken = getToken();

    if (token) {
        localStorage.setItem('token', token);
    } else {
        localStorage.removeItem('token');
    }

    if (onChange) {
        onChange(token, currentToken);
    }
}

export function getToken() {
    return localStorage.getItem('token');
}

export function setOnChangeHandler(handler) {
    onChange = handler;
}
