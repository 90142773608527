import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';

import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import SearchInput from '../formComponents/SearchInput';
import { BANNED, ENABLED, PASSWORD_CORRECT, SUCCEED } from '../../../constants/authLog';

class AuthLogFilter extends Component {
    render() {
        return <Row>
            <Col span={8}>
                <Field
                    name='username'
                    component={SearchInput}
                    placeholder='Пользователь' />
            </Col>
            <Col span={4}>
                <Field
                    name='banned'
                    component={Select}
                    options={BANNED}
                    placeholder='Попытка заблокирована'
                    allowClear />
            </Col>
            <Col span={4}>
                <Field
                    name='enabled'
                    component={Select}
                    options={ENABLED}
                    placeholder='Пользователь активен'
                    allowClear />
            </Col>
            <Col span={4}>
                <Field
                    name='passwordCorrect'
                    component={Select}
                    options={PASSWORD_CORRECT}
                    placeholder='Верный пароль'
                    allowClear />
            </Col>
            <Col span={4}>
                <Field
                    name='succeed'
                    component={Select}
                    options={SUCCEED}
                    placeholder='Успешный вход'
                    allowClear />
            </Col>
        </Row>
    }
}

export default withFilterForm(AuthLogFilter);
