import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { path } from 'ramda';

import { getRole } from '../../../utils/users';
import { CLIENT_MANAGER } from '../../../constants/roles';
import Table from '../table/Table';
import FullName from '../table/FullName';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import withUserContext from '../../hocs/withUserContext';
import { getClientManagers, editClientManager } from '../../../queries/clientManagers';
import UserFilter from '../../forms/filters/UserFilter';
import { DEFAULT_USER_FILTER, ENABLED_STATE, FINAL_APPROVER } from '../../../constants/lists';
import SwitchEnabled from '../table/SwitchEnabled';
import { getClientManagerEditInput } from '../../../utils/users';
import DateFormat from '../table/DateFormat';
import RecoveryButton from './RecoveryButton';
import Contacts from '../table/Contacts';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

class ClientManagers extends Component {
    getColumns = () => {
        return [
            {
                title: 'ФИО',
                key: 'name',
                render: item =>
                    <ModalsContext.Consumer>
                        { ({ openModal }) =>
                            <FullName item={item} onClick={() => openModal(MODAL_TYPES.userView, item)} />
                        }
                    </ModalsContext.Consumer>
            },
            {
                title: 'Контакты',
                dataIndex: 'username',
                key: 'contacts',
                render: (email, { phone, activated }) => <Contacts email={email} phone={phone} activated={activated} />
            },
            {
                title: 'Макрорегион',
                dataIndex: ['regionMacro', 'name'],
                key: 'regionMacro'
            },
            {
                title: 'Регион',
                dataIndex: ['regionClient', 'name'],
                key: 'regionClient'
            },
            {
                title: 'Активен',
                key: 'enabled',
                width: 110,
                filters: ENABLED_STATE.map(i => ({ ...i, text: i.label })),
                filterMultiple: false,
                render: item =>
                    <SwitchEnabled
                        mutation={editClientManager}
                        refetchQueries={['clientManagers']}
                        item={{
                            ...getClientManagerEditInput(item),
                            regionMacro: path(['regionMacro', 'id'], item)
                        }} />
            },
            {
                title: 'Первый этап согласования',
                key: 'approver',
                dataIndex: 'approver',
                filters: FINAL_APPROVER,
                filterMultiple: false,
                render: approver => approver ? 'Да' : 'Нет'
            },
            {
                title: 'Финальный этап согласования',
                key: 'finalApprover',
                dataIndex: 'finalApprover',
                filters: FINAL_APPROVER,
                filterMultiple: false,
                render: finalApprover => finalApprover ? 'Да' : 'Нет'
            },
            {
                title: 'Последний вход',
                key: 'lastLoginAt',
                dataIndex: 'lastLoginAt',
                render: date => <DateFormat date={date} />
            },
            {
                key: 'actions',
                width: 100,
                render: item =>
                    <Button.Group>
                        <RecoveryButton username={item.username} />
                        <ModalsContext.Consumer>
                            { ({ openModal }) =>
                                <Fragment>
                                    { getRole() !== CLIENT_MANAGER && (
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => openModal(MODAL_TYPES.clientManager, getClientManagerEditInput(item))} />
                                    )}
                                </Fragment>
                            }
                        </ModalsContext.Consumer>
                    </Button.Group>
            }
        ];
    }

    renderToolbarButtons = () => {
        return <ModalsContext.Consumer>
            { ({ openModal }) =>
                <Fragment>
                    { getRole() !== CLIENT_MANAGER && (
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => openModal(MODAL_TYPES.clientManager)}>
                            Добавить
                        </Button>
                    )}
                </Fragment>
            }
        </ModalsContext.Consumer>;
    }

    render() {
        const defaultFilter = getRole() === CLIENT_MANAGER && {
            regionMacro: path(['regionMacro', 'id'], this.props.user)
        };

        return <Table
            name='clientManagers'
            query={getClientManagers}
            columns={this.getColumns()}
            toolbarButtons={this.renderToolbarButtons()}
            FilterForm={UserFilter}
            defaultFilter={defaultFilter ? ({
                ...DEFAULT_USER_FILTER,
                ...defaultFilter
            }) : DEFAULT_USER_FILTER} />;
    }
}

export default withUserContext(ClientManagers);
