import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';

import withFilterForm from '../../hocs/withFilterForm';
import SearchInput from '../formComponents/SearchInput';

class RegionMacroFilter extends Component {
    render() {
        return <Row>
            <Col span={12}>
                <Field
                    name='name'
                    component={SearchInput}
                    placeholder='Название' />
            </Col>
        </Row>
    }
}

export default withFilterForm(RegionMacroFilter);
