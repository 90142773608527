import gql from 'graphql-tag';

export const getAdmins = gql(`
    query admins($filter: AdminFilterInput, $pagination: PaginationInput, $sorting: UserSortInput) {
        admins(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                username,
                lastName,
                firstName,
                middleName,
                phone,
                enabled,
                activated,
                lastLoginAt,
                applicantStatusManagementEnabled
            }
        }
    }
`);

export const createAdmin = gql(`
    mutation createAdmin($values: AdminInput!) {
        createAdmin(admin: $values) {
            id
        }
    }
`);

export const editAdmin = gql(`
    mutation editAdmin($values: AdminEditInput!, $id: ID!) {
        editAdmin(admin: $values, id: $id) {
            id
        }
    }
`);
