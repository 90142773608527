import React, { Component } from 'react';

import Modal from './Modal';
import RegionClientForm from '../forms/RegionClientForm';
import { editRegionClient, createRegionClient } from '../../queries/regions';
import { message } from 'antd';

export default class RegionClientModal extends Component {
    onSuccess = () => {
        const { params } = this.props;

        message.success(`Регион был успешно ${params ? 'изменен' : 'добавлен'}`);
        this.props.close();
    }

    onError = () => message.error(`Не удалось ${this.props.params ? 'изменить' : 'добавить'} регион`);

    render() {
        const { modal, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать регион' : 'Добавить регион'}
            footer={null}>
            <RegionClientForm
                mutation={params ? editRegionClient : createRegionClient}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={['regionsClient']}
                item={params} />
        </Modal>;
    }
}
