import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop, propOr } from 'ramda';
import moment from 'moment';

import withFormWrapper from '../hocs/withFormWrapper';
import withUserContext from '../hocs/withUserContext';
import Textarea from './formComponents/Textarea';
import SubmitButton from './formComponents/SubmitButton';
import DateTimePicker from './formComponents/DateTimePicker';
import Datepicker from './formComponents/Datepicker';
import Timepicker from './formComponents/Timepicker';
import FeedbackFields from './FeedbackFields';
import { uniteDates } from '../../utils/date';
import { isNextWorkWeekFrom } from '../../utils/deadline';
import { needNativeInputs } from '../../utils/mobile';

class ApplicantSupervisorStatusChangeForm extends Component {
    render() {
        const { item, user } = this.props;

        return <Fragment>
            { this.props.showDateFields && (
                needNativeInputs() ? (
                    <Field
                            name='date'
                            component={DateTimePicker}
                            disabledDate={isNextWorkWeekFrom(propOr(moment.utc(), 'statusChangedAt', item))}
                            label='Дата Время' />
                ) : (
                    <Fragment>
                        <Field
                            name='date'
                            component={Datepicker}
                            disabledDate={isNextWorkWeekFrom(propOr(moment.utc(), 'statusChangedAt', item))}
                            label='Дата' />
                        <Field
                            name='time'
                            component={Timepicker}
                            label='Время' />
                    </Fragment>
                )
            )}
            { this.props.showInterviewFields &&
                <FeedbackFields user={user} />
            }
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий' />
            <SubmitButton>
                Отправить
            </SubmitButton>
        </Fragment>;
    }
}

export default withFormWrapper(withUserContext(ApplicantSupervisorStatusChangeForm), {
    mapPropsToValues: prop('data'),
    mapBeforeSubmit: ({ date, time, ...values }) => date && time ? ({
        ...values,
        date: uniteDates(date, time)
    }) : values
});
