import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { pathOr, path } from 'ramda';

import { getSupervisors } from '../../../queries/supervisors';
import { getPositions } from '../../../queries/positions';
import { getRegionsAncor } from '../../../queries/regions';
import { getClientManagers } from '../../../queries/clientManagers';
import { getConsultants } from '../../../queries/consultants';
import { getUrlFilter } from '../../../utils/urlParams';
import { getRegionsCascaderData, getRegionsManagerCascaderData } from '../../../utils/requests';
import withFilterForm from '../../hocs/withFilterForm';
import Select from '../formComponents/Select';
import Cascader from '../formComponents/Cascader';
import HireRequestSelectSmall from '../../user/HireRequestSelectSmall';
import { getFullNameString } from '../../user/table/FullName';

class HireRequestFilter extends Component {
    render() {
        const { defaultFilter = {} } = this.props;
        const values = getUrlFilter(this.props.location);
        const supervisor = path(['verifier'], values);
        const regionMacro = path(['regionMacro'], values);
        const regionClient = path(['regionClient'], values);
        const regionAncor = path(['regionAncor'], values);

        return <Row>
            <Col span={6}>
                <Query
                    query={getPositions}
                    variables={{
                        filter: {
                            enabled: true
                        }
                    }}>
                    { ({ data, loading }) =>
                        <Field
                            name='position'
                            component={Select}
                            options={pathOr([], ['positions', 'items'], data)}
                            loading={loading}
                            placeholder='Позиция'
                            namePath='name'
                            valuePath='id'
                            allowClear />
                    }
                </Query>
            </Col>
            <Col span={6}>
                <Query query={getRegionsAncor} variables={regionMacro ? { filter: { regionMacro } } : undefined}>
                    { ({ data }) =>
                        defaultFilter.regionMacro ? (
                            <Field
                                name='regionClient'
                                additionalNames={['regionAncor']}
                                component={Cascader}
                                placeholder='Регион/Город'
                                disabled={!!supervisor}
                                options={getRegionsManagerCascaderData(data)} />
                        ) : (
                            <Field
                                name='regionMacro'
                                additionalNames={['regionClient', 'regionAncor']}
                                component={Cascader}
                                placeholder='Макрорегион/Регион/Город'
                                disabled={!!supervisor}
                                options={getRegionsCascaderData(data)} />
                        )
                    }
                </Query>
            </Col>
            <Col span={6}>
                <Query
                    query={getClientManagers}
                    variables={{
                        filter: {
                            enabled: true,
                            regionMacro: defaultFilter.regionMacro || regionMacro,
                            regionClient: regionClient,
                        }
                    }}
                >
                    { ({ data : dataClientManagers, loading: loadingClientManagers }) =>
                        <Query
                            query={getSupervisors}
                            variables={{
                                filter: {
                                    enabled: true,
                                    regionMacro: defaultFilter.regionMacro || regionMacro,
                                    regionClient: regionClient,
                                    regionAncor: regionAncor,
                                }
                            }}
                        >
                            { ({ data, loading }) =>
                                <Field
                                    name='verifier'
                                    component={Select}
                                    options={[
                                        ...pathOr([], ['supervisors', 'items'], data),
                                        ...pathOr([], ['clientManagers', 'items'], dataClientManagers),
                                    ]}
                                    loading={loading || loadingClientManagers}
                                    placeholder='Ответственный'
                                    renderName={getFullNameString}
                                    valuePath='id'
                                    renderSmall={HireRequestSelectSmall}
                                    allowClear />
                            }
                        </Query>
                    }
                </Query>
            </Col>
            <Col span={6}>
                <Query
                    query={getConsultants}
                    variables={{
                        filter: {
                            enabled: true,
                        }
                    }}
                >
                    { ({ data, loading }) =>
                        <Field
                            name='recruiter'
                            component={Select}
                            options={pathOr([], ['consultants', 'items'], data)}
                            loading={loading}
                            placeholder='Рекрутер'
                            renderName={getFullNameString}
                            valuePath='id'
                            allowClear />
                    }
                </Query>
            </Col>
        </Row>
    }
}

export default withFilterForm(HireRequestFilter);
