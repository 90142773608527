import React, { Component } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { path, prop, pathOr } from 'ramda';
import * as yup from 'yup';
import { Spin } from 'antd';
import { Query } from 'react-apollo';

import SubmitButton from './formComponents/SubmitButton';
import withFormWrapper from '../hocs/withFormWrapper';
import withUserContext from '../hocs/withUserContext';
import { APPLICANTS_DOUBLES_STATUSES, APPLICANTS_COPY_STATUSES } from '../../constants/applicants';
import Select, { LazyLoadSelect } from './formComponents/Select';
import { getHireRequests } from '../../queries/requests';
import { getApplicants, getApplicant } from '../../queries/applicants';
import HireRequestSelectSmall from '../user/HireRequestSelectSmall';
import { getFullNameApplicantString } from '../user/table/FullName';
import Applicant from '../user/table/Applicant';
import Position from '../user/table/Position';
import UserLink from '../user/table/UserLink';
import Status from '../user/table/Status';

const validationSchema = yup.object().shape({
    id: yup.string().nullable().required(),
});

class ApplicantForm extends Component {
    selectApplicant = (id, item) => {
        this.props.form.change('applicant', item);
    }

    render() {
        const { pdaEnabled } = this.props.user;

        return <div>
            <Query
                query={getHireRequests}
                variables={{
                    filter: {
                        id: this.props.requestId,
                    }
                }}>
                { ({ data, loading }) =>
                    <Field
                        name='requestId'
                        component={Select}
                        label='Заявка'
                        options={pathOr([], ['hireRequests', 'items'], data)}
                        namePath='position.name'
                        valuePath='id'
                        loading={loading}
                        renderSmall={HireRequestSelectSmall}
                        disabled />
                }
            </Query>
            <Field
                name='id'
                component={LazyLoadSelect}
                singleQuery={getApplicant}
                query={getApplicants}
                variables={{
                    filter: {
                        depersonalized: pdaEnabled ? false : undefined,
                        statuses: APPLICANTS_COPY_STATUSES
                    }
                }}
                label='Кандидат для копирования'
                optionsPath={'applicants'}
                singleQueryPath={'applicant'}
                valuePath='id'
                renderName={getFullNameApplicantString}
                renderSmall={Status}
                onChange={this.selectApplicant} />
            <FormSpy subscription={{ values: true }}>{({ values }) => (
                <Query
                    query={getApplicants}
                    skip={!path(['id'], values)}
                    variables={{ filter: {
                        contact: {
                            phone: path(['applicant', 'contact', 'phone'], values),
                            email: path(['applicant', 'contact', 'email'], values),
                        },
                        statuses: APPLICANTS_DOUBLES_STATUSES,
                    }}}
                >
                    { ({ data, loading }) => !path(['id'], values) &&
                        <div>
                            { loading ? (
                                <div className='copy-applicant'>Поиск двойников <Spin /></div>
                            ) : (
                                pathOr([], ['applicants', 'items'], data).map(item => (
                                    <div key={item.id} className='copy-applicant' >
                                        <div>Кандидат: {item.depersonalized ? '-' : ''}</div>
                                        <Applicant applicant={item} item={item.contact} />
                                        <div>Заявка: </div>
                                        <Position position={item.hireRequest.position.name} item={item} hideSmall />
                                        <div>Рекрутер: </div>
                                        <UserLink item={item.responsible} />
                                        <Status item={item.status} />
                                    </div>
                                ))
                            )}
                        </div>
                    }
                </Query>
            )}</FormSpy>
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>;
    }
}

export default withFormWrapper(withUserContext(ApplicantForm), {
    mapPropsToValues: prop('values'),
    validationSchema
});
