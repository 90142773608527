import gql from 'graphql-tag';

export const getPositions = gql(`
    query positions($filter: PositionFilterInput, $pagination: PaginationInput) {
        positions(filter: $filter, pagination: $pagination) {
            _meta {
                count
            },
            items {
                id,
                name,
                requirements,
                enabled
            }
        }
    }
`);

export const createPosition = gql(`
    mutation createPosition($values: PositionInput!) {
        createPosition(position: $values) {
            id
        }
    }
`);

export const editPosition = gql(`
    mutation editPosition($values: PositionInput!, $id: ID!) {
        editPosition(position: $values, id: $id) {
            id
        }
    }
`);
