import moment from 'moment';

export const uniteDates = (date, time) => {
    if (!time) {
        return moment.utc(date).toDate();
    }

    const momentTime = moment.utc(time);

    return moment.utc(date)
        .set('h', momentTime.get('h'))
        .set('m', momentTime.get('m'))
        .toDate();
}
