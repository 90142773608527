import React, { Component } from 'react';
import { message } from 'antd';

import RecoveryForm from '../forms/RecoveryForm';
import { requestResetPassword } from '../../queries/authorization';
import { DEFAULT_RECOVERY_ERROR } from '../../constants/errors';

export default class Recovery extends Component {
    onSuccess = () => {
        message.success('Ссылка на восстановление пароля была успешно отправлена');
        this.props.history.push('/');
    }

    render() {
        return <RecoveryForm
            mutation={requestResetPassword}
            onSuccess={this.onSuccess}
            defaultError={DEFAULT_RECOVERY_ERROR}
            useValuesAsVariables />;
    }
}
