import React from 'react';
import { contains } from 'ramda';

import NoMatch from '../NoMatch';
import { getRole } from '../../../utils/users';
import Route from '../../Route';

const SecureRoute = ({ roles, ...props }) => {
    const role = getRole();

    return !roles || contains(role, roles) ?
        <Route {...props} /> :
        <NoMatch />
}

export default SecureRoute;
