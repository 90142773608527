import React from 'react';
import { map, propEq, pathOr, find } from 'ramda';
import { ResponsiveFunnel } from '@nivo/funnel';
import { useSpring, animated } from 'react-spring'
import { useTheme, useMotionConfig } from '@nivo/core'

const PartLabel = ({ part, total }) => {
    const theme = useTheme();
    const { animate, config: motionConfig } = useMotionConfig();
    const xDiff = (part.points[1].x - part.points[2].x) / 2;
    const animatedProps = useSpring({
        transform: `translate(${part.x1 - xDiff}, ${part.y})`,
        color: part.labelColor,
        config: motionConfig,
        immediate: !animate,
    });
    const percent = part.formattedValue * 100 / total;

    return (
        <animated.g transform={animatedProps.transform}>
            <animated.line x1={0} y1={0} x2={25} y2={0} stroke={part.color} />
                <foreignObject dominantBaseline="central" x={30} y={-8} width={130} height={160}>
                    <p style={{
                        ...theme.labels.text,
                        color: animatedProps.color,
                        fontWeight: 'bold',
                        lineHeight: 1.2
                    }}>
                        {part.data.label}: {part.formattedValue} ({ percent % 1 === 0 ? percent : percent ? percent.toFixed(1) : 0 }%)
                    </p>
                </foreignObject>
        </animated.g>
    );
}

const Labels = props => props.parts.map(part => <PartLabel key={part.data.id} part={part} total={props.total} />);

const FunnelChart = (props) => {
    const data = map(({ field: label, count: value }) => ({ id: label, label, value }), props.data);

    if (data.length === 0) {
        return <div className='ant-table-placeholder'>Нет данных</div>;
    }

    const total = pathOr(0, ['count'], find(propEq('_id', 'supervisorApproval'), props.data));

    return (
        <div style={{ maxWidth: '700px', margin: '50px auto', height: 400 }}>
            <ResponsiveFunnel
                data={data}
                shapeBlending={0.66}
                layers={['parts', 'annotations', props => <Labels {...props} total={total} />]}
                colors={{ scheme: 'spectral' }}
                borderWidth={0}
                spacing={2}
                beforeSeparatorLength={100}
                beforeSeparatorOffset={20}
                afterSeparatorLength={150}
                afterSeparatorOffset={50}
                currentPartSizeExtension={10}
                currentBorderWidth={0}
                labelColor='#000'
            />
        </div>
    )
};

export default FunnelChart;
