import React, { Component } from 'react';
import { message } from 'antd';
import { path } from 'ramda';
import { withApollo } from 'react-apollo';

import ApplicantForm from '../forms/ApplicantForm';
import { createApplicant, editApplicant, sendPdaNotification } from '../../queries/applicants';
import { APPLICANTS_REFETCH_QUERIES } from '../../constants/applicants';
import Modal from './Modal';
import withUserContext from '../hocs/withUserContext';

class ApplicantFormModal extends Component {
    static defaultProps = {
        params: {}
    };

    sendOpd = id => {
        setTimeout(() => {
            this.props.client.mutate({
                mutation: sendPdaNotification,
                variables: {
                    id
                },
                refetchQueries: APPLICANTS_REFETCH_QUERIES
            })
                .then(() => {
                    message.success('Уведомление СОПД успешно отправлено');
                    this.props.close();
                })
                .catch(() => {
                    message.error('Не удалось отправить уведомление СОПД');
                    this.props.close();
                });
        });
    }

    onSuccess = data => {
        const id = path(['editApplicant', 'id'], data);
        const { params } = this.props;

        message.success(`Кандидат был успешно ${params.applicant && !params.copy ? 'изменен' : 'добавлен'}`);

        if (params.applicant && params.applicant.depersonalized && id) {
            this.sendOpd(id);
        } else {
            this.props.close();
        }
    }

    onError = (err) => message.error(`Не удалось ${this.props.params.applicant && !this.props.params.copy ? 'изменить' : 'добавить'} кандидата`);

    render() {
        const { modal, params, user } = this.props;

        return <Modal
            {...modal}
            title={params.copy ? 'Копирование кандидата' : params.applicant ? 'Редактировать кандидата' : 'Добавить кандидата'}
            footer={null}
        >
            <ApplicantForm
                mutation={params.applicant && !params.copy ? editApplicant : createApplicant}
                onSuccess={this.onSuccess}
                onError={this.onError}
                refetchQueries={APPLICANTS_REFETCH_QUERIES}
                applicant={params.applicant ? ({
                    id: params.copy ? params.requestId : params.applicant.id,
                    birthDate: params.applicant.birthDate,
                    gender: params.applicant.gender,
                    contact: {
                        firstName: params.applicant.contact.firstName,
                        middleName: params.applicant.contact.middleName,
                        lastName: params.applicant.contact.lastName,
                        email: params.applicant.contact.email,
                        phone: params.applicant.contact.phone,
                        pdaAgreement: params.applicant.contact.pdaAgreement,
                        communicationsAgreement: params.applicant.contact.communicationsAgreement,
                        passportId: params.applicant.contact.passportId,
                        passportDate: params.applicant.contact.passportDate,
                        passportIssuer: params.applicant.contact.passportIssuer,
                        address: params.applicant.contact.address,
                    },
                    educationLevel: params.applicant.educationLevel,
                    resume: params.applicant.resume,
                    comment: params.applicant.comment,
                    responsible: params.copy ? user.id : path(['responsible', 'id'], params.applicant),
                    resetDepersonalization: params.applicant.depersonalized ? true : undefined,
                    pdaContent: params.applicant.pdaContent,
                }): { id: params.requestId, responsible: user.id }}
                showRequestIdSelector={!params.applicant && !params.requestId}
                isCheckApplicants={!params.applicant || params.copy}
            />
        </Modal>
    }
}

export default withUserContext(withApollo(ApplicantFormModal));
