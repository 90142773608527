import gql from 'graphql-tag';

import { User } from './authorization';

export const getApplicants = gql`
    query applicants($filter: ApplicantFilterInput, $pagination: PaginationInput, $sorting: ApplicantSortInput) {
        applicants(filter: $filter, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                status,
                createdAt,
                contact {
                    firstName,
                    lastName,
                    middleName,
                    phone,
                    email,
                    pdaAgreement,
                    communicationsAgreement,
                    passportId,
                    passportDate,
                    passportIssuer,
                    address
                },
                initiator {
                    ...userFields
                },
                firstWorkingDay,
                hireRequest {
                    id,
                    status,
                    statusComment,
                    deadline,
                    address,
                    gender,
                    minAge,
                    maxAge,
                    requirements,
                    count,
                    qualification,
                    qualificationAdditions,
                    educationLevel,
                    verifier {
                        ...userFields
                    },
                    firstWorkingDate,
                    jobConditions {
                        schedule,
                        salary,
                        bonus,
                        carCompensation
                    },
                    initiator {
                        ...userFields
                    },
                    recruiter {
                        ...userFields
                    },
                    comment,
                    createdAt,
                    regionClient {
                        id,
                        name
                    },
                    regionAncor {
                        name,
                        id
                    },
                    position {
                        name,
                        id
                    }
                },
                dateInterview,
                deadline,
                statusChangedAt,
                statusComment,
                reasonForRejection,
                gender,
                educationLevel,
                birthDate,
                resume,
                comment,
                pdaContent,
                pdaKey,
                pdaKeyExpiringAt,
                firstPdaSentAt,
                lastPdaSentAt,
                countSentPdaNotifications,
                depersonalized,
                depersonalizationReason,
                files {
                    id,
                    fileName,
                    createdAt,
                    mimeType
                },
                interviewFeedback {
                    achievementOfResults,
                    motivation,
                    passionForChanges,
                    communication,
                    salesTechniques
                },
                responsible {
                    ...userFields
                }
            },
            _meta {
                count
            }
        }
    }

    ${User.fragments.fields}
`;

export const getApplicant = gql(`
    query applicant($id: String!) {
        applicant(id: $id) {
            id,
            status,
            contact {
                firstName,
                lastName,
                middleName,
                phone,
                email,
                pdaAgreement,
                communicationsAgreement,
                passportId,
                passportDate,
                passportIssuer,
                address
            },
            birthDate,
            gender,
            firstWorkingDay,
            hireRequest {
                id,
                address,
                position {
                    id,
                    name
                }
            },
            resume,
            dateInterview,
            statusChangedAt,
            educationLevel,
            pdaContent,
            pdaKey,
            pdaKeyExpiringAt,
            firstPdaSentAt,
            lastPdaSentAt,
            countSentPdaNotifications,
            depersonalized,
            depersonalizationReason,
            files {
                id,
                fileName,
                createdAt,
                mimeType
            },
            comment
        }
    }
`);

export const createApplicant = gql(`
    mutation createApplicant($values: ApplicantInput!, $id: ID!) {
        createApplicant(applicant: $values, hireRequestId: $id) {
            id
        }
    }
`);

export const editApplicant = gql(`
    mutation editApplicant($values: ApplicantInput!, $id: ID!) {
        editApplicant(applicant: $values, id: $id) {
            id,
            pdaKey
        }
    }
`);

export const changeApplicantStatus = gql(`
    mutation changeApplicantStatus($id: ID!, $status: ApplicantStatus, $comment: String) {
        changeApplicantStatus(id: $id, status: $status, comment: $comment) {
            id
        }
    }
`);

export const rejectApplicant = gql(`
    mutation rejectApplicant($id: ID!, $reason: ReasonForRejectionInput!, $comment: String) {
        rejectApplicant(id: $id, reason: $reason, comment: $comment) {
            id
        }
    }
`);

export const setDateInterview = gql(`
    mutation setDateInterview($id: ID!, $date: Date!) {
        setDateInterview(id: $id, date: $date) {
            id
        }
    }
`);

export const setFirstWorkingDay = gql(`
    mutation setFirstWorkingDay($id: ID!, $date: Date!) {
        setFirstWorkingDay(id: $id, date: $date) {
            id
        }
    }
`);

export const setApplicantInterviewFeedback = gql(`
    mutation setApplicantInterviewFeedback($id: ID!, $feedback: ApplicantInterviewFeedbackInput!) {
        setApplicantInterviewFeedback(id: $id, feedback: $feedback) {
            id
        }
    }
`);

export const getApplicantLog = gql`
    query applicantLog($id: ID!, $pagination: PaginationInput, $sorting: UserActivitySortInput) {
        applicantLog(id: $id, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                type,
                data,
                date,
                initiator {
                    ...userFields
                },
                cancelable,
                canceled,
                canceledAt,
                canceledBy {
                    ...userFields
                },
                previousResponsible {
                    ...userFields
                },
                responsible {
                    ...userFields
                }
            }
        }
    }

    ${User.fragments.fields}
`;

export const getApplicantFilters = gql(`
    query applicantFilters($filter: ApplicantFilterInput) {
        applicantFilters(filter: $filter) {
            statuses {
                status,
                count
            }
        }
    }
`);

export const getApplicantFunnel = gql(`
    query applicantFunnel($filter: ApplicantFunnelFilterInput) {
        applicantFunnel(filter: $filter)
    }
`);

export const getApplicantStatusStat = gql(`
    query applicantStatusStat($filter: ReportFilterInput) {
        applicantStatusStat(filter: $filter)
    }
`);

export const getApplicantRejectStat = gql(`
    query applicantRejectStat($filter: ReportFilterInput) {
        applicantRejectStat(filter: $filter)
    }
`);

export const getApplicantApprovalStat = gql(`
    query applicantApprovalStat($filter: ApplicantApprovalFilterInput) {
        applicantApprovalStat(filter: $filter)
    }
`);

export const getApplicantDailyUsersStat = gql(`
    query applicantDailyUsersStat($filter: ReportFilterInput) {
        applicantDailyUsersStat(filter: $filter)
    }
`);

export const getApplicantExpirationStat = gql`
    query applicantExpirationStat($filter: ApplicantExpirationFilterInput) {
        applicantExpirationStat(filter: $filter) {
            count,
            hireRequest {
                status,
                position {
                    name
                }
                regionAncor {
                    id,
                    name
                },
            },
            regionMacro {
                name
            },
            regionClient {
                name
            },
            regionAncor {
                name
            },
            responsible {
                lastName,
                firstName,
                middleName
            },
        }
    }
`;

export const getExpiringsStat = gql`
    query expiringsStat($filter: ReportFilterInput) {
        expiringsStat(filter: $filter) {
            regionMacro {
                name
            },
            regionClient {
                name
            },
            regionAncor {
                name
            },
            days,
            requestsCount
        }
    }
`;

export const applicantDeadlineLog = gql`
    query applicantDeadlineLog($pagination: PaginationInput, $sorting: UserActivitySortInput) {
        applicantDeadlineLog(pagination: $pagination, sorting: $sorting) {
            items {
                id,
                type,
                initiator {
                    ...userFields
                },
                data,
                date,
                regionMacro {
                    name
                },
                regionClient {
                    name
                },
                regionAncor {
                    name
                },
                hireRequest {
                    id,
                    status,
                    statusComment,
                    deadline,
                    address,
                    gender,
                    minAge,
                    maxAge,
                    requirements,
                    count,
                    qualification,
                    qualificationAdditions,
                    educationLevel,
                    verifier {
                        ...userFields
                    },
                    firstWorkingDate,
                    jobConditions {
                        schedule,
                        salary,
                        bonus,
                        carCompensation
                    },
                    initiator {
                        ...userFields
                    },
                    comment,
                    createdAt,
                    regionClient {
                        id,
                        name
                    },
                    regionAncor {
                        id,
                        name
                    },
                    position {
                        id,
                        name
                    }
                },
                applicant {
                    id,
                    status,
                    contact {
                        firstName,
                        lastName,
                        middleName,
                        phone,
                        email
                    },
                    initiator {
                        ...userFields
                    },
                    firstWorkingDay,
                    hireRequest {
                        id,
                        status,
                        statusComment,
                        deadline,
                        address,
                        gender,
                        minAge,
                        maxAge,
                        requirements,
                        count,
                        qualification,
                        qualificationAdditions,
                        educationLevel,
                        verifier {
                            ...userFields
                        },
                        firstWorkingDate,
                        jobConditions {
                            schedule,
                            salary,
                            bonus,
                            carCompensation
                        },
                        initiator {
                            ...userFields
                        },
                        comment,
                        createdAt,
                        regionClient {
                            name,
                            id
                        },
                        regionAncor {
                            name,
                            id
                        },
                        position {
                            name,
                            id
                        }
                    },
                    dateInterview,
                    deadline,
                    statusChangedAt,
                    statusComment,
                    reasonForRejection,
                    gender,
                    educationLevel,
                    birthDate,
                    resume,
                    comment,
                    interviewFeedback {
                        achievementOfResults,
                        motivation,
                        passionForChanges,
                        communication,
                        salesTechniques
                    }
                }
            },
            _meta {
                count
            }
        }
    }

    ${User.fragments.fields}
`;

export const cancelAction = gql`
    mutation cancelAction($id: ID!) {
        cancelAction(id: $id) {
            id
        }
    }
`;

export const removeApplicant = gql`
    mutation removeApplicant($id: ID!) {
        removeApplicant(id: $id)
    }
`;

export const sendPdaNotification = gql`
    mutation sendPdaNotification($id: ID!) {
        sendPdaNotification(id: $id)
    }
`;

export const getApplicantWorkTime = gql`
    query applicantWorkTime($filter: ReportFilterInput) {
        applicantWorkTime(filter: $filter)
    }
`;

export const addApplicantComment = gql`
    mutation addApplicantComment($comment: CommentInput!, $applicant: ID!) {
        addApplicantComment(comment: $comment, applicant: $applicant) {
            id
        }
    }
`;

export const positionExpiringsStat = gql`
    query positionExpiringsStat($pagination: PaginationInput, $filter: ReportFilterInput) {
        positionExpiringsStat(pagination: $pagination, filter: $filter) {
            items {
                requestsCount,
                days,
                status,
                startDate,
                finishDate,
                workingDate,
                regionClient {
                    name
                },
                position {
                    name
                }
            },
            _meta {
                count
            }
        }
    }
`;
