import React, { Component } from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { isNil } from 'ramda';

import { getAuthLog } from '../../../queries/authorization';
import Table from '../table/Table';
import AuthLogFilter from '../../forms/filters/AuthLogFilter';
import DateFormat from '../table/DateFormat';
import { LoginOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

const UserIcon = styled.div`
    color: ${({ green }) => green ? '#52c41a' : '#f5222d'}
`;

export default class AuthLog extends Component {
    getColumns = () => {
        return [
            {
                title: 'Пользователь',
                dataIndex: 'username',
                key: 'username'
            },
            {
                title: 'IP',
                dataIndex: 'ip',
                key: 'ip',
                render: (ip, { userAgent }) => (
                    <Tooltip title={userAgent || '-'}>
                        {ip}
                    </Tooltip>
                )
            },
            {
                title: 'Дата',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => <DateFormat date={date} />
            },
            {
                title: 'Попытка заблокирована',
                dataIndex: 'banned',
                key: 'banned',
                render: banned => this.renderStatusIcon(!banned),
                align: 'center'
            },
            {
                title: 'Пользователь активен',
                dataIndex: 'enabled',
                key: 'enabled',
                render: this.renderStatusIcon,
                align: 'center'
            },
            {
                title: 'Верный пароль',
                dataIndex: 'passwordCorrect',
                key: 'passwordCorrect',
                render: this.renderStatusIcon,
                align: 'center'
            },
            {
                title: 'Успешный вход',
                dataIndex: 'succeed',
                key: 'succeed',
                render: succeed => <UserIcon green={succeed}><LoginOutlined /></UserIcon>,
                align: 'center'
            }
        ];
    }

    renderStatusIcon = status => {
        return isNil(status) ? null : <UserIcon green={status}>
            {status ? <CheckOutlined /> : <CloseOutlined />}
        </UserIcon>;
    }

    render() {
        return <Table
            name='authLog'
            query={getAuthLog}
            columns={this.getColumns()}
            FilterForm={AuthLogFilter}
            variables={{
                sorting: {
                    field: 'createdAt',
                    order: -1
                }
            }} />;
    }
}
