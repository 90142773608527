import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Tooltip } from 'antd';
import { Query } from 'react-apollo';
import { pathOr, toLower, path } from 'ramda';
import moment from 'moment';

import { getHireRequests } from '../../../queries/requests';
import { REQUEST_STATUSES } from '../../../constants/requests';
import { LINES_PER_PAGE } from '../../../constants/lists';
import EmptyList from './EmptyList';
import withUserContext from '../../hocs/withUser';

class HireRequests extends Component {
    state = {
        offset: 0,
    };

    getVariables = (offset = 0) => {
        return {
            pagination: {
                offset,
                limit: LINES_PER_PAGE
            }
        }
    }

    loadMore = (page, fetchMore) => {
        const offset = page * LINES_PER_PAGE;

        this.setState({ offset }, () => {
            fetchMore({
                variables: this.getVariables(offset),
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return prev;
                    }

                    return {
                        hireRequests: {
                            ...fetchMoreResult.hireRequests,
                            items: pathOr([], ['hireRequests', 'items'], prev).concat(fetchMoreResult.hireRequests.items)
                        }
                    };
                }
            });
        });
    }

    renderList = (props) => {
        const { data, fetchMore } = props;
        const items = pathOr([], ['hireRequests', 'items'], data);
        const count = pathOr(0, ['hireRequests', '_meta', 'count'], data);

        if (items.length === 0) {
            return (
                <EmptyList items={items} {...props} />
            );
        }

        return <div className="list-box">

            <InfiniteScroll
                pageStart={0}
                loadMore={page => this.loadMore(page, fetchMore)}
                hasMore={count > this.state.offset + LINES_PER_PAGE && !props.loading}>
                { items.map(item =>
                    <div className="item box hire-request" key={item.id} onClick={() => this.props.history.push(`/request/${item.id}`)}>
                        <div className='type-box' style={{ background: REQUEST_STATUSES[item.status].color }}>
                            <i className={`icon-${REQUEST_STATUSES[item.status].icon}`}></i>
                            {REQUEST_STATUSES[item.status].icon}
                            <span className="name">{ toLower(REQUEST_STATUSES[item.status].title) }</span>
                        </div>
                        <div className="title-box">
                            <div className="title">{ item.position.name }</div>
                            <div className="sub-title">
                                { item.deadline &&
                                    <span className="date">
                                        <Tooltip title='Срок'>
                                            <i className="icon-calendar"></i>{ moment.utc(item.deadline).format('DD.MM.YYYY') }
                                        </Tooltip>
                                    </span>
                                }
                                <span className="position">
                                    <i className="icon-worker"></i>{ item.count }
                                </span>
                                <span className="date" style={{ marginLeft: 10 }}>
                                    <Tooltip title='Дата создания заявки'>
                                        <i className="icon-calendar"></i>{ moment.utc(item.createdAt).format('DD.MM.YYYY') }
                                    </Tooltip>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </InfiniteScroll>
        </div>;
    }

    render() {
        return <Fragment>
            <Query
                query={getHireRequests}
                variables={this.getVariables()}>
                { this.renderList }
            </Query>
            { path(['user', 'hireRequestManagementEnabled'], this.props) &&
                <div className="controls flex-end" onClick={() => this.props.history.push('/request')}>
                    <Button className="btn-float request-add-btn"><i className="icon-plus"></i></Button>
                </div>
            }
        </Fragment>
    }
}

export default withUserContext(HireRequests);
