import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { path, pathOr } from 'ramda';
import * as yup from 'yup';
import { Query } from 'react-apollo';

import Input from './formComponents/Input';
import Select from './formComponents/Select';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import { getRegionsClient, getRegionsMacro } from '../../queries/regions';
import ListenerField from './ListenerField';
import Switch from './formComponents/Switch';

const validationSchema = yup.object().shape({
    id: yup.string(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    username: yup.string().email().required(),
    phone: yup.string().nullable().phone()
});

const labelCol = {
    xs: { span: 24 },
    sm: { span: 9 },
};

const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 15 },
};

class SupervisorForm extends Component {
    onChangeRegionMacro = () => {
        this.props.form.change('regionClient', null);
    }

    render() {
        return <div>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='username'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                required />
            <Field
                name='phone'
                component={Input}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Query query={getRegionsMacro}>
                { ({ data, loading }) =>
                    <Field
                        name='regionMacro'
                        component={Select}
                        label='Макрорегион'
                        loading={loading}
                        options={pathOr([], ['regionsMacro', 'items'], data)}
                        namePath='name'
                        valuePath='id'
                        onChange={this.onChangeRegionMacro}
                        wrapperCol={wrapperCol}
                        labelCol={labelCol}
                        allowClear />
                }
            </Query>
            <ListenerField listenFieldName='regionMacro'>
                { ({ regionMacro }) => regionMacro &&
                    <Query
                        query={getRegionsClient}
                        variables={{
                            filter: {
                                regionMacro
                            }
                        }}>
                        { ({ data, loading }) =>
                            <Field
                                name='regionClient'
                                component={Select}
                                label='Регион'
                                loading={loading}
                                options={pathOr([], ['regionsClient', 'items'], data)}
                                namePath='name'
                                valuePath='id'
                                wrapperCol={wrapperCol}
                                labelCol={labelCol}
                                allowClear />
                        }
                    </Query>
                }
            </ListenerField>
            <Field
                name='applicantStatusManagementEnabled'
                component={Switch}
                label='Управление статусами кандидатов'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='hireRequestManagementEnabled'
                component={Switch}
                label='Создание заявок'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>
    }
}

export default withFormWrapper(SupervisorForm, {
    validationSchema,
    mapPropsToValues: ({ supervisor }) => supervisor ? ({
        ...supervisor,
        regionClient: path(['regionClient', 'id'], supervisor),
        regionAncor: path(['regionAncor', 'id'], supervisor),
        regionMacro: path(['regionMacro', 'id'], supervisor)
    }) : null
});
