import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Switch, message } from 'antd';

export default class SwitchEnabled extends Component {
    onChange = (enabled, mutate) => {
        const { id, ...data } = this.props.item;

        mutate({
            variables: {
                id,
                values: {
                    ...data,
                    enabled
                }
            },
            update: () => {
                message.success('Статус успешно изменен');
            }
        }).catch(() => {
            message.error('Не удалось изменить статус');
        });
    }

    renderSwitch = (mutate, { loading }) => {
        const { item, disabled } = this.props;

        return <Switch
            checked={item.enabled}
            onChange={enabled => this.onChange(enabled, mutate)}
            loading={loading}
            disabled={loading || disabled} />;
    }

    render() {
        const { mutation, refetchQueries } = this.props;

        return <Mutation
            mutation={mutation}
            refetchQueries={refetchQueries}>
            { this.renderSwitch }
        </Mutation>
    }
}
