import React, { Component } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import { includes, path } from 'ramda';

import RequiredFieldsContext from '../../contexts/RequiredFieldsContext';
import FormServerErrorsContext from '../../contexts/FormServerErrorsContext';

const Item = styled(Form.Item)`
    width: 100%;
    position: relative;
`;

const Error = styled.div`
    color: #f5222d;
    line-height: 1;
`;

const withFieldWrapper = WrappedComponent =>
    class FieldWrapper extends Component {
        onChange = (value, data) => {
            const { input, onChange } = this.props;

            input.onChange(value);
            onChange && onChange(value, data);
        }

        renderField = (fields, serverErrors) => {
            const { label, meta: { error, submitFailed, dirtySinceLastSubmit }, input: { name }, wrapperCol, labelCol, required } = this.props;
            const serverError = path([name], serverErrors);
            const showError = (error && submitFailed) || (serverError && !dirtySinceLastSubmit);

            return <Item
                label={label}
                validateStatus={showError ? 'error' : undefined}
                required={includes(name, fields) || required}
                wrapperCol={wrapperCol || { span: 24 }}
                labelCol={labelCol || { span: 24 }}>
                <WrappedComponent {...this.props} onChange={this.onChange} />
                { showError && <Error className='field-error'>{ error || serverError }</Error> }
            </Item>;
        }

        render() {
            return <RequiredFieldsContext.Consumer>
                { fields =>
                    <FormServerErrorsContext.Consumer>
                        { serverErrors => this.renderField(fields, serverErrors) }
                    </FormServerErrorsContext.Consumer>
                }
            </RequiredFieldsContext.Consumer>
        }
    }

export default withFieldWrapper;
