import gql from 'graphql-tag';

export const getRegionsClient = gql(`
    query regionsClient($filter: RegionClientFilterInput, $pagination: PaginationInput, $sorting: RegionSortInput = { field: name, order: 1 }) {
        regionsClient(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                name,
                regionMacro {
                    id,
                    name
                }
            }
        }
    }
`);

export const getRegionsAncor = gql(`
    query regionsAncor($filter: RegionAncorFilterInput, $pagination: PaginationInput, $sorting: RegionSortInput = { field: name, order: 1 }) {
        regionsAncor(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                name,
                regionClient {
                    id,
                    name,
                    regionMacro {
                        id,
                        name
                    }
                }
            }
        }
    }
`);

export const getRegionsMacro = gql(`
    query regionsMacro($filter: RegionMacroFilterInput, $pagination: PaginationInput, $sorting: RegionSortInput = { field: name, order: 1 }) {
        regionsMacro(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                name
            }
        }
    }
`);

export const createRegionClient = gql(`
    mutation createRegionClient($values: RegionClientInput!) {
        createRegionClient(regionClient: $values) {
            id
        }
    }
`);

export const createRegionMacro = gql(`
    mutation createRegionMacro($values: RegionMacroInput!) {
        createRegionMacro(regionMacro: $values) {
            id
        }
    }
`);

export const createRegionAncor = gql(`
    mutation createRegionAncor($values: RegionAncorInput!) {
        createRegionAncor(regionAncor: $values) {
            id
        }
    }
`);

export const editRegionClient = gql(`
    mutation editRegionClient($id: ID!, $values: RegionClientInput!) {
        editRegionClient(id: $id, regionClient: $values) {
            id
        }
    }
`);

export const editRegionMacro = gql(`
    mutation editRegionMacro($id: ID!, $values: RegionMacroInput!) {
        editRegionMacro(id: $id, regionMacro: $values) {
            id
        }
    }
`);

export const editRegionAncor = gql(`
    mutation editRegionAncor($id: ID!, $values: RegionAncorInput!) {
        editRegionAncor(id: $id, regionAncor: $values) {
            id
        }
    }
`);

export const removeRegionClient = gql(`
    mutation removeRegionClient($id: ID!) {
        removeRegionClient(id: $id)
    }
`);

export const removeRegionMacro = gql(`
    mutation removeRegionMacro($id: ID!) {
        removeRegionMacro(id: $id)
    }
`);

export const removeRegionAncor = gql(`
    mutation removeRegionAncor($id: ID!) {
        removeRegionAncor(id: $id)
    }
`);
