import React, { Component, Fragment } from 'react';
import { Radio, message } from 'antd';

import HireRequestCommentForm from '../../forms/HireRequestCommentForm';
import { REQUEST_REFETCH_QUERIES } from '../../../constants/requests';

export default class HireRequestComment extends Component {
    state = {
        selected: null
    };

    onSuccess = () => {
        message.success('Статус заявки был успешно изменен');
        this.props.close();
    }

    onError = () => message.error('Не удалось изменить статус заявки');

    renderForm = ({ mutation }) => {
        return <HireRequestCommentForm
            mutation={mutation}
            onSuccess={this.onSuccess}
            onError={this.onError}
            refetchQueries={REQUEST_REFETCH_QUERIES}
            request={{ id: this.props.params.id }}
            useValuesAsVariables />
    }

    onChange = e => {
        this.setState({ selected: e.target.value });
    }

    render() {
        const { params } = this.props;
        const data = params.actions[this.state.selected];

        return <Fragment>
            <Radio.Group value={this.state.selected} onChange={this.onChange}>
                { params.actions.map((action, index) =>
                    <Radio.Button key={`action-type-${index}`} value={index} className={`hire-status-change-radio-${index}`}>
                        { action.title }
                    </Radio.Button>
                )}
            </Radio.Group>
            { data && this.renderForm(data) }
        </Fragment>
    }
}
