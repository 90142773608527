import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import { Button, Row, Col, Spin } from 'antd';
import { find, propEq, pathOr, path } from 'ramda';
import moment from 'moment';

import { getApplicant } from '../../../queries/applicants';
import {
    APPLICANTS_TABS,
    APPLICANTS_ACTIONS,
    EDUCATION, GENDERS
} from '../../../constants/applicants';
import ModalsContext from '../../../contexts/ModalsContext';
import { MODAL_TYPES } from '../../../constants/modals';
import UserItem from './UserItem';
import Error from '../Error';
import withUserContext from '../../hocs/withUserContext';

class User extends Component {
    renderField = (title, value) => {
        return <div className="form-group">
            <label>{ title }</label>
            <div className="box p10">
                { value }
            </div>
        </div>
    }

    render() {
        const { data: response, user } = this.props;
        const item = pathOr({}, ['data', 'applicant'], this.props);
        const data = find(propEq('key', item.status), APPLICANTS_TABS);

        if (response.loading) {
            return <Spin />;
        } else {
            if (response.error) {
                return <Error error={response.error} />;
            }
        }

        return item.id ? <Fragment>
            <UserItem
                item={item}
                title={data.title}
            />
            <div className="controls contact">
                { item.contact.phone &&
                    <a href={`tel:${item.contact.phone}`} className="ant-btn btn-default"><i className="icon-phone"></i>{ item.contact.phone }</a>
                }
                { item.contact.email &&
                    <a href={`mailto:${item.contact.email}`} className="ant-btn btn-default"><i className="icon-mail"></i>{ item.contact.email }</a>
                }
                <ModalsContext.Consumer>
                    { ({ openModal }) =>
                        <Button
                            className='btn-default'
                            onClick={() => openModal(MODAL_TYPES.logs, { id: item.id })}>
                            <i className="icon-draft"></i>
                            ИСТОРИЯ
                        </Button>
                    }
                </ModalsContext.Consumer>
            </div>

            <Row gutter={10}>
                {item.birthDate && item.birthDate.length &&
                    <Col xs={24} sm={12} >{ this.renderField('Дата рождения', moment.utc(item.birthDate).format('DD.MM.YYYY')) }</Col>
                }
                {item.gender && item.gender.length &&
                    <Col xs={24} sm={12}>{ this.renderField('Пол', path(['label'], find(propEq('value'), GENDERS))) }</Col>
                }
            </Row>

            { item.educationLevel && this.renderField('Образование', path(['label'], find(propEq('value', item.educationLevel), EDUCATION))) }

            { item.resume && (
                <div className="form-group">
                    <label>Резюме</label>
                    <div className="box p10">
                        <div className="text-center mt10">
                            <Button className="btn-link" href={`/api/file/${item.resume}`} target="_blank" rel="noopener noreferrer" download>
                                <i className="icon-downloads"></i>Скачать резюме
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            { item.comment && this.renderField('Комментарий', item.comment) }

            { (data.supervisor || user.applicantStatusManagementEnabled) &&
                <ModalsContext.Consumer>
                    { ({ openModal }) =>
                        <div className="controls">
                            { (data.next || []).map(status =>
                                <Button
                                    type='primary'
                                    key={status}
                                    onClick={() => openModal(
                                        MODAL_TYPES.applicantSupervisorChangeStatus,
                                        { id: item.id, status, statusChangedAt: item.statusChangedAt }
                                    )}
                                >
                                    { APPLICANTS_ACTIONS[status] }
                                </Button>
                            )}
                            { data.rejectable &&
                                <Button className='btn-default' onClick={() => openModal(MODAL_TYPES.applicantSupervisorReject, { id: item.id, type: item.status })}>
                                    Отказать
                                </Button>
                            }
                            <Button type='primary' onClick={() => openModal(MODAL_TYPES.supervisorComment, { applicant: item.id })}>Комментарий</Button>
                        </div>
                    }
                </ModalsContext.Consumer>
            }
        </Fragment> : null;
    }
}

export default graphql(getApplicant, {
    options: props => ({
        variables: {
            id: props.match.params.id
        }
    })
})(withUserContext(User));
