import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

import Modal from './Modal';

export default class AncorTechModal extends Component {
    render() {
        const { modal } = this.props;

        return <Modal
            {...modal}
            title='Техническая поддержка'
            footer={null}>
            <p><MailOutlined style={{ marginRight: 5 }} /> <a href='mailto:bmd-support@sys.experium.ru?subject=BMD_'>bmd-support@sys.experium.ru</a></p>
            <p><PhoneOutlined style={{ marginRight: 5 }} /> <a href='tel:+74956600699'>(495) 660-0699</a></p>
            <p>Ответ поступит в рабочие часы: пн-пт 08:00 – 18:00 по московскому времени.</p>
        </Modal>;
    }
}
