import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

const DateFormat = ({ date, utc }) => date ?
    <Tooltip title={ (utc ? moment.utc(date) : moment(date)).format('DD.MM.YYYY HH:mm') }>
        <span className='nowrap'>
            <CalendarOutlined /> { (utc ? moment.utc(date) : moment(date)).format('DD.MM.YYYY') }
        </span>
    </Tooltip> : null

export default DateFormat;
