import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Link, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import styled from 'styled-components';

import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import SubmitButton from './formComponents/SubmitButton';

const validationSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
});

export const RecoveryLink = styled(Link)`
    margin-top: 15px;
`;

class LoginForm extends Component {
    render() {
        return <Fragment>
            <Field
                name='username'
                component={Input}
                placeholder='Логин'
                htmlType='email'
                customIcon='profile' />
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password'
                customIcon='password' />
            <div className='controls'>
                <SubmitButton>
                    Войти
                </SubmitButton>
                <RecoveryLink className='ant-btn btn-link-small' to='/recovery'>Восстановить пароль</RecoveryLink>
            </div>
        </Fragment>;
    }
}

export default withFormWrapper(withRouter(LoginForm), {
    validationSchema
});
