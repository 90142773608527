import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

import { FEEDBACK_OPTIONS } from '../../constants/applicants';
import { required } from '../../utils/yup';
import RadioButtons from './formComponents/RadioButtons';

const FeedbackFields = ({ user = {} }) => {
    const { ancorStagesEnabled } = user;

    return (
        <Fragment>
            <Field
                name='feedback.motivation'
                component={RadioButtons}
                options={FEEDBACK_OPTIONS}
                required
                validate={required}
                label='Мотивация к работе' />
            { ancorStagesEnabled && (
                <Field
                    name='feedback.salesTechniques'
                    component={RadioButtons}
                    options={FEEDBACK_OPTIONS}
                    required
                    validate={required}
                    label='Навыки продаж' />
            )}
            <Field
                name='feedback.communication'
                component={RadioButtons}
                options={FEEDBACK_OPTIONS}
                required
                validate={required}
                label='Коммуникативные навыки' />
            <Field
                name='feedback.achievementOfResults'
                component={RadioButtons}
                options={FEEDBACK_OPTIONS}
                required
                validate={required}
                label='Ориентация на результат' />
            <Field
                name='feedback.passionForChanges'
                component={RadioButtons}
                options={FEEDBACK_OPTIONS}
                required
                validate={required}
                label='Ответственность' />
        </Fragment>
    );
};

export default FeedbackFields;
