import React, { Component } from 'react';

import Modal from './Modal';
import ApplicantLogs from '../user/admin/ApplicantLogs';

export default class LogsModal extends Component {
    render() {
        const { modal, params: { id }} = this.props;

        return <Modal
            {...modal}
            title='История'
            footer={null}>
            <ApplicantLogs id={id} />
        </Modal>;
    }
}
