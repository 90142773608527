import axios from 'axios';
import { message } from 'antd';

import { getToken } from './token';

export const downloadHireRequest = id =>
    axios.get(`/api/hire-request/${id}.xlsx`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        },
        responseType: 'blob'
    })
    .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'hire-request.xlsx');
        document.body.appendChild(link);
        link.click();
    })
    .catch(() => message.error('Не удалось скачать файл'));
