import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { pathOr, prop } from 'ramda';
import * as yup from 'yup';

import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';
import Select from './formComponents/Select';
import { getSupervisors } from '../../queries/supervisors';
import { getFullNameString } from '../user/table/FullName';
import ListenerField from './ListenerField';
import { getClientManagers } from '../../queries/clientManagers';

const validationSchema = yup.object().shape({
    verifierId: yup.string().required()
});

class SetVerifierForm extends Component {
    render() {
        const { regionClient, regionMacro, item } = this.props;

        return <Fragment>
            <Query
                query={getClientManagers}
                variables={{
                    filter: {
                        regionMacro
                    }
                }}>
                { ({ data: managersData, loading: managersLoading }) =>
                    <Query
                        query={getSupervisors}
                        variables={{
                            filter: {
                                regionClient
                            }
                        }}>
                        { ({ data, loading }) =>
                            <Field
                                name='verifierId'
                                component={Select}
                                label='Ответственный'
                                loading={loading || managersLoading}
                                options={[...pathOr([], ['clientManagers', 'items'], managersData), ...pathOr([], ['supervisors', 'items'], data)]}
                                valuePath='id'
                                renderName={getFullNameString} />
                        }
                    </Query>
                }
            </Query>
            <ListenerField listenFieldName='verifierId'>
                { ({ verifierId }) =>
                    <SubmitButton disabled={verifierId === item.verifierId}>
                        Назначить
                    </SubmitButton>
                }
            </ListenerField>
        </Fragment>;
    }
}

export default withFormWrapper(SetVerifierForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
