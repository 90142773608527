import { pathEq } from 'ramda';

import { CLIENT_MANAGER, SUPERVISOR, CONSULTANT } from './roles';
import { returnHireRequestToDraft, approveHireRequest, rejectHireRequest, takeHireRequestToAncorApproval, takeHireRequestToWork, holdHireRequest, markHireRequestAsDone, sendHireRequestToClientApproval, finalApproveHireRequest } from '../queries/requests';

export const LINES_PER_PAGE = 10;

export const HIRE_REQUESTS_TABS = [
    {
        key: 'last',
        title: 'Последние',
    },
    {
        key: 'draft',
        roles: [SUPERVISOR],
        title: 'Черновик',
        actions: [
            { title: 'Отправить', mutation: sendHireRequestToClientApproval, roles: [SUPERVISOR]}
        ]
    },
    {
        key: 'clientApproval',
        title: 'Согласование',
        actions: [
            { title: 'В черновик', mutation: returnHireRequestToDraft, roles: [CLIENT_MANAGER], hide: pathEq(['verifier', '__typename'], 'ClientManager') },
            { title: 'Принять', mutation: approveHireRequest, roles: [CLIENT_MANAGER, CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CLIENT_MANAGER] }
        ]
    },
    {
        key: 'finalClientApproval',
        title: 'Финальное согласование',
        actions: [
            { title: 'На доработку', mutation: sendHireRequestToClientApproval, roles: [CLIENT_MANAGER] },
            { title: 'Принять', mutation: finalApproveHireRequest, roles: [CLIENT_MANAGER, CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CLIENT_MANAGER] }
        ]
    },
    {
        key: 'ancorAwaiting',
        title: 'Ожидание Анкор',
        actions: [
            { title: 'Принять', mutation: takeHireRequestToAncorApproval, roles: [CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CONSULTANT] }
        ]
    },
    {
        key: 'ancorApproval',
        title: 'Согласование Анкор',
        actions: [
            { title: 'Принять', mutation: takeHireRequestToWork, roles: [CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CONSULTANT] }
        ]
    },
    {
        key: 'inwork',
        title: 'В работе',
        actions: [
            { title: 'Приостановить', mutation: holdHireRequest, roles: [CONSULTANT] },
            { title: 'Завершить', mutation: markHireRequestAsDone, roles: [CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CONSULTANT] }
        ]
    },
    {
        key: 'hold',
        title: 'Приостановлено',
        actions: [
            { title: 'В работу', mutation: takeHireRequestToWork, roles: [CONSULTANT] },
            { title: 'Завершить', mutation: markHireRequestAsDone, roles: [CONSULTANT] },
            { title: 'Отклонить', mutation: rejectHireRequest, roles: [CONSULTANT] }
        ]
    },
    {
        key: 'done',
        title: 'Выполнено'
    },
    {
        key: 'rejected',
        title: 'Отклонено'
    }
];

export const ENABLED_STATE = [
    { label: 'Активные', value: true },
    { label: 'Архивные', value: false }
];

export const DEFAULT_USER_FILTER = {
    enabled: true
};

export const DEFAULT_POSITION_FILTER = {
    enabled: true
};

export const FINAL_APPROVER = [
    { text: 'Да', value: true },
    { text: 'Нет', value: false }
];
