import React, { Component } from 'react';
import { Tabs } from 'antd';

import Route from '../../Route';
import { StyledTabs } from './Users';
import SlaSettings from './SlaSettings';

const TABS = [
    { tab: 'SLA', key: 'sla', component: SlaSettings }
];

export default class Settings extends Component {
    onChange = key => {
        this.props.history.push(`/settings/${key}`);
    }

    render() {
        const { params: { type }} = this.props.match;

        return <StyledTabs
            onChange={this.onChange}
            activeKey={type}
            animated={{ tabPane: false }}>
            { TABS.map(tab =>
                <Tabs.TabPane tab={tab.tab} key={tab.key}>
                    <Route path={`/settings/${tab.key}`} component={tab.component} />
                </Tabs.TabPane>
            )}
        </StyledTabs>;
    }
}
