import React from 'react';
import { insert } from 'ramda';
import { AuditOutlined, CalendarOutlined, SecurityScanOutlined, EditOutlined, ContactsOutlined, UserDeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const SUPERVISOR_APPLICANTS_STATUSES = [
    { value: 'active', label: 'Активные' },
    { value: 'all', label: 'Все' }
];

export const GENDERS = [
    { value: 'male', label: 'Мужской' },
    { value: 'female', label: 'Женский' }
];

export const EDUCATION = [
    { value: 'middle', label: 'Среднее' },
    { value: 'middleSpecial', label: 'Среднее-специальное' },
    { value: 'incompleteHigher', label: 'Неоконченное высшее' },
    { value: 'higher', label: 'Высшее' }
];

export const APPLICANTS_STATUSES = {
    supervisorApproval: 'Согласование',
    supervisorInterview: 'Интервью',
    securityApproval: 'СБ',
    registration: 'Оформление',
    attendanceAwaiting: 'Ожидание выхода',
    absence: 'Неявка',
    hired: 'Т/У',
    rejected: 'Отклонен',
    supervisorInterviewPassed: 'Интервью проведено'
};

export const APPLICANTS_TABS_NOANCOR = [
    { key: 'supervisorApproval', title: APPLICANTS_STATUSES.supervisorApproval, rejectable: true, next: ['supervisorInterview'], supervisor: true, funnelTitle: 'Направленные CV' },
    { key: 'supervisorInterview', title: APPLICANTS_STATUSES.supervisorInterview, rejectable: true, next: ['securityApproval', 'absence'], supervisor: true, funnelTitle: 'Назначенные собеседования' },
    { key: 'securityApproval', title: APPLICANTS_STATUSES.securityApproval, rejectable: true, next: ['registration'], funnelTitle: 'Одобренные на интервью' },
    { key: 'registration', title: APPLICANTS_STATUSES.registration, rejectable: true, next: ['attendanceAwaiting'], funnelTitle: 'Прошедшие СБ' },
    { key: 'attendanceAwaiting', title: APPLICANTS_STATUSES.attendanceAwaiting, rejectable: true, next: ['hired'], funnelTitle: 'Оффер' },
    { key: 'absence', title: APPLICANTS_STATUSES.absence, rejectable: true, next: ['supervisorInterview'], hideFunnel: true },
    { key: 'hired', title: APPLICANTS_STATUSES.hired },
    { key: 'rejected', title: APPLICANTS_STATUSES.rejected }
];

export const APPLICANTS_TABS = [
    { key: 'last', title: 'Последние' },
    ...APPLICANTS_TABS_NOANCOR,
];

export const APPLICANTS_DOUBLES_STATUSES = [
    'supervisorApproval',
    'supervisorInterview',
    'securityApproval',
    'registration',
    'attendanceAwaiting'
];

export const APPLICANTS_COPY_STATUSES = [
    'hired',
    'rejected',
];

export const FUNNEL_APPLICANTS = insert(3, { key: 'supervisorInterviewPassed', funnelTitle: 'Дошедшие до интервью' }, APPLICANTS_TABS);
export const FUNNEL_APPLICANTS_NOANCOR = insert(2, { key: 'supervisorInterviewPassed', funnelTitle: 'Дошедшие до интервью' }, APPLICANTS_TABS_NOANCOR);

export const APPLICANTS_MANAGER_ACTIONS = [
    APPLICANTS_TABS[1],
    APPLICANTS_TABS[2],
];

export const APPLICANTS_ACTIONS = {
    supervisorInterview: 'Интервью',
    securityApproval: 'Одобрить',
    absence: 'Неявка',
    registration: 'Оформление',
    attendanceAwaiting: 'Ожидание выхода',
    hired: 'Т/У'
};

export const REJECT_COMMENT_REQUIRED = [
    'supervisorApproval',
    'supervisorInterview'
];

export const REJECT_REASON = [
    { value: 'denialByResume', label: 'Отказ по резюме' },
    { value: 'denialByInterview', label: 'Отказ после интервью' },
    { value: 'selfByPhone', label: 'Самоотказ по телефону' },
    { value: 'notResponding', label: 'Не выходит на связь' },
    { value: 'denialBySecurity', label: 'Отказ СБ' },
    { value: 'selfAfterInterview', label: 'Самоотказ после интервью' },
    { value: 'selfAfterSecurity', label: 'Самоотказ после СБ' },
    { value: 'selfBeforeAttendance', label: 'Отказ от Т/У' },
    { value: 'absence', label: 'Неявка' },
    { value: 'closedHireRequest', label: 'Заявка выполнена' }
];

export const PDA_FILTER = [
    { value: 'pdaAgreement', label: 'Дали согласие на ОПД' },
    { value: 'communicationsAgreement', label: 'Дали согласие на рассылки' },
    { value: 'noPda', label: 'Нет согласия' },
    { value: 'depersonalizationRejected', label: 'Отказ' },
    { value: 'depersonalized', label: 'Обезличен' },
];

export const PDA_FILTERS = {
    'pdaAgreement': {
        pdaAgreement: true,
    },
    'communicationsAgreement': {
        communicationsAgreement: true,
    },
    'noPda': {
        pdaAgreement: false,
    },
    'depersonalizationRejected': {
        pdaAgreement: false,
        depersonalized: true,
        depersonalizationReason: 'rejected',
    },
    'depersonalized': {
        pdaAgreement: false,
        depersonalized: true,
        depersonalizationReason: 'notAccepted',
    },
};

export const ALLOWED_REJECT_REASONS = {
    supervisorApproval: ['selfByPhone', 'notResponding'],
    supervisorInterview: ['denialByInterview'],
    securityApproval: ['denialBySecurity', 'selfAfterInterview', 'selfAfterSecurity'],
    registration: ['selfBeforeAttendance'],
    absence: ['absence'],
    attendanceAwaiting: ['selfBeforeAttendance']
};

export const APPLICANTS_STATUS_COLORS = {
    supervisorApproval: '#01203F',
    supervisorInterview: '#9013FE',
    securityApproval: '#F5A623',
    registration: '#4CC2E5',
    attendanceAwaiting: '#1890FF',
    absence: '#8E8E8E',
    hired: '#71C57A',
    rejected :'#FF4136',
    supervisorInterviewPassed: '#9013FE'
};

export const APPLICANTS_STATUS_ICONS = {
    supervisorApproval: <AuditOutlined />,
    supervisorInterview: <CalendarOutlined />,
    securityApproval: <SecurityScanOutlined />,
    registration: <EditOutlined />,
    attendanceAwaiting: <ContactsOutlined />,
    absence: <UserDeleteOutlined />,
    hired: <CheckOutlined />,
    rejected: <CloseOutlined />,
    supervisorInterviewPassed: <CalendarOutlined />
};

export const APPLICANTS_REFETCH_QUERIES = ['applicants', 'applicantFilters', 'hireRequests'];
export const APPLICANT_REFETCH_QUERIES = ['applicant'];

export const FEEDBACK_OPTIONS = [
    { value: 0, label: '1', help: 'не соответствует требованиям (не проявляет компетенцию)' },
    { value: 1, label: '2', help: 'требует значительного совершенствования' },
    { value: 2, label: '3', help: 'требует незначительного совершенствования' },
    { value: 3, label: '4', help: 'соответствует требованиям' },
    { value: 4, label: '5', help: 'Превышает ожидания по требованиям' },
];

export const REJECT_REASONS = [
    { value: 'selfRefusal', label: 'Отказался сам' },
    { value: 'lowMotivation', label: 'Не замотивирован на выполнение задач' },
    { value: 'weakCommunication', label: 'Отсутствие коммуникативных навыков' },
    { value: 'highExpectations', label: 'Завышенные зарплатные ожидания' },
    { value: 'weakCompetences', label: 'Отсутствие компетенций' },
    { value: 'notPresentableAppearance', label: 'Не презентабельный внешний вид' },
];

export const APPLICANT_CHANGE_STATUS = 'Applicant:ChangeStatus';

export const APPLICANT_LOG_STATUSES = {
    'Applicant:Create': 'Создание кандидата',
    'Applicant:Edit': 'Редактирование кандидата',
    [APPLICANT_CHANGE_STATUS]: 'Изменение статуса кандидата',
    'Applicant:SetDateInterview': 'Ввод даты интервью',
    'Applicant:SetInterviewFeedback': 'Ввод результатов интервью',
    'Applicant:SetFirstWorkingDay': 'Ввод даты выхода на работу',
    'Applicant:ChangeResponsible': 'Изменение рекрутера'
};

export const DEADLINE_APPLICANTS = [
    { type: 'supervisorApproval', name: 'Согласование' },
    { type: 'supervisorInterview', name: 'Интервью' },
    { type: 'securityApproval', name: 'СБ' },
    { type: 'registration', name: 'Оформление' },
    { type: 'attendanceAwaiting', name: 'Ожидание выхода' }
];

export const REPORT_PERIODS = [
    { value: 'month', label: 'По месяцам' },
    { value: 'quarter', label: 'По кварталам' },
    { value: 'year', label: 'По годам' }
];

export const REPORT_PERIOD_LABLES = {
    month: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    quarter: ['I квартал', 'II квартал', 'III квартал', 'IV квартал']
};

export const APPLICANTS_ALL_STATUSES = [
    'supervisorApproval',
    'supervisorInterview',
    'supervisorInterviewPassed',
    'securityApproval',
    'registration',
    'attendanceAwaiting',
    'hired',
    'absence',
    'rejected'
];

export const APPLICANTS_DEPERSONALIZATION_REASONS = {
    notAccepted: 'Нет ответа',
    rejected: 'Отказ',
};

