import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import { path, split, assocPath } from 'ramda';

import InitialValuesContext from '../../contexts/InitialValuesContext';

class ListenerFieldComponent extends Component {
    constructor(props) {
        super(props);

        const { listenFieldName, contextValues } = props;
        let initialState = {};

        if (Array.isArray(listenFieldName)) {
            listenFieldName.forEach( name => {
                const pathArray = split('.', name);
                const fieldValue = path(pathArray, contextValues);
                initialState = assocPath(pathArray, fieldValue, initialState);
            })

            this.state = initialState;
        } else {
            const pathArray = split('.', listenFieldName);
            const fieldValue = path(pathArray, contextValues);
            this.state = assocPath(pathArray, fieldValue, {});
        }
    }

    static propTypes = {
        listenFieldName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]).isRequired,
        children: PropTypes.func,
        contextValues: PropTypes.object,
    }

    setValue = (name, value) => {
        this.setState(prevState => assocPath(split('.', name), value, prevState));
    }

    render() {
        const { listenFieldName } = this.props;

        return (
            <Fragment>
                { Array.isArray(listenFieldName) ? (
                    listenFieldName.map(name => (
                        <OnChange key={name} name={name}>
                            { value => this.setValue(name, value)}
                        </OnChange>
                    ))
                ) : (
                    <OnChange name={listenFieldName}>
                        { value => this.setValue(listenFieldName, value)}
                    </OnChange>
                )}
                { this.props.children(this.state) }
            </Fragment>
        );
    }
}

export default class ListenerField extends Component {
    render() {
        return (
            <InitialValuesContext.Consumer>
                { contextValues => (
                        <ListenerFieldComponent
                            contextValues={contextValues}
                            {...this.props}
                        />
                    )
                }
            </InitialValuesContext.Consumer>
        );
    }
}
