import React from 'react';
import styled from 'styled-components';

const Email = styled.span`
    text-decoration: ${({ activated }) => activated ? 'none' : 'line-through'};
`;

const UserEmail = ({ email, activated }) =>
    <Email activated={activated}>{ email }</Email>

export default UserEmail;
