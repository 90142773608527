import React, { Component } from 'react';

import LoginForm from '../forms/LoginForm';
import { login } from '../../queries/authorization';
import { setToken } from '../../utils/token';
import UserContext from '../../contexts/UserContext';
import { DEFAULT_LOGIN_ERROR } from '../../constants/errors';

export default class Login extends Component {
    onSuccess = ({ login }, getUser) => {
        setToken(login.accessToken);
        getUser();
    }

    render() {
        return <UserContext.Consumer>
            { ({ getUser }) =>
                <LoginForm
                    mutation={login}
                    onSuccess={data => this.onSuccess(data, getUser)}
                    defaultError={DEFAULT_LOGIN_ERROR}
                    useValuesAsVariables />
            }
        </UserContext.Consumer>;
    }
}
