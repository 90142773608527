import gql from 'graphql-tag';

export const getConsultants = gql(`
    query consultants($filter: ConsultantFilterInput, $pagination: PaginationInput, $sorting: UserSortInput) {
        consultants(filter: $filter, pagination: $pagination, sorting: $sorting) {
            _meta {
                count
            },
            items {
                id,
                username,
                lastName,
                firstName,
                middleName,
                phone,
                enabled,
                activated,
                lastLoginAt,
                applicantStatusManagementEnabled
            }
        }
    }
`);

export const createConsultant = gql(`
    mutation createConsultant($values: ConsultantInput!) {
        createConsultant(consultant: $values) {
            id
        }
    }
`);

export const editConsultant = gql(`
    mutation editConsultant($values: ConsultantEditInput!, $id: ID!) {
        editConsultant(consultant: $values, id: $id) {
            id
        }
    }
`);
