import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import RSelect, { components } from 'react-select';
import { VariableSizeList } from 'react-window';
import { isNil, findIndex, equals, take, length, filter, path, find, propEq, map, prop, compose, includes, __, pathOr, remove, any, prepend } from 'ramda';
import { Spin } from 'antd';
import InfiniteLoader from 'react-window-infinite-loader';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { HEIGHT_WITH_SMALL, HEIGHT } from '../../../constants/select';
import { needNativeInputs } from '../../../utils/mobile';
import { Query } from '@apollo/react-components';
import { useApolloClient } from '@apollo/react-hooks';

const Wrapper = styled.div`
    .select {
        line-height: 1.5;

        .toolbar .ant-form-item-control & {
            margin-top: 0;
        }

        .select__menu {
            min-width: 100%;
            z-index: 3;
            overflow: hidden;
        }

        .select__value-container {
            height: 32px;
        }

        .select__control {
            min-height: 33px;
            height: 33px;
            border-radius: 4px;
            border: 1px solid #d9d9d9;

            &.select__control--is-focused {
                border-color: #d9d9d9;
            }
            &:hover {
                border-color: #d9d9d9;
                cursor: pointer;
            }
            &:focus {
                border-color: #d9d9d9;
            }
        }

        .select__option {
            padding-top: 5px;
            text-align: left;
            margin-top: -1px;
            & > div,
            small {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &:not(.select__option--is-focused):not(.select__option--is-selected) small {
                color: #aaa;
            }
        }

        .select__clear-indicator {
            padding: 0;
        }

        .select__dropdown-indicator {
            padding: 0;
            padding-right: 8px;
        }

        .ant-spin.ant-spin-sm.ant-spin-spinning {
            line-height: 100%;
        }

        .select__placeholder {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 10px);
            overflow: hidden;
        }
    }

    .no-options {
        height: 33px;
        text-align: center;
        line-height: 33px;
    }
`;

const Option = styled.div`
    .search-input__option {
        height: ${({ small }) => small ? HEIGHT_WITH_SMALL : HEIGHT}px;
    }
`;

const defaultProps = {
    options: [],
    placeholder: ''
};

class Select extends Component {
    static defaultProps = defaultProps;

    getOptionsHeight = childrens => {
        if (Array.isArray(childrens)) {
            const smallLength = length(filter(path(['props', 'data', 'small']), childrens));

            return smallLength * HEIGHT_WITH_SMALL + (childrens.length - smallLength) * HEIGHT;
        }

        return HEIGHT;
    }

    getOffset = (options, value) => {
        const index = findIndex(equals(value), options);

        return index < 0 ? 0 : this.getOptionsHeight(take(index, options));
    }

    getMenuList = options => ({ children, maxHeight, getValue }) => {
        const { lazyLoad, isItemLoaded, itemCount, loadMoreItems } = this.props;
        const [ value ] = getValue();
        const optionsHeight = this.getOptionsHeight(children);

        const listHeight = options ? optionsHeight > maxHeight ? maxHeight : optionsHeight : 0;
        const initialOffset = optionsHeight > maxHeight ? this.getOffset(options, value) : 0;

        return lazyLoad ? <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
        >
            {({ onItemsRendered, ref }) => (
                <VariableSizeList
                    height={listHeight}
                    itemCount={itemCount}
                    itemSize={index => options[index] && options[index].small ? HEIGHT_WITH_SMALL : HEIGHT}
                    initialScrollOffset={this.scrollOffset || 0}
                    onItemsRendered={onItemsRendered}
                    onScroll={({ scrollOffset }) => this.scrollOffset = scrollOffset}
                    ref={ref}>
                    { ({ index, style }) => <div style={style} className='rc-select-list-item'>{ !isItemLoaded(index) ? 'Загрузка...' : (Array.isArray(children) ? children[index] : children) }</div> }
                </VariableSizeList>
            )}
        </InfiniteLoader> :
        <VariableSizeList
            height={listHeight}
            itemCount={children.length || 1}
            itemSize={index => options[index] && options[index].small ? HEIGHT_WITH_SMALL : HEIGHT}
            initialScrollOffset={initialOffset}>
            { ({ index, style }) => <div style={style} className='rc-select-list-item'>{ Array.isArray(children) ? children[index] : children }</div> }
        </VariableSizeList>;
    }

    onChange = data => {
        const { isMulti, onChange } = this.props;

        if (isMulti) {
            onChange(map(prop('value'), data || []));
        } else {
            onChange(data ? data.value : null, data ? data.item : null);
        }
    };

    getOption = props => {
        const { renderLabel } = this.props;
        const { label, data: { small } } = props;

        return <Option small={small}>
            <components.Option {...props}>
                <div>
                    { renderLabel ? renderLabel(props) : label }
                </div>
                { small && <small>{ small }</small> }
            </components.Option>
        </Option>;
    }

    render() {
        const { options, input, loading, placeholder, allowClear, searchable, isMulti, renderSmall, disabled, onMenuClose } = this.props;

        const value = isMulti ?
            filter(compose(includes(__, input.value || []), prop('value')), options) :
            (find(propEq('value', input.value), options) || '');

        return <Wrapper>
            <RSelect
                classNamePrefix='select'
                className={`select select-${input.name}`}
                value={value}
                options={options}
                onChange={this.onChange}
                isLoading={loading}
                placeholder={placeholder}
                isClearable={allowClear}
                isSearchable={searchable}
                isMulti={isMulti}
                isDisabled={disabled}
                maxMenuHeight={(renderSmall ? HEIGHT_WITH_SMALL : HEIGHT) * 6}
                onMenuClose={() => {
                    this.scrollOffset = 0;
                    onMenuClose && onMenuClose();
                }}
                components={{
                    MenuList: this.getMenuList(options),
                    Option: this.getOption,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => <Spin size='small' />,
                    NoOptionsMessage: () => <div className='no-options'>Ничего не найдено</div>,
                }}
                loadingMessage={() => <span>Загрузка...</span>}
            />
        </Wrapper>;
    }
}

class NativeSelect extends Component {
    static defaultProps = defaultProps;

    onChange = event => {
        const { options, valuePath } = this.props;
        const value = event.target.value;
        const item = find(propEq(valuePath, value), options);

        this.props.onChange(value || null, item || null);
    }

    render() {
        const { input: { value, name }, loading, disabled, options, placeholder, allowClear } = this.props;

        return (
            <select
                id={name}
                name={name}
                className='select-input'
                value={value || undefined}
                disabled={loading || disabled}
                placeholder={placeholder}
                onChange={this.onChange}
            >
                { allowClear && <option value=''>Не выбрано</option> }
                { options.map((item, index) => (
                    <option key={item.value || index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        );
    }
}

const getOptions = props => {
    const { options, valuePath = 'value', namePath = 'label', renderSmall, renderName } = props;

    return options.map(item => ({
        value: path(valuePath.split('.'), item),
        label: renderName ? renderName(item) : path(namePath.split('.'), item),
        small: renderSmall ? renderSmall(item) : null,
        item
    }));
};

const SelectComponent = withFieldWrapper(
    props => {
        const options = getOptions(props);
        return needNativeInputs() ? <NativeSelect {...props} options={options} /> : <Select {...props} options={options} />
    }
);

export const LazyLoadSelect = props => {
    const limit = isNil(props.limit) ? 20 : props.limit;
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState(null);
    const [selectedLoading, setSelectedLoading] = useState(null);
    const startLoading = () => setLoading(true);
    const endLoading = () => {
        setLoading(false);
        setPage(page + 1);
    };

    useEffect(() => {
        if (props.input.value) {
            setSelectedLoading(true);
            client.query({
                query: props.singleQuery,
                variables: {
                    id: props.input.value
                }
            }).then(({ data }) => {
                setSelectedLoading(false);
                setSelected(path([props.singleQueryPath], data));
            }).catch(() => setSelectedLoading(false));
        } else {
            setSelected(null);
        }
    }, [props.input.value, props.singleQuery, props.singleQueryPath, client]);

    const loadMore = (hasMore, fetchMore) => {
        if (hasMore && !loading) {
            startLoading();

            fetchMore({
                variables: {
                    pagination: {
                        offset: page * limit,
                        limit
                    }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    endLoading();

                    return {
                        [props.optionsPath]: {
                            ...fetchMoreResult[props.optionsPath],
                            items: pathOr([], [props.optionsPath, 'items'], prev).concat(pathOr([], [props.optionsPath, 'items'], fetchMoreResult)),
                            _meta: pathOr({}, [props.optionsPath, '_meta'], fetchMoreResult)
                        }
                    }
                }
            });
        }
    }

    return <Query
        query={props.query}
        variables={{
            ...(props.variables || {}),
            pagination: {
                offset: 0,
                limit
            }
        }}>
        { data => {
            let options = pathOr([], ['data', props.optionsPath, 'items'], data);
            const itemCount = pathOr(0, ['data', props.optionsPath, '_meta', 'count'], data);
            const hasMore = !data.loading && (limit * page < itemCount);

            if (selected) {
                options = prepend(selected, any(propEq('id', selected.id), options) ? remove(findIndex(propEq('id', selected.id), options), 1, options) : options);
            }

            return <SelectComponent
                {...props}
                loading={loading || selectedLoading}
                disabled={selectedLoading}
                options={options}
                isItemLoaded={index => index < options.length}
                itemCount={itemCount}
                loadMoreItems={() => loadMore(hasMore, data.fetchMore)}
                onMenuClose={() => {
                    setPage(0);
                    setLoading(false);
                    data.refetch({
                        ...(props.variables || {}),
                        pagination: {
                            offset: 0,
                            limit
                        }
                    });
                }}
                loadingMessage={() => <span>Загрузка...</span>}
                lazyLoad />;
        }}
    </Query>
}

export default SelectComponent;
