import { ascend, prop, pathOr, uniqBy, map, filter, pathEq, sortWith } from 'ramda';

const sortRegions = sortWith([
    ascend(prop('label'))
]);

export const getRegionsCascaderData = data => {
    const cascaderData = getRegionsManagerCascaderData(data);

    return sortRegions(map(({ regionMacro }) => ({
        value: regionMacro.id,
        label: regionMacro.name,
        children: sortRegions(
            filter(pathEq(['regionMacro', 'id'], regionMacro.id), cascaderData)
        ),
    }), uniqBy(i => i.regionMacro.id, cascaderData)));
};

export const getRegionsManagerCascaderData = data => {
    const items = pathOr([], ['regionsAncor', 'items'], data);

    return sortRegions(map(({ regionClient }) => ({
        regionMacro: regionClient.regionMacro,
        value: regionClient.id,
        label: regionClient.name,
        children: sortRegions(
            map(i => ({
                value: i.id,
                label: i.name
            }), filter(pathEq(['regionClient', 'id'], regionClient.id), items))
        ),
    }), uniqBy(i => i.regionClient.id, items)));
};

export const getRegionsClientCascaderData = data => {
    const items = pathOr([], ['regionsClient', 'items'], data);

    return sortRegions(map(({ regionMacro }) => ({
        value: regionMacro.id,
        label: regionMacro.name,
        children: sortRegions(
            map(i => ({
                value: i.id,
                label: i.name
            }), filter(pathEq(['regionMacro', 'id'], regionMacro.id), items))
        ),
    }), uniqBy(i => i.regionMacro.id, items)));
};

export const formatMoney = value => value && Number(value) ? (
    `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
) : value;
