import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { prop } from 'ramda';
import * as yup from 'yup';

import Input from './formComponents/Input';
import withFormWrapper from '../hocs/withFormWrapper';
import SubmitButton from './formComponents/SubmitButton';

const validationSchema = yup.object().shape({
    id: yup.string(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    username: yup.string().email().required(),
    phone: yup.string().nullable().phone()
});

export const labelCol = {
    xs: { span: 24 },
    sm: { span: 4 },
};

export const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 20 },
};

class UserForm extends Component {
    render() {
        return <div>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <Field
                name='username'
                component={Input}
                label='Email'
                htmlType='email'
                wrapperCol={wrapperCol}
                labelCol={labelCol}
                required />
            <Field
                name='phone'
                component={Input}
                label='Телефон'
                wrapperCol={wrapperCol}
                labelCol={labelCol} />
            <SubmitButton>
                Сохранить
            </SubmitButton>
        </div>
    }
}

export default withFormWrapper(UserForm, {
    validationSchema,
    mapPropsToValues: prop('user')
});
